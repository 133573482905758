import { Box, SxProps, Theme } from '@mui/material';
import { EnumHelpers, EnumHelpersWithIcon } from './enums/types';

export type SelectOptionType = {
  value: string;
  label: React.ReactNode;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export const getSelectOptionsFromEnumHelper = <T extends string>(enumHelper: EnumHelpers<T>): Pick<SelectOptionType, 'value' | 'label'>[] => {
  return enumHelper.enumValues.map(value => ({ value, label: enumHelper.getLabel(value) }));
};

export const getSelectOptionsFromEnumHelperWithIcon = <T extends string>(enumHelper: EnumHelpersWithIcon<T>): Pick<SelectOptionType, 'value' | 'label'>[] => {
  return enumHelper.enumValues.map(value => {
    const Icon = enumHelper.getIcon(value);

    return {
      value,
      label: (
        <Box display="flex" alignItems="center" key={value} gap={1}>
          <Icon />
          {enumHelper.getLabel(value)}
        </Box>
      ),
    };
  });
};