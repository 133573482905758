import { useState } from 'react';
import { FiltersModal } from './FiltersModal.modal';
import { DateService } from 'services';
import { EnumFilter } from './EnumFilter.component';
import { YearFilter } from './YearFilter.component';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ResourceFilter } from './ResourceFilter.component';
import { useLocalStorage } from 'hooks';
import { FiltersActionContent } from 'components/FullCalendar/CalendarSidebarAction/FiltersActionContent.component';
import { FullCalendarSidebarActionProps } from 'components/FullCalendar';
import _ from 'lodash';

export type FilterValidFilterObject = {
  [key: string | symbol]: any;
};

type FilterValidRow = {
  [key: string | symbol]: any;
};

type FilterBaseFieldConfiguration<R extends FilterValidRow = FilterValidRow, V = any> = {
  field: string;
  predicate: (row: R, filterValue: V extends Array<infer U> ? U : V) => boolean;
  readOnly?: boolean;
  label: string;
};

export type FilterResourceFieldConfiguration<R extends FilterValidRow = FilterValidRow> = FilterBaseFieldConfiguration<R, string> & {
  type: 'resource';
  defaultValue: 'any' | string[];
  options: { _id: string; label: string }[];
  loading?: boolean;
  includeAny?: boolean;
  includeNotSelected?: boolean;
};

export type FilterEnumFieldConfiguration<R extends FilterValidRow = FilterValidRow, V extends string = string> = FilterBaseFieldConfiguration<R, V> & {
  type: 'enum';
  strict?: boolean;
  defaultValue: V[];
  options: V[];
  getOptionLabel: (option: V) => string;
};

export type YearInputValueOption<R extends FilterValidRow, V extends string> = {
  value: V;
  label: string;
  predicate: (row: R) => boolean;
};
export type YearInputValue<V extends string> = number | V;
export type FilterYearFieldConfiguration<R extends FilterValidRow = FilterValidRow, V extends string = string> = {
  field: string;
  label: string;
  type: 'year';
  yearPredicate: (row: R, year: number) => boolean;
  defaultValue: YearInputValue<V>;
  options: YearInputValueOption<R, V>[];
  readOnly?: boolean;
};

export type FilterCheckboxFieldConfiguration<R extends FilterValidRow = FilterValidRow> = FilterBaseFieldConfiguration<R, boolean> & {
  type: 'checkbox';
  defaultValue: boolean;
};

export type FilterFieldConfiguration<R extends FilterValidRow = any> = FilterEnumFieldConfiguration<R> | FilterYearFieldConfiguration<R> | FilterCheckboxFieldConfiguration<R> | FilterResourceFieldConfiguration<R>;

export type UseFilterArgs<R extends FilterValidRow = FilterValidRow> = {
  localStorageId: string;
  rows: R[];
  getRowId?: (row: R) => string;

  filterConfigurations: FilterFieldConfiguration<R>[];
};

const getDefaultFilterObject = (filterConfigurations: FilterFieldConfiguration[]) => {
  return filterConfigurations.reduce((r: FilterValidFilterObject, filterConfiguration) => {
    return { ...r, [filterConfiguration.field]: filterConfiguration.defaultValue };
  }, {});
};

type GetFilterValueReturnType<C extends FilterFieldConfiguration> =
  'enum' extends C['type'] ? any[] :
    'resource' extends C['type'] ? string[] | 'any' :
      'checkbox' extends C['type'] ? boolean :
        'year' extends C['type'] ? string | number :
          any;
const getFilterValue = <C extends FilterFieldConfiguration>(filterConfiguration: C, filter: FilterValidFilterObject): GetFilterValueReturnType<C> => {
  switch (filterConfiguration.type) {
    case 'enum':
      return filter[filterConfiguration.field] ?? [] as any;
    case 'resource':
      return filter[filterConfiguration.field] ?? 'any' as any;
    case 'checkbox':
      return filter[filterConfiguration.field] ?? false as any;
    case 'year':
      return filter[filterConfiguration.field] ?? DateService.dayjs().year() as any;
    default:
      return null as any;
  }
};

export type UseFilterOutput<Row> = {
  modal: React.ReactNode;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  nonDefaultFilterApplied: boolean;
  filter: FilterValidFilterObject;
  setFilter: React.Dispatch<React.SetStateAction<FilterValidFilterObject>>;
  filterFunc: (value: Row | string) => boolean;
  filteredRows: Row[];
  onResetFilters: () => void;
  sidebarActionConfig: FullCalendarSidebarActionProps;
};

export const useFilter = <Row extends FilterValidRow>({
  localStorageId,
  filterConfigurations,
  rows,
  getRowId = r => r._id,
}: UseFilterArgs<Row>) => {
  const [ showModal, setShowModal ] = useState(false);
  const localStorage = useLocalStorage<FilterValidFilterObject>(localStorageId);
  const defaultFilters = getDefaultFilterObject(filterConfigurations);
  const [ filter, setFilter ] = useState(localStorage.getResource(defaultFilters));

  const nonDefaultFilterApplied = !_.isEqual(filter, defaultFilters);

  const onFilterObjectChange = (property: keyof FilterValidFilterObject, value: any) => {
    setFilter((filter) => {
      const newFilter: FilterValidFilterObject = { ...filter, [property]: value };

      localStorage.setResource(newFilter);

      return newFilter;
    });
  };

  const onResetFilters = () => {
    setFilter(defaultFilters);
    localStorage.setResource(defaultFilters);
  };

  const modal = (
    <FiltersModal
      open={showModal}
      onClose={() => setShowModal(false)}
      onResetFilters={onResetFilters}
      resetDisabled={!nonDefaultFilterApplied}
    >
      {filterConfigurations.map((filterConfiguration) => {
        switch (filterConfiguration.type) {
          case 'enum':
            const enumValue = getFilterValue(filterConfiguration, filter);

            const options = filterConfiguration.options.map((option) => ({
              value: option,
              label: filterConfiguration.getOptionLabel(option),
            }));

            return (
              <EnumFilter
                key={filterConfiguration.field}
                label={filterConfiguration.label}
                options={options}
                value={enumValue}
                onChange={(value) => onFilterObjectChange(filterConfiguration.field, value)}
                readOnly={filterConfiguration.readOnly}
                strict={filterConfiguration.strict}
              />
            );
          case 'resource':
            const { field, ...configurations } = filterConfiguration;
            const resourceValue = getFilterValue(filterConfiguration, filter);

            return (
              <ResourceFilter
                {...configurations}
                key={field}
                value={resourceValue}
                onChange={(value) => onFilterObjectChange(field, value)}
                readOnly={filterConfiguration.readOnly}
              />
            );
          case 'year':
            const yearValue = getFilterValue(filterConfiguration, filter);

            return (
              <YearFilter
                label={filterConfiguration.label}
                key={filterConfiguration.field}
                options={filterConfiguration.options}
                value={yearValue}
                onChange={(value) => onFilterObjectChange(filterConfiguration.field, value)}
                modalZIndex={1501}
                readOnly={filterConfiguration.readOnly}
              />
            );
          case 'checkbox':
            const checked = getFilterValue(filterConfiguration, filter);

            return (
              <FormControlLabel
                control={(
                  <Checkbox
                    key={filterConfiguration.field}
                    checked={checked}
                    onChange={(_, value) => onFilterObjectChange(filterConfiguration.field, value)}
                    disabled={filterConfiguration.readOnly}
                  />
                )}
                label={<Typography>{filterConfiguration.label} {filterConfiguration.readOnly && <i>(Read Only)</i>}</Typography>}
              />
            );
          default:
            return null;
        }
      })}
    </FiltersModal>
  );

  const filteredRows = getFilteredRowsFromFilterConfigurations(rows, filterConfigurations, filter);
  const filteredResourceIds = filteredRows.map(getRowId);
  const filterFunc = (value: Row | string) => {
    const id = typeof value === 'string' ? value : (getRowId(value));

    return filteredResourceIds.includes(id);
  };
  const output: UseFilterOutput<Row> = {
    modal,
    setShowModal,
    nonDefaultFilterApplied,
    filter,
    setFilter,
    filterFunc,
    filteredRows,
    onResetFilters,
    sidebarActionConfig: {
      label: 'Filters',
      content: <FiltersActionContent filter={filter} filterFieldConfigurations={filterConfigurations} />,
      onClickMore: () => setShowModal(true)
    },
  };

  return output;
};

export const getFilteredRowsFromFilterConfigurations = <R extends FilterValidRow>(rows: R[], filterConfigurations: FilterFieldConfiguration<R>[], filter?: FilterValidFilterObject) => {
  const filters = filter ?? getDefaultFilterObject(filterConfigurations);

  return rows.filter((row) => {
    return filterConfigurations.every((filterConfiguration) => {
      if (filterConfiguration.type === 'enum') {
        const enumValue = getFilterValue(filterConfiguration, filters);

        if (filterConfiguration.strict) {
          return enumValue.every((value) => filterConfiguration.predicate(row, value));
        }
        return enumValue.some((value) => filterConfiguration.predicate(row, value));
      } else if (filterConfiguration.type === 'resource') {
        const resourceValue = getFilterValue(filterConfiguration, filters);

        if (resourceValue === 'any') {
          return true;
        }

        return resourceValue.some((value) => filterConfiguration.predicate(row, value));
      } else if (filterConfiguration.type === 'year') {
        const yearValue = getFilterValue(filterConfiguration, filters);

        if(typeof yearValue === 'number') {
          return filterConfiguration.yearPredicate(row, yearValue);
        }

        const selectedOption = filterConfiguration.options.find(option => option.value === yearValue);

        if(selectedOption) {
          return selectedOption.predicate(row);
        }
      } else if (filterConfiguration.type === 'checkbox') {
        const checked = getFilterValue(filterConfiguration, filters);

        return filterConfiguration.predicate(row, checked);
      }

      return true;
    });
  });
};