import { authAxiosRequest } from 'api/axios';
import { DeepOptionalToNull } from 'api/resources/resources.types';
import { DeepPartial } from 'types';

export type UpdateCustomerInput = DeepPartial<DeepOptionalToNull<{
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
}>>;

export const updateCustomer = (id: string, data: UpdateCustomerInput) => {
  return authAxiosRequest(`/api/customers/${id}`, { method: 'PUT', data });
};