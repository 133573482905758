import { Box, Typography } from '@mui/material';
import { GetEventsResponse, GetTeamsResponse } from 'api/actions';
import { WrapWithStatusIndicator } from 'components/StatusIndicator';
import { getTeamShortLabel } from 'helpers';
import uniq from 'lodash/uniq';
import React from 'react';

export type TeamsSchedulingCalendarSummaryProps = {
  events: GetEventsResponse['data'];
  eventDates: GetEventsResponse['data'][number]['dates'];
  teams: GetTeamsResponse['data'];
  containerIsMediumOrSmall: boolean;
};

export const TeamsSchedulingCalendarSummary: React.FC<TeamsSchedulingCalendarSummaryProps> = props => {
  const { eventDates, events } = props;
  const teamIds = uniq(events.map(event => event.team?._id));
  const teams = props.teams.filter(team => teamIds.includes(team._id));
  const noTeamEventIds = events.filter(event => !event.team).map(event => event._id);
  const noTeamEventDates = eventDates.filter(eventDate => noTeamEventIds.includes(eventDate.event));
  const teamDaysHash = teams.reduce((r, team) => {
    const teamEventIds = events.filter(e => e.team?._id === team._id).map(e => e._id);
    const teamEventDates = eventDates.filter(eventDate => teamEventIds.includes(eventDate.event));

    return { ...r, [team._id]: teamEventDates };
  }, {} as Record<string, GetEventsResponse['data'][number]['dates']>);

  return (
    <Box
      my={2}
      mx={1}
      display="flex"
      flexDirection={props.containerIsMediumOrSmall ? 'column' : 'row'}
      justifyContent="flex-end" alignItems={props.containerIsMediumOrSmall ? 'center' : 'flex-end'}
      gap={1}
    >
      <LabeledNumberContainer
        number={eventDates.length}
        description={({ plural }) => `show day${plural ? 's' : ''}`}
      />
      <SummaryDivider isMediumOrSmall={props.containerIsMediumOrSmall} />
      <LabeledNumberContainer
        number={teams.length}
        description={({ plural, none }) => `departing team${plural ? 's' : ''}${none ? '' : ':'}`}
      />
      {teams.map(team => (
        <Box display="flex" alignItems="center" key={team._id} gap={0.5} mb="-2px">
          <WrapWithStatusIndicator status={team.color}>
            <Typography variant="subtitle1" fontWeight={500}>{getTeamShortLabel(team.name)}</Typography>
            <Typography variant="body2" color="text.secondary">
              ({teamDaysHash[team._id].length} day{teamDaysHash[team._id].length !== 1 && 's'})
            </Typography>
          </WrapWithStatusIndicator>
        </Box>
      ))}
      <SummaryDivider isMediumOrSmall={props.containerIsMediumOrSmall} />
      <LabeledNumberContainer
        number={noTeamEventIds.length}
        description={({ plural }) => `show${plural ? 's' : ''}`}
      />
      <Typography>with total</Typography>
      <LabeledNumberContainer
        number={noTeamEventDates.length}
        description={({ plural }) => `day${plural ? 's' : ''}`}
      />
      <Typography>{noTeamEventIds.length === 1 ? 'is' : 'are'} not assigned a team</Typography>
    </Box>
  );
};

type LabeledNumberContainerProps = {
  number: number;
  description: (arg: {plural?: boolean; none: boolean}) => string;
};

const LabeledNumberContainer: React.FC<LabeledNumberContainerProps> = ({ number, description }) => {
  return (
    <>
      <Typography
        bgcolor="primary.background"
        fontWeight={500}
        component="span"
        px={1}
        border={theme => `1px solid ${theme.palette.primary.main}`}
      >
        {number}
      </Typography>
      <Typography>
        {description({ plural: number !== 1, none: number === 0 })}
      </Typography>
    </>
  );
};

type SummaryDividerProps = {
  isMediumOrSmall: boolean;
};

const SummaryDivider: React.FC<SummaryDividerProps> = ({ isMediumOrSmall }) => {
  if (isMediumOrSmall) {
    return <Box fontSize="40px" my={-2} mx={1} fontWeight={100} color="grey.400">—</Box>;
  }

  return <Box fontSize="40px" my={-2} mx={1} fontWeight={100} color="grey.400">|</Box>;
};
