import { AddressVerificationInput, PlaceDistanceAndDurationContent, SectionCard } from 'components';
import { Box, Typography } from '@mui/material';
import { updateEvent } from 'api/actions';
import { object, string } from 'yup';
import { PlaceDefaultEnum } from 'api/resources';
import { useEventContext } from '../event.context';
import { EventSectionCardRowEditable, EmbeddedMap } from '../components';
import { EventPageSection, EventPageSectionRowId } from '../types';

export const LocationSection: React.FC = () => {
  const { event } = useEventContext();

  return (
    <SectionCard title="Location" id={EventPageSection.LOCATION}>
      <EventSectionCardRowEditable
        title="Event Address"
        rowId={EventPageSectionRowId.ADDRESS}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { placeId: values.placeId }),
          initialValues: { location: event.place.address ?? '', placeId: event.place.googlePlaceId ?? '' },
          validationSchema: object({
            location: string().required('Location Required'),
            placeId: string().default('')
          })
        }}
        form={(
          <AddressVerificationInput
            name="location"
            label="Location"
            placeIdName="placeId"
          />
        )}
        bottomContent={(
          <Box display="flex" flexDirection="column" gap={2}>
            <PlaceDistanceAndDurationContent
              fromLabel="Studio"
              toLabel="Event"
              placeMatrix={event.place.fromStudioMatrix}
              routeDetails={{
                fromAddress: PlaceDefaultEnum.studio,
                toAddress: event.place.address,
              }}
            />
            <EmbeddedMap placeId={event.place.googlePlaceId} />
          </Box>
        )}
      >
        <Typography>{event.place.address}</Typography>
      </EventSectionCardRowEditable>
    </SectionCard>
  );
};