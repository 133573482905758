import { useShopifyProduct } from 'queries/shopify';
import { useProductOutletContext } from '../Product.base';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { SectionCardRow, SectionCard, CardContentLink } from 'components';
import { useMemo } from 'react';
import { ProductPageSection } from '../types';

export const ShopifySection = () => {
  const { product } = useProductOutletContext();
  const { data: shopifyProduct = null, isInitialLoading: shopifyProductLoading } = useShopifyProduct(product.shopifyProductId ?? 0, { enabled: !!product.shopifyProductId });

  const content = useMemo(() => {
    if(shopifyProductLoading) {
      return <Skeleton variant="rectangular" width="100%" height={200} />;
    }

    if (!product.shopifyProductId || !shopifyProduct) {
      return (
        <Box height={200} display="flex" alignItems="center" justifyContent="center">
          <Typography fontStyle="italic" variant="body2">This product is not linked to shopify</Typography>
        </Box>
      );
    }

    return (
      <>
        <SectionCardRow title="Title & Handle">
          <Stack gap={1}>
            <Typography>{shopifyProduct.title}</Typography>
            <Typography variant="body2" color="text.secondary">Handle: {shopifyProduct.handle}</Typography>
          </Stack>
        </SectionCardRow>
        <SectionCardRow title="Status">{shopifyProduct.status}</SectionCardRow>
        <SectionCardRow title="Tags">{shopifyProduct.tags}</SectionCardRow>
      </>
    );
  }, [ product.shopifyProductId, shopifyProduct, shopifyProductLoading ]);

  return (
    <SectionCard title="Shopify" id={ProductPageSection.SHOPIFY} actions={shopifyProduct ? <CardContentLink href={shopifyProduct.storeUrl} title="View on Shopify" openInNew /> : null}>
      {content}
    </SectionCard>
  );
};
