import { CardContentLink, SectionCard, SectionCardRow, TextInput, ToggleButtonGroup, YesNoUnknownIconSwitch } from 'components';
import React from 'react';
import { SaleSectionCardRowEditable } from '../components/SaleSectionCardRowEditable.component';
import { useSaleOutletContext } from '../Sale.base';
import { SalePageSectionRowId } from '../types';
import { updateSale } from 'api/actions';
import { mixed, number, object } from 'yup';
import { frameEnumToValueHash, getFrameEnumFromValue, getSelectOptionsFromEnumHelper, saleEnumHelpers } from 'helpers';
import { FrameEnum } from 'types';
import { Box, ToggleButton } from '@mui/material';
import { formSx } from 'styles';
import { DateService, currencyFormatter } from 'services';
import { ROUTING_CONFIG } from 'constants/routing-config';

export const OverviewSection = () => {
  const { sale } = useSaleOutletContext();
  const order = sale.order;

  return (
    <SectionCard title="Overview">
      <SectionCardRow title="Product">
        {sale.product.name}
      </SectionCardRow>
      {order && (
        <SectionCardRow title="Order">
          <CardContentLink title={order.name} href={`/${ROUTING_CONFIG.orders}/${order._id}`} />
        </SectionCardRow>
      )}
      <SectionCardRow title="Log">Logged {sale.order.user ? <>by <b>{sale.order.user?.name}</b></> : null} on {DateService.getFormattedDateAndTime(sale.createdAt, 'ddd, MMM Do, YYYY', sale.order.event?.timezone)} <b>{sale.order.type}</b></SectionCardRow>
      <SaleSectionCardRowEditable
        title="Price"
        rowId={SalePageSectionRowId.SALE_PRICE}
        formikProps={{
          initialValues: { salePrice: sale.salePrice },
          onSubmit: (values) => updateSale(sale._id, { salePrice: values.salePrice }),
          validationSchema: object({ salePrice: number().required('Price Required') })
        }}
        form={<TextInput name="salePrice" type="number" label="Sale Price" fullWidth />}
      >
        {currencyFormatter.format(sale.salePrice)}
      </SaleSectionCardRowEditable>
      <SaleSectionCardRowEditable
        title="Frame"
        rowId={SalePageSectionRowId.FRAME}
        formikProps={{
          initialValues: { frame: getFrameEnumFromValue(sale.frame) },
          onSubmit: (values) => updateSale(sale._id, { frame: frameEnumToValueHash[values.frame], }),
          validationSchema: object({ frame: mixed<FrameEnum>().oneOf([ FrameEnum.frame, FrameEnum.noFrame ]).required() })
        }}
        form={(
          <Box sx={formSx.formGroup}>
            <ToggleButtonGroup label="Frame" name="frame" color="primary" exclusive fullWidth>
              {getSelectOptionsFromEnumHelper(saleEnumHelpers.frame).map(({ value, label }) => (
                <ToggleButton value={value} key={value}>{label}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        )}
      >
        <YesNoUnknownIconSwitch value={sale.frame} />
      </SaleSectionCardRowEditable>
      {(sale.order.event && sale.order.eventDate) && <SectionCardRow title="Event Date">{sale.order.event.name} - {DateService.getFormattedDate(DateService.dayjsTz(sale.order.eventDate.dateAsUtc), 'ddd, MMM Do, YYYY')}</SectionCardRow>}
      {sale.order.storefront && <SectionCardRow title="Store Front">{sale.order.storefront.name}</SectionCardRow>}
      <SectionCardRow title="Order">{sale.order?.name}</SectionCardRow>
      <SectionCardRow title="Sale ID">{sale._id}</SectionCardRow>
    </SectionCard>
  );
};
