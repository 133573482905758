import { CalendarTodayOutlined, ReportProblemOutlined } from '@mui/icons-material';
import { Box, Chip, Collapse, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { DateService } from 'services';
import { eventDateEnumHelpers, getEventDateTimePeriod, getEventLodgingPlaceForDate, userEnumHelpers } from 'helpers';
import { CardContentMarkdown, PlaceDistanceAndDurationContent } from 'components';
import { useIsMobile } from 'hooks';
import { EventDate, EventDateTypeEnum, PlaceMatrix, User } from 'api/resources';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { GetEventsResponse } from 'api/actions';

export type EventDateContentData = {
  event: Pick<GetEventsResponse['data'][number], 'lodging'>;
  eventDate: {
    _id: EventDate['_id'];
    dateAsUtc: EventDate['dateAsUtc'];
    type: EventDate['type'];
    staffNeeded: EventDate['staffNeeded'];
    notes: EventDate['notes'];

    setupType: EventDate['setupType'];
    breakdownType: EventDate['breakdownType'];

    setupEndTime: EventDate['setupEndTime'];
    arriveAsEarlyAs: EventDate['arriveAsEarlyAs'];
    noVehiclesAllowedAfter: EventDate['noVehiclesAllowedAfter'];
    allVehiclesMustBeRemovedBy: EventDate['allVehiclesMustBeRemovedBy'];

    bufferTimeInMinutes: EventDate['bufferTimeInMinutes'];
    displaySetupInMinutes: EventDate['displaySetupInMinutes'];
    startTime: EventDate['startTime'];
    endTime: EventDate['endTime'];
    confirmedTimes: EventDate['confirmedTimes'];

    staff: ResourceWithPopulated<EventDate['staff'][number], {
      user: PickFromResource<User, 'name'>;
    }>[];
  };

  eventDates: PickFromResource<EventDate, 'dateAsUtc'>[];

  travelData?: {
    studioToEventMatrix: PlaceMatrix;
    lodgingToEventMatrix?: PlaceMatrix;
  };
};

export type EventDateContentProps = {
  data: EventDateContentData;
  hasWarning?: boolean;
  hideStaff?: boolean;
  isExpanded?: boolean;
};

export const EventDateContent: React.FC<EventDateContentProps> = ({ data, hasWarning = false, hideStaff = false, isExpanded = true, }) => {
  const isMobile = useIsMobile();
  const { event, eventDate, travelData, eventDates } = data;
  const {
    type,
    setupType,
    breakdownType,
    staff,
    staffNeeded = 0,
    notes,
    confirmedTimes,
  } = eventDate;

  const sortedEventDates = [ ...eventDates ].sort((a, b) => a.dateAsUtc.localeCompare(b.dateAsUtc));
  const eventDateIndex = sortedEventDates.findIndex(({ _id }) => _id === eventDate._id);
  const wasLodging = !!eventDateIndex && getEventLodgingPlaceForDate(event, DateService.dayjsTz(eventDates[eventDateIndex - 1].dateAsUtc));

  const eventDateOrderLabel = `(Day ${eventDateIndex + 1} of ${sortedEventDates.length})`;

  const timePeriod = getEventDateTimePeriod(eventDate.dateAsUtc);
  const startTime = DateService.dayjs(eventDate.startTime, 'HH:mm').format('h:mma');
  const endTime = DateService.dayjs(eventDate.endTime, 'HH:mm').format('h:mma');

  const bufferTimeInMinutes = eventDate.bufferTimeInMinutes ?? 0;

  const mainLabels = useMemo(() => {
    const labels = [
      { label: eventDateEnumHelpers.type.getLabel(type), Icon: eventDateEnumHelpers.type.getIcon(type) },
    ];

    if(eventDate.type === EventDateTypeEnum.regular) {
      labels.push(
        { label: `${eventDateEnumHelpers.setupBreakdownType.getLabel(setupType)} setup`, Icon: eventDateEnumHelpers.setupBreakdownType.getIcon(setupType) },
        { label: `${eventDateEnumHelpers.setupBreakdownType.getLabel(breakdownType)} breakdown`, Icon: eventDateEnumHelpers.setupBreakdownType.getIcon(breakdownType) }
      );
    }

    return labels.filter(({ label }) => label !== 'Unknown')
      .map(({ label, Icon }) => (
        <Box display="flex" alignItems="center" gap={0.75} key={label} color="text.secondary">
          <Icon fontSize="small" />
          <Typography fontSize="small">{label}</Typography>
        </Box>
      ));
  }, [ breakdownType, eventDate.type, setupType, type ]);

  const travelContent = useMemo(() => {
    if (!travelData) {
      return null;
    }

    const { studioToEventMatrix, lodgingToEventMatrix } = travelData;

    let leaveBy: string | null = null;
    let placeDistanceAndDuration: React.ReactNode = null;

    if (wasLodging) {
      placeDistanceAndDuration = <PlaceDistanceAndDurationContent fromLabel="Lodging" toLabel="Event" placeMatrix={lodgingToEventMatrix} displayMissingMatrixMessage="Add a lodging address" />;

      if (lodgingToEventMatrix) {
        const durationInSeconds = lodgingToEventMatrix.duration;

        leaveBy = DateService.dayjs(eventDate.arriveAsEarlyAs || eventDate.setupEndTime, 'HH:mm')
          .subtract(durationInSeconds, 'seconds')
          .subtract(bufferTimeInMinutes + eventDate.displaySetupInMinutes, 'minutes')
          .format('h:mma');
      }
    } else {
      placeDistanceAndDuration = <PlaceDistanceAndDurationContent fromLabel="Studio" toLabel="Event" placeMatrix={studioToEventMatrix} />;

      const durationInSeconds = studioToEventMatrix.duration;

      leaveBy = DateService.dayjs(eventDate.setupEndTime || eventDate.startTime, 'HH:mm')
        .subtract(durationInSeconds, 'seconds')
        .subtract(bufferTimeInMinutes + eventDate.displaySetupInMinutes, 'minutes')
        .format('h:mma');
    }

    return (
      <Box display="flex" flexDirection="column" gap={0.25}>
        {placeDistanceAndDuration}
        {leaveBy && <Typography variant="body2" fontSize="small">Leave by: <Typography component="span" fontSize="inherit" variant="body2" fontWeight={500}>{leaveBy}</Typography> (with no traffic)</Typography>}
      </Box>
    );
  }, [ bufferTimeInMinutes, eventDate.arriveAsEarlyAs, eventDate.displaySetupInMinutes, eventDate.setupEndTime, eventDate.startTime, travelData, wasLodging ]);

  const setupLabel = useMemo(() => {
    if (!eventDate.setupEndTime) {
      return null;
    }

    const setupStartTime = DateService.dayjs(eventDate.setupEndTime, 'HH:mm')
      .subtract(eventDate.displaySetupInMinutes, 'minutes')
      .format('h:mma');
    const setupEndTime =  DateService.dayjs(eventDate.setupEndTime, 'HH:mm').format('h:mma');

    return (
      <Typography component="span" variant="body2" color="text.secondary">
        Setup: <Typography variant="body2" component="span" color="text.primary">{setupStartTime} - {setupEndTime}</Typography>
      </Typography>
    );
  }, [ eventDate.displaySetupInMinutes, eventDate.setupEndTime ]);

  return (
    <Box width="100%">

      <Box display="flex" alignItems="center" justifyContent="space-between" bgcolor="primary.background" py={1} px={2} pr={{ xs: 1, sm: 2 }} gap={{ xs: 1, sm: 2 }}>
        <Box display="flex" alignItems="center" gap={{ xs: 1, sm: 2 }}>
          <CalendarTodayOutlined fontSize="small" color="primary" />
          <Typography component="span" fontWeight={500}>
            {DateService.dayjsTz(eventDate.dateAsUtc).format(isMobile ? 'ddd, MMM Do, YYYY' : 'dddd, MMM Do, YYYY')}
            <Typography component="span" fontSize="small" whiteSpace="nowrap">&nbsp;{eventDateOrderLabel}</Typography>
          </Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          {hasWarning && <ReportProblemOutlined fontSize="small" color="warning" />}
          <Chip
            label={timePeriod}
            variant="filled"
            size="small"
            color={(timePeriod === 'upcoming' || timePeriod === 'today') ? 'primary' : undefined}
            sx={{ fontWeight: 500 }}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" pl={2} pr={1} py={1} gap={2}>
        <Box display="flex" columnGap={1} rowGap={2} flexWrap="wrap">
          <Box whiteSpace="nowrap" flex={2} display="flex" flexDirection="column">
            <Collapse in={isExpanded} unmountOnExit>
              <Box display="flex" alignItems="center" gap={1.25} flexWrap="wrap" mb={0.5}>
                {mainLabels}
              </Box>
            </Collapse>
            <Typography mb={travelContent ? 0.5 : 0}>
              {eventDate.type === EventDateTypeEnum.regular && setupLabel}
              <Typography>
                {eventDate.type !== EventDateTypeEnum.travel && (
                  <>
                    {startTime} – {endTime}
                    {!confirmedTimes && <Typography component="span" variant="body2" color="warning.main">&nbsp;Not Confirmed</Typography>}
                  </>
                )}
              </Typography>
            </Typography>
            {travelContent}
          </Box>
          {!hideStaff && (
            <Box flex={1} display="flex" flexDirection="column" gap={1}>
              <Box textAlign="center">
                <Typography variant="body2">Scheduled Staff</Typography>
                <Typography variant="body2" component="span" whiteSpace="nowrap" fontSize="small"><Typography component="span" fontWeight={500}>{staffNeeded}</Typography> Needed, </Typography>
                <Typography variant="body2" component="span" whiteSpace="nowrap" fontSize="small"><Typography component="span" fontWeight={500}>{staff.length}</Typography> Scheduled</Typography>
              </Box>
              <Box flex={1} display="flex" alignItems="center" justifyContent="center">
                <Box display="flex" gap={0.5} flexWrap="wrap" alignItems="center" justifyContent="center">
                  {staff.map((staff) => {
                    return (
                      <Box key={staff._id} display="flex">
                        <Chip
                          size="small"
                          color={userEnumHelpers.staffStatus.getColor(staff.status)}
                          variant="outlined"
                          label={staff.user.name}
                          sx={{ fontWeight: 500, borderRadius: '4px' }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {notes && (
          <Box>
            <CardContentMarkdown maxHeight={110} markdown={`Notes: ${notes}`} />
          </Box>
        )}
      </Box>

    </Box>
  );
};