import { useEventTeamChangeOptions } from 'queries';
import { useEffect, useMemo } from 'react';
import { useEventContext } from '../event.context';
import { Autocomplete, EventResourceOptionsAutocomplete } from 'components';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { formSx } from 'styles';
import { useFormikContext } from 'formik';
import { EventResourceForm } from 'types';
import { useUsersAsResourceOptions } from 'queries/user';

export const TeamForm: React.FC = () => {
  const { event } = useEventContext();
  const hasDates = event?.dates.length;
  const { data: teamChangeOptions = [], isInitialLoading: teamChangeOptionsLoading } = useEventTeamChangeOptions(event._id);
  const { data: users = [], isInitialLoading: usersLoading } = useUsersAsResourceOptions();
  const { values, initialValues, setFieldValue } = useFormikContext<EventResourceForm['team']>();

  const teamInput = useMemo(() => {
    const name = 'team';
    const label = 'Team';

    if (event && hasDates) {
      return (
        <EventResourceOptionsAutocomplete
          id={name}
          name={name}
          label={label}
          loading={teamChangeOptionsLoading}
          options={teamChangeOptions}
          transformValue={(team) => team?.id}
          disableClearable={Boolean(event.salesCount)}
          getOptionDisabled={(team) => Boolean(team.eventDateConflicts?.length)}
          getOptionLabel={(team) => team?.name}
          autoFocus
          openOnFocus
        />
      );
    }

    return (
      <Autocomplete
        name="team"
        label="Team"
        options={[]}
        disabled
        helperText="Select team after dates are added"
      />
    );
  }, [ event, hasDates, teamChangeOptionsLoading, teamChangeOptions ]);

  const hasTeamChange = useMemo(() => values.team !== initialValues.team, [ initialValues.team, values.team ]);

  useEffect(() => {
    const teamLead = teamChangeOptions.find(option => option.id === values.team)?.teamLead;

    if (teamLead) {
      setFieldValue('teamManager', teamLead);
    }
  }, [ setFieldValue, teamChangeOptions, values.team ]);

  return (
    <Box sx={formSx.formGroup}>
      {hasTeamChange && !!event.salesCount && (
        <Alert severity="warning">
          <AlertTitle>Team Change</AlertTitle>
          <Typography>By changing the team, all sales associated with this event will undergo a team change and stocks will be updated. Please check with a manager before making this change.</Typography>
        </Alert>
      )}
      {teamInput}
      <Autocomplete
        name="teamManager"
        label="Team Manager"
        options={users}
        getOptionLabel={(opt) => opt?.name}
        transformValue={(opt) => opt?._id}
        loading={usersLoading}
      />
    </Box>
  );
};