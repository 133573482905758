import { Table } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ordersTableColumns } from '../helpers';
import { GridPaginationModel } from '@mui/x-data-grid';
import { useNotFulfilledOrders } from 'queries';
import { OrdersTableDetailsPanelContent } from '../components/OrdersTableDetailsPanelContent.component';

export const UnfulfilledOrders = () => {
  const [ paginationModel, setPaginationModel ] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const navigate = useNavigate();
  const { data: { orders, totalCount } = { orders: [], totalCount: 0 }, isInitialLoading: ordersLoading } = useNotFulfilledOrders({ page: paginationModel.page });

  return (
    <Table
      getDetailPanelContent={OrdersTableDetailsPanelContent}
      getDetailPanelHeight={() => 'auto'}
      loading={ordersLoading}
      rows={orders}
      columns={ordersTableColumns}
      getRowId={row => row._id}
      emptyMessage="No orders found"
      pagination
      pageSizeOptions={[ 50 ]}
      onRowClick={({ row }) => navigate(row._id)}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      rowCount={totalCount}
    />
  );
};
