import { Box, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { EventPageSectionRowId, EventSectionRowForm } from '../types';
import { TextInput } from 'components/Inputs';
import { FormDatePicker } from 'components/DateTimePicker';
import { formSx } from 'styles';

type EventPaymentDueValue = 'dueAsSoonAsPossible' | 'dueWithApplication' | 'dueDate';

export const EventPaymentDueForm = () => {
  const { values, setFieldValue } = useFormikContext<EventSectionRowForm[EventPageSectionRowId.PAYMENTS_DUE]>();
  const value = useMemo(() => {
    if (values.dueWithApplication) {
      return 'dueWithApplication';
    }
    if (values.dueAsSoonAsPossible) {
      return 'dueAsSoonAsPossible';
    }

    return 'dueDate';
  }, [ values.dueAsSoonAsPossible, values.dueWithApplication ]);

  useEffect(() => {
    if (value !== 'dueDate') {
      setFieldValue('dueDate', '');
    }
  }, [ setFieldValue, value ]);

  const onChange = (value: EventPaymentDueValue) => {
    if (value === 'dueWithApplication') {
      setFieldValue('dueWithApplication', true);
      setFieldValue('dueAsSoonAsPossible', false);
    } else if (value === 'dueAsSoonAsPossible') {
      setFieldValue('dueWithApplication', false);
      setFieldValue('dueAsSoonAsPossible', true);
    } else {
      setFieldValue('dueWithApplication', false);
      setFieldValue('dueAsSoonAsPossible', false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box sx={formSx.formGroup}>
          <TextInput name="amountDue" label="Amount Due *" type="number" startAdornment="$" />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={formSx.formGroup}>
          <FormLabel id="due-radio-buttons-group-label">When is this payment due?</FormLabel>
          <RadioGroup
            aria-labelledby="due-radio-buttons-group-label"
            value={value}
            onChange={(_, value) => onChange(value as EventPaymentDueValue)}
            sx={{ mt: 1 }}
          >
            <FormControlLabel value="dueWithApplication" control={<Radio />} label="Due with application" />
            <FormControlLabel value="dueAsSoonAsPossible" control={<Radio />} label="Due as soon as possible" />
            <FormControlLabel value="dueDate" control={<Radio />} label="Due on (select date):" />
          </RadioGroup>
          <FormDatePicker
            name="dueDate"
            label="Due Date"
            sx={{ ml: 3, flex: 1 }}
            disabled={value !== 'dueDate'}
          />
        </Box>
      </Grid>
    </Grid>
  );
};