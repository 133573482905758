import { User, UserEmployeeRoleEnum, UserRoleEnum } from 'api/resources';
import { UnknownEnum, YesNoEnum } from 'types';

export type ProfilePageSectionProps = {
  user: User;
};

export const enum ProfilePageSection {
  OVERVIEW = 'overview',
  DRIVING = 'driving',
  CREDENTIALS = 'credentials',
  SESSIONS = 'sessions',
  SALES = 'sales',
  SCHEDULE = 'schedule'
}

export const enum UserPageSectionRowId {
  NAME = 'name',
  EMAIL = 'email',
  ROLE = 'role',
  PHONE_NUMBER = 'phone_number',
  BIRTHDAY = 'birthday',
  PAY_RATE = 'pay_rate',
  EMPLOYEE_ROLES = 'employee_roles',
  PASSWORD = 'password',
  PIN = 'pin',
  DRIVER_LICENSE = 'driver_license',
  PERSONAL_VEHICLE = 'personal_vehicle',
  COVERED_BY_BUSSINESS_INSURANCE_AS_DRIVER = 'covered_by_bussiness_insurance_as_driver',
  CAN_DRIVE_BOX_TRUCK = 'can_drive_box_truck',
  CAN_PULL_TRAILER = 'can_pull_trailer',
  DRIVING_NOTES = 'driving_notes'
}

export type UserSectionRowForm = {
  [UserPageSectionRowId.NAME]: {
    name: string;
  };
  [UserPageSectionRowId.EMAIL]: {
    email: string;
  };
  [UserPageSectionRowId.ROLE]: {
    role: UserRoleEnum;
  };
  [UserPageSectionRowId.PHONE_NUMBER]: {
    phoneNumber: string;
  };
  [UserPageSectionRowId.BIRTHDAY]: {
    birthday: string | null;
  };
  [UserPageSectionRowId.PAY_RATE]: {
    payRate: number;
  };
  [UserPageSectionRowId.EMPLOYEE_ROLES]: {
    employeeRoles: UserEmployeeRoleEnum[];
  };
  [UserPageSectionRowId.PASSWORD]: {
    currentPassword: string;
    newPassword: string;
  };
  [UserPageSectionRowId.PIN]: {
    password: string;
    newPin: string;
  };
  [UserPageSectionRowId.DRIVER_LICENSE]: {
    driverLicence: YesNoEnum | UnknownEnum;
  };
  [UserPageSectionRowId.PERSONAL_VEHICLE]: {
    personalVehicle: YesNoEnum | UnknownEnum;
  };
  [UserPageSectionRowId.COVERED_BY_BUSSINESS_INSURANCE_AS_DRIVER]: {
    coveredByBussinessInsuranceAsDriver: YesNoEnum | UnknownEnum;
  };
  [UserPageSectionRowId.CAN_DRIVE_BOX_TRUCK]: {
    canDriveBoxTruck: YesNoEnum | UnknownEnum;
  };
  [UserPageSectionRowId.CAN_PULL_TRAILER]: {
    canPullTrailer: YesNoEnum | UnknownEnum;
  };
  [UserPageSectionRowId.DRIVING_NOTES]: {
    drivingNotes: string;
  };
};