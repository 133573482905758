import { Box } from '@mui/material';
import { TextInput } from 'components';
import { useFormikContext } from 'formik';
import kebabCase from 'lodash/kebabCase';
import  { useEffect } from 'react';
import { formSx } from 'styles';
import { ProductPageSectionRowId, ProductSectionRowForm } from '../types';

export const ProductNameForm = () => {
  const { values, setFieldValue } = useFormikContext<ProductSectionRowForm[ProductPageSectionRowId.NAME]>();

  useEffect(() => {
    const kebabCaseName = kebabCase(values.name.toLowerCase());

    setFieldValue('sku', kebabCaseName);
  }, [ setFieldValue, values.name ]);

  return (
    <Box sx={formSx.formGroup}>
      <TextInput
        trim
        fullWidth
        id="name"
        name="name"
        label="Product Name *"
      />
      <TextInput
        trim
        fullWidth
        defaultValue=""
        id="sku"
        name="sku"
        label="Sku *"
        disabled
        helperText="Based on Product Name"
      />
    </Box>
  );

};
