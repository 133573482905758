import { Autocomplete, EventResourceOptionsAutocomplete } from 'components';
import { useEventVehicleChangeOptions } from 'queries';
import { Typography } from '@mui/material';
import { currencyFormatter } from 'services';
import { getVehicleDistanceCost } from 'helpers';
import { useEventContext } from '../event.context';

const name = 'vehicle';
const label = 'Vehicle';

export const VehicleForm: React.FC = () => {
  const { event } = useEventContext();
  const { data: vehicleChangeOptions = [], isInitialLoading: loading } = useEventVehicleChangeOptions(event._id);

  if (event && event.dates.length) {
    return (
      <EventResourceOptionsAutocomplete
        id={name}
        name={name}
        label={label}
        loading={loading}
        options={vehicleChangeOptions}
        transformValue={(vehicle) => vehicle?.id}
        disableClearable={false}
        getOptionDisabled={(vehicle) => Boolean(vehicle.eventDateConflicts?.length)}
        getOptionLabel={(vehicle) => vehicle?.name}
        renderOptionTitle={(vehicle) => <Typography>{vehicle.name}&ensp;{vehicle.mpg && <Typography variant="body2" component="span" color="text.secondary">(one-way commute will cost {currencyFormatter.format(getVehicleDistanceCost(vehicle.mpg, event.place.fromStudioMatrix.distance))})</Typography>}</Typography>}
        autoFocus
        openOnFocus
      />
    );
  }

  return (
    <Autocomplete
      name={name}
      label={label}
      options={[]}
      disabled
      helperText="Select vehicle after dates are added"
    />
  );
};