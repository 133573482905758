import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetLoggingPortalDataResponse, LoggingPortalResourceTypeEnum, updateSale } from 'api/actions';
import { UpdateResourceModal, useAlertSnackbar } from 'components';
import { Formik } from 'formik';
import { frameEnumToValueHash, removeUnchanged } from 'helpers';
import { FrameEnum } from 'types';
import { saleValidationSchema } from './validation';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { QUERY_KEY } from 'queries/query-keys';
import { UserRoleEnum } from 'api/resources';
import { EditSaleForm } from './EditSale.form';

export type EditSaleModalProps = {
  sale: GetLoggingPortalDataResponse['data']['recentOrders'][number]['sales'][number];
  onClose: () => void;
};

export type EditSaleFormType = {
  product: string;
  salePrice: number;
  frame: FrameEnum;
};

export const EditSaleModal: React.FC<EditSaleModalProps> = ({ sale, onClose }) => {
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const { resourceType, resourceId, userOptions } = useLoggingPortalContext();

  const updateMutation = useMutation({
    mutationFn: (form: EditSaleFormType) => {
      const update = removeUnchanged(form, initialValues);

      return updateSale(sale._id, {
        product: update.product,
        salePrice: update.salePrice,
        frame: update.frame && frameEnumToValueHash[update.frame],
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        resourceType === LoggingPortalResourceTypeEnum.eventDate
          ? QUERY_KEY.LOGGING_PORTAL_DATA_FOR_EVENT_DATE(resourceId)
          : QUERY_KEY.LOGGING_PORTAL_DATA_FOR_STOREFRONT(resourceId)
      );
      snackbar.success('Sale updated');

      onClose();
    },
    onError: () => {
      snackbar.error('Could not updated sale');
    },
  });

  const initialValues: EditSaleFormType = {
    product: sale.product._id,
    salePrice: sale.salePrice,
    frame: sale.frame ? FrameEnum.frame : FrameEnum.noFrame,
  };

  return (
    <Formik
      onSubmit={(values) => updateMutation.mutateAsync(values)}
      initialValues={initialValues}
      validationSchema={saleValidationSchema}
    >
      {(formik) => {
        const onSave = (userId: string) => {
          const isAdmin = !!(userOptions.find(user => user._id === userId)?.role === UserRoleEnum.admin);
          const update = removeUnchanged(formik.values, initialValues);

          if (!isAdmin && (update.frame || update.salePrice)) {
            return snackbar.error('Only admins can update frame and sale price');
          }

          formik.handleSubmit();
        };

        return (
          <UpdateResourceModal
            open
            onClose={onClose}
            title="Edit sale"
            onSave={onSave}
            loading={formik.isSubmitting}
            requireCredentials
            userCredentialsFormProps={{ firstStepText: 'Who is editing this sale', users: userOptions }}
          >
            <EditSaleForm />
          </UpdateResourceModal>
        );
      }}
    </Formik>
  );
};