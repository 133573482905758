import reduce from 'lodash/reduce';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { DeepPartial } from 'types';

export function removeUnchanged<T extends { [key: string]: any }>(values: T, initialValues: T): DeepPartial<T> {
  return reduce(values, (acc: Partial<T>, value: T[keyof T], key: string) => {
    if (isObject(value) && isObject(initialValues[key]) && !isArray(value)) {
      const cleanedObject = removeUnchanged(value, initialValues[key]);

      if (!isEmpty(cleanedObject)) {
        return { ...acc, [key]: cleanedObject };
      }
    } else if (!isEqual(value, initialValues[key])) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
}