import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { PageContentContainer, PageContentHeader, TableActionsBar } from 'components';
import React, { useMemo, useState } from 'react';
import { OrdersTableTypeEnum, ordersTableTypeEnumHelper } from './helpers';
import { UnfulfilledOrders } from './pages/UnfulfilledOrders.page';
import { AllOrders } from './pages/AllOrders.page';
import { useCurrentUser } from 'contexts';

export const Orders = () => {
  const [ tableType, setTableType ] = useState<OrdersTableTypeEnum>(OrdersTableTypeEnum.not_fulfilled);
  const { isAdmin } = useCurrentUser();

  const table = useMemo(() => {
    switch (tableType) {
      case OrdersTableTypeEnum.not_fulfilled:
        return <UnfulfilledOrders />;
      case OrdersTableTypeEnum.all:
      default:
        return <AllOrders />;
    }
  }, [ tableType ]);

  return (
    <PageContentContainer header={<PageContentHeader  title="Orders" />}>
      <TableActionsBar
        leftActions={(
          <Box width={200}>
            <FormControl fullWidth>
              <InputLabel id="table-view-type">View Type</InputLabel>
              <Select
                label="View Type"
                size="small"
                value={tableType}
                disabled={!isAdmin}
                onChange={(e) => setTableType(e.target.value as OrdersTableTypeEnum)}
                fullWidth
              >
                {ordersTableTypeEnumHelper.enumValues.map(value => <MenuItem key={value} value={value}>{ordersTableTypeEnumHelper.getLabel(value)}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        )}
      />
      {table}
    </PageContentContainer>
  );
};
