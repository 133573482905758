import { Box, Typography, ToggleButton, Card, Stack, Divider } from '@mui/material';
import { DateService } from 'services';
import { EventSalesChartProps } from './types';
import React, { useCallback, useMemo, useState } from 'react';
import { AnalyticsChartTypeEnum, AnalyticsTimelineChart, AnalyticsTimelineChartConfig, AnalyticsTimelineChartTimePeriodEnum, OnAnalyticsTimelineChartTimePeriodChangeArg, useEventContext } from 'components';
import { GetEventSalesAnalyticsDataResponse } from 'api/actions';
import { EventDateTypeEnum } from 'api/resources';

export const EventsSalesDateCompareChart: React.FC<EventSalesChartProps> = ({ data: { events, sales }, isLoading }) => {
  const { event: selectedEvent } = useEventContext();
  const [ selectedEventDatesIds, setSelectedEventDatesIds ] = useState<string[]>([]);

  const getEventDate = (_id: string) => {
    return events.reduce((r, event) => {
      const eventDate = event.dates.find(eventDate => eventDate._id === _id);

      if(eventDate) {
        return eventDate;
      }

      return r;
    }, undefined as GetEventSalesAnalyticsDataResponse['data']['events'][number]['dates'][number] | undefined);
  };

  const handleEventDateClick = (_id: string) => {
    setSelectedEventDatesIds(p => {
      if (p.includes(_id)) {
        return p.filter(id => id !== _id);
      }

      return [ ...p, _id ];
    });
  };

  const config: AnalyticsTimelineChartConfig<GetEventSalesAnalyticsDataResponse['data']['sales'][number]> = {
    baseConfig: {
      data: sales,
      dateRange: { start: DateService.dayjsTz(), end: DateService.dayjsTz() },
      getValue: () => 1,
      getDate: (sale) => DateService.dayjsTz(sale.order.eventDate?.dateAsUtc)
    },
    datasetsConfigs: selectedEventDatesIds.map(eventDateId => {
      const eventDate = getEventDate(eventDateId);

      return {
        filter: sale => !!sale.order.eventDate && sale.order.eventDate._id === eventDateId,
        dateRange: { start: DateService.dayjsTz(eventDate?.dateAsUtc), end: DateService.dayjsTz(eventDate?.dateAsUtc) }
      };
    })
  };

  const dateRange = useMemo(() => ({ start: DateService.dayjs(), end: DateService.dayjs() }), []);
  const onTimePeriodChange = useCallback((timePeriod: AnalyticsTimelineChartTimePeriodEnum, arg: OnAnalyticsTimelineChartTimePeriodChangeArg) => {
    if(timePeriod === AnalyticsTimelineChartTimePeriodEnum.day) {
      arg.setChartType(AnalyticsChartTypeEnum.bar);
    }

    if(timePeriod === AnalyticsTimelineChartTimePeriodEnum.hour) {
      arg.setChartType(AnalyticsChartTypeEnum.line);
    }
  }, []);

  return (
    <Box display="flex" flexDirection={{ xs: 'column-reverse', md: 'column-reverse', lg: 'row' }} gap={0.5}>
      <Card variant="outlined" sx={{ maxWidth: { xs: '100%', md: '100%', lg: 425 }, py: 2 }}>
        <Typography variant="subtitle1" px={1} mb={1}>
          Event Dates
          <Typography component="div" variant="caption" color="text.secondary">
            Select up to 5 event dates for comparison.
          </Typography>
        </Typography>
        <Stack gap={2} py={1} width="100%" justifyContent="flex-start">
          {events.map(event => {
            return (
              <Box key={event._id} px={-2} bgcolor={selectedEvent._id === event._id ? 'primary.background' : 'transparent'}>
                <Divider />
                <Box px={2} py={0.5}>
                  <Typography fontWeight={500}>{event.year}</Typography>
                  <Box display="flex" flexWrap="wrap" my={1}>
                    {event.dates.map((eventDate, index) => {
                      if(eventDate.type !== EventDateTypeEnum.regular) {
                        return null;
                      }

                      const selected = selectedEventDatesIds.includes(eventDate._id);
                      const isNextWeek = !!index && (DateService.dayjsTz(eventDate.dateAsUtc).day() + 6) % 7 < (DateService.dayjsTz(event.dates[index - 1].dateAsUtc).day() + 6) % 7;

                      return (
                        <>
                          {isNextWeek && <Box width="100%" my={1} />}
                          <ToggleButton
                            color="primary"
                            key={eventDate._id}
                            value={eventDate._id}
                            sx={{ width: 130 }}
                            selected={selected}
                            onClick={() => handleEventDateClick(eventDate._id)}
                            disabled={!selected && selectedEventDatesIds.length === 5}
                          >
                            {DateService.dayjsTz(eventDate.dateAsUtc).format('ddd, MMM Do')}
                          </ToggleButton>
                        </>
                      );
                    })}
                  </Box>
                </Box>
                <Divider />
              </Box>
            );
          })}
        </Stack>
      </Card>
      <Box maxHeight={{ xs: 'none', md: 'none', lg: 500 }} flex={1}>
        <AnalyticsTimelineChart
          header="Dates compare"
          dateRange={dateRange}
          subheader=""
          config={config}
          onTimePeriodChange={onTimePeriodChange}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
};