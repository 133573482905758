import { Box, Card, Container, FormHelperText, List, Typography } from '@mui/material';
import { Loading, SaleProductListItem, useAlertSnackbar } from 'components';
import { getFrameEnumFromValue, orderEnumHelpers, saleEnumHelpers } from 'helpers';
import { DiscountEnum, LogSaleStepEnum, listDividersSx, useLogSaleContext } from '../utils';
import { NavigateButtons } from '../components/NavigateButtons.component';
import { currencyFormatter } from 'services';
import { DiscountChip } from '../components/DiscountChip.component';
import { TotalsOverview } from '../components/TotalsOverview.component';
import { Formik } from 'formik';
import { OrderPaymentEnum } from 'api/resources';
import { SchemaOf, boolean, mixed, object, string } from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'queries/query-keys';
import { CreateLiveSaleBaseInput, LoggingPortalResourceTypeEnum, createLiveSaleForEventDate, createLiveSaleForStorefront } from 'api/actions';
import { useLoggingPortalContext } from 'pages/LoggingPortal/LoggingPortal.base';
import { PaymentTypeSelect } from 'components/Order/PaymentTypeSelect.component';

export type PaymentStepProps = {
  userId: string;
};

type PaymentStepForm = {
  payment: OrderPaymentEnum | null;

  customerName: string;
  customerEmail: string;
  sendReceipt: boolean;
};

type PaymentStepFormValidated = {
  payment: OrderPaymentEnum;

  customerName: string;
  customerEmail: string;
  sendReceipt: boolean;
};

const paymentStepFormValidationSchema: SchemaOf<PaymentStepForm> = object().shape({
  payment: mixed<OrderPaymentEnum>().oneOf(orderEnumHelpers.payment.enumValues).required('Please select a payment type'),

  customerName: string().default('').when('customerEmail', {
    is: (val: string) => !!val,
    then: schema => schema.required('Please enter a customer name'),
  }),
  customerEmail: string().email('Please enter a valid email').default('').when('customerName', {
    is: (val: string) => !!val,
    then: schema => schema.required('Please enter a customer email'),
  }),
  sendReceipt: boolean().default(true),
}, [ [ 'customerName', 'customerEmail' ], [ 'customerEmail', 'customerName' ] ]);

const initialValues: PaymentStepForm = {
  payment: null,

  customerName: '',
  customerEmail: '',
  sendReceipt: true,
};

export const PaymentStep: React.FC<PaymentStepProps> = props => {
  const { resourceId, resourceType, ...data } = useLoggingPortalContext();
  const { state, onSetStep, saleProductsTransformed, onClearAndExit } = useLogSaleContext();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const confirmSaleMutation = useMutation({
    mutationFn: async (form: PaymentStepFormValidated) => {
      const otherDiscountsTotal = state.discounts.reduce((acc, discount) => {
        switch (discount) {
          case DiscountEnum.fiveOff:
            return acc - 5;
          default:
            return acc;
        }
      }, 0);

      const discountPer = +((otherDiscountsTotal / saleProductsTransformed.length).toFixed(2));
      let filler = 0;

      if (discountPer * saleProductsTransformed.length !== otherDiscountsTotal) {
        filler = +((otherDiscountsTotal - (discountPer * saleProductsTransformed.length)).toFixed(2));
      }

      const sales = saleProductsTransformed.map(({ product, price, totalDiscount, frame, variantId, shipping }, idx, { length }): CreateLiveSaleBaseInput['sales'][number] => {
        let salePrice = price + totalDiscount + discountPer;

        if (filler && idx === length - 1) {
          salePrice = +((salePrice + filler).toFixed(2));
        }

        return {
          product: product._id,
          salePrice,
          frame,
          variantId,
          shipping,
        };
      });

      const customer = state.shipmentInfo.email ? {
        firstName: state.shipmentInfo.firstName,
        lastName: state.shipmentInfo.lastName,
        email: state.shipmentInfo.email,
        place: state.shipmentInfo.place,
        phone: state.shipmentInfo.phone,
      } : undefined;

      if ('eventDate' in data) {
        return await createLiveSaleForEventDate({
          eventDate: data.eventDate._id,
          sales,
          customer,
          payment: form.payment,
          user: props.userId,
        });
      }

      return await createLiveSaleForStorefront({
        storefront: data.storefront._id,
        sales,
        customer,
        payment: form.payment,
        user: props.userId,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        resourceType ===  LoggingPortalResourceTypeEnum.eventDate
          ? QUERY_KEY.LOGGING_PORTAL_DATA_FOR_EVENT_DATE(resourceId)
          : QUERY_KEY.LOGGING_PORTAL_DATA_FOR_STOREFRONT(resourceId)
      );
      snackbar.success('Sale logged');
      onClearAndExit();
    },
    onError: () => {
      snackbar.error('Unable to log sale. Please try again or contact management.');
    }
  });

  const getPaymentTypeSelectDisabled = (paymentType: OrderPaymentEnum) => {
    if (paymentType === OrderPaymentEnum.cash) {
      if (saleProductsTransformed.some(saleProduct => !saleProduct.frame)) {
        return 'Only available for framed products';
      }
    }

    if (paymentType === OrderPaymentEnum.split) {
      return 'Split payments will be available soon';
    }

    return false;
  };

  return (
    <Formik
      onSubmit={(values: PaymentStepFormValidated) => confirmSaleMutation.mutateAsync(values)}
      initialValues={initialValues}
      validationSchema={paymentStepFormValidationSchema}
    >
      {formik => {
        const paymentError = formik.touched.payment && formik.errors.payment;

        if (formik.isSubmitting) {
          return <Loading />;
        }

        return (
          <Container sx={{ flex: 1, pt: { xs: 3, lg: 4 }, pb: 10 }} maxWidth="md">
            <NavigateButtons
              onNext={() => formik.handleSubmit()}
              onBack={() => onSetStep(LogSaleStepEnum.discount)}
            />
            <Box display="flex" gap={2} mx={{ xs: -2, sm: 0 }} flexDirection={{ xs: 'column', sm: 'row' }} flexWrap="wrap">

              <Box flex={1} display="flex" flexDirection="column" gap={2} minWidth={{ xs: 'unset', sm: '400px' }}>
                <Box display="flex" gap={1}>
                  <Card variant="outlined" sx={{ flex: 1 }}>
                    <List disablePadding sx={listDividersSx()}>
                      {saleProductsTransformed.map(({ product, id, frame, total, price, imageSrc, variantId }) => {
                        const frameLabel = saleEnumHelpers.frame.getLabel(getFrameEnumFromValue(frame));

                        return (
                          <SaleProductListItem
                            key={id}
                            name={product.name}
                            isProductImagesDisplay={!variantId}
                            imageUrl={imageSrc}
                            secondary={frameLabel}
                            actions={(
                              <Box display="flex" alignItems="center" gap={1}>
                                {price !== total && (
                                  <Typography sx={{ textDecoration: 'line-through', textDecorationColor: theme => theme.palette.error.light }}>
                                    {currencyFormatter.format(price)}
                                  </Typography>
                                )}
                                <Typography fontWeight={500}>
                                  {currencyFormatter.format(total)}
                                </Typography>
                              </Box>
                            )}
                          />
                        );
                      })}
                    </List>
                  </Card>
                  <Card variant="outlined">
                    <Box p={2} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={state.discounts.length ? 2 : 0}>
                      <Box flex={1} display="flex" flexDirection="column" gap={1}>
                        {state.discounts.map((discount) => <DiscountChip key={discount} discount={discount} />)}
                      </Box>
                      <TotalsOverview />
                    </Box>
                  </Card>
                </Box>

                <PaymentTypeSelect
                  selected={formik.values.payment}
                  onSelect={(paymentType) => formik.setFieldValue('payment', paymentType)}
                  getDisabled={getPaymentTypeSelectDisabled}
                />

                {Boolean(paymentError) && (
                  <FormHelperText error sx={{ textAlign: 'center', fontSize: theme => theme.typography.body2.fontSize }}>
                    {paymentError}
                  </FormHelperText>
                )}
              </Box>
            </Box>

          </Container>
        );
      }}
    </Formik>
  );
};