import { CreditCardOutlined, LocalAtmOutlined } from '@mui/icons-material';
import { Box, Card, Divider, Theme, Tooltip, Typography } from '@mui/material';
import { OrderPaymentEnum } from 'api/resources';
import { orderEnumHelpers } from 'helpers';
import { useCallback } from 'react';

export type PaymentTypeSelectProps = {
  selected: OrderPaymentEnum | null;
  onSelect: (payment: OrderPaymentEnum) => void;
  getDisabled: (value: OrderPaymentEnum) => string | boolean;
  fontSize?: number;
};

export const PaymentTypeSelect: React.FC<PaymentTypeSelectProps> = ({ selected, onSelect, getDisabled, fontSize = 100 }) => {
  const getPaymentTypeIcon = useCallback((paymentType: OrderPaymentEnum ) => {
    switch (paymentType) {
      case OrderPaymentEnum.card:
        return <CreditCardOutlined sx={{ fontSize }} />;
      case OrderPaymentEnum.cash:
        return <LocalAtmOutlined sx={{ fontSize }} />;
      case OrderPaymentEnum.split:
        return (
          <>
            <LocalAtmOutlined sx={{ fontSize, mr: -fontSize / 35, clipPath: 'inset(0 33% 0 0)' }}/>
            <Divider orientation="vertical" flexItem />
            <CreditCardOutlined sx={{ fontSize, ml: -fontSize / 35, clipPath: 'inset(0 0 0 33%)' }}/>
          </>
        );
      default:
        return null;
    }
  }, [ fontSize ]);

  return (
    <Box display="flex" gap={1}>
      {[ OrderPaymentEnum.cash, OrderPaymentEnum.card, OrderPaymentEnum.split ].map(paymentType => {
        const isSelected = selected === paymentType;
        const disabled = getDisabled(paymentType);
        const disabledReason = typeof disabled === 'string' ? disabled : undefined;

        const color: (theme: Theme) => string | undefined = (theme) => !!disabled ? theme.palette.grey[400] : undefined;

        return (
          <Tooltip title={fontSize < 100 ? disabledReason : undefined} key={paymentType}>
            <Card
              variant="outlined"
              sx={{
                position: 'relative',
                flex: 1,
                px: fontSize / 20,
                py: fontSize / 20,
                cursor: !!disabled ? undefined : 'pointer',
                border: theme => isSelected ? `1px solid ${theme.palette.primary.main}` : undefined,
                background: theme => isSelected ? theme.palette.primary.background : 'transparent',
                color: isSelected ? theme => theme.palette.primary.dark : color,
                userSelect: 'none',
              }}
              onClick={() => !disabled && onSelect(paymentType)}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {getPaymentTypeIcon(paymentType)}
              </Box>
              <Typography width="100%" textAlign="center" fontSize={Math.max(10, fontSize - 86)}>{orderEnumHelpers.payment.getLabel(paymentType)}</Typography>
              {!!disabled && fontSize >= 100 && (
                <Typography
                  variant="caption"
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    textAlign: 'center',
                    pb: 1,
                  }}
                >
                  {disabledReason}
                </Typography>
              )}
            </Card>
          </Tooltip>
        );
      })}
    </Box>
  );
};