import { Box, ClickAwayListener, CssBaseline, Toolbar } from '@mui/material';
import { MainLayoutConfType } from 'types';
import { useMainLayoutContext } from 'contexts';
import { useLayoutConf as defaultLayoutConf } from 'hooks';
import { Sidebar, sideBarDrawerWidth } from './Sidebar.component';
import { Appbar } from './Appbar.component';
import { ScrollableView, useScrollableView } from './StickyStack';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type MainLayoutProps = {
  children: React.ReactNode;
  useLayoutConf?: () => MainLayoutConfType;
};

export const MainLayout: React.FC<MainLayoutProps> = ({ useLayoutConf = defaultLayoutConf, children }) => {
  const { isDrawerOpen, onToggleDrawer } = useMainLayoutContext();

  return (
    <Box display="flex" height="100%" bgcolor={theme => theme.palette.background.default}>
      <CssBaseline />
      <Appbar open={isDrawerOpen} onToggleDrawer={onToggleDrawer} />
      <ClickAwayListener onClickAway={() => isDrawerOpen && onToggleDrawer()}>
        <div>
          <Sidebar useLayoutConf={useLayoutConf} />
        </div>
      </ClickAwayListener>
      <Box
        component="main"
        sx={(theme) => {
          return {
            flexGrow: 1,
            minHeight: '100vh',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: 0,
            [theme.breakpoints.up('sm')]: {
              marginLeft: `${sideBarDrawerWidth}px`,
            },
            ...(!isDrawerOpen && {
              [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(5),
              },
            }),
          };
        }}
      >
        <Toolbar />
        <ScrollableView>
          <MainLayoutChildren>
            {children}
          </MainLayoutChildren>
        </ScrollableView>
      </Box>
    </Box>
  );
};

const MainLayoutChildren = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  const { scrollTo } = useScrollableView();

  useEffect(() => {
    scrollTo({ top: 0 });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pathname ]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};