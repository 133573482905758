import { authAxiosRequest } from 'api/axios';

export type GetShopifyProductResponse = {
  data: {
    title: string;
    handle: string;
    status: string;
    tags: string;
    storeUrl: string;
  };
};

export const getShopifyProduct = (shopifyProductId: number) => {
  return authAxiosRequest<GetShopifyProductResponse>(`/api/shopify/products/${shopifyProductId}`);
};

export type GetShopifyProductsResopnse = {
  data: {
    id: number;
    variants: {
      id: number;
      title: string;
      imageSrc: string;
    }[];
  }[];
};

export const getShopifyProducts = (shopifyProductIds: number[]) => {
  return authAxiosRequest<GetShopifyProductsResopnse>('/api/shopify/products', { data: { shopifyProductIds }, method: 'POST' });
};