import { BaseTextFieldProps, InputBaseProps } from '@mui/material';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { BaseTextField } from '../BaseTextField';

export type TextInputRawProps = InputBaseProps & {
  name: string;
  label?: string;
  helperText?: string;
  trim?: boolean;
  value: string;
  setValue: (value: string) => void;
  InputLabelProps?: BaseTextFieldProps['InputLabelProps'];
};

export const TextInputRaw: React.VFC<TextInputRawProps> = ({
  error,
  helperText,
  sx,
  trim,
  value,
  setValue,
  onChange,
  onBlur,
  label,
  InputLabelProps,
  ...props
}) => {
  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (trim) setValue(value.trim());
      onBlur?.(e);
    },
    [ onBlur, value, trim, setValue ],
  );

  return (
    <BaseTextField
      value={value}
      onChange={onChange}
      label={label}
      error={error}
      helperText={helperText}
      sx={sx}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      InputLabelProps={InputLabelProps}
      InputProps={{
        ...props,
        onBlur: handleBlur,
        // prevent unexpected behavior when the user scrolls the input
        onWheel: (e: React.WheelEvent<HTMLDivElement>) => {
          const inputElement = e.currentTarget.querySelector('input');

          if (inputElement) {
            inputElement.blur();
          }
        },
      }}
    />
  );
};

export type TextInputProps = Omit<TextInputRawProps, 'value' | 'setValue'>;

export const TextInput: React.FC<TextInputProps> = ({
  helperText: propsHelperText,
  ...props
}) => {
  const [ field, meta, helpers ] = useField(props.name);
  const hasError = props.error || Boolean(meta.error && meta.touched);
  const helperText = (meta.touched && meta.error) || propsHelperText;

  return (
    <TextInputRaw
      {...props}
      {...field}
      setValue={helpers.setValue}
      error={hasError}
      helperText={helperText}
      rows={props.rows}
    />
  );
};
