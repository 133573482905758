import React, { useMemo, useRef, useState } from 'react';
import { DialogTitle, DialogContent, DialogContentText, Button } from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { Modal } from '../Modal.component';
import { ModalActions } from '../ModalActions.component';
import { useMutation } from '@tanstack/react-query';
import {
  UserCredentialsForm,
  UserCredentialsFormProps,
  validateUserCredentialsSchema,
} from 'components/UserCredentialsModal';
import { useAlertSnackbar } from 'components/AlertSnackbar';
import { useCurrentUser } from 'contexts';
import { validateCredentials } from 'api/actions';
import { ResourceForm } from 'types';

type ConfirmActionModalPropsBase = {
  open: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  title?: string;
  text?: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
};

type ConfirmActionModalPropsWithCredentials = ConfirmActionModalPropsBase & {
  requireCredentials: true;
  userCredentialsFormProps?: UserCredentialsFormProps;
};

type ConfirmActionModalPropsWithoutCredentials = ConfirmActionModalPropsBase & {
  requireCredentials?: false;
  userCredentialsFormProps?: never;
};

export type ConfirmActionModalProps = ConfirmActionModalPropsWithCredentials | ConfirmActionModalPropsWithoutCredentials;

export const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  open,
  title = 'Are you sure?',
  text = 'Please, confirm your action',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  requireCredentials = false,
  userCredentialsFormProps,
  onConfirm,
  onClose,
  loading,
}) => {
  const [ credentialsStep, setCredentialsStep ] = useState(false);
  const userCredentialsFormikRef = useRef<FormikProps<ResourceForm['validateCredentials']>>(null);
  const { _id: meId } = useCurrentUser();
  const snackbar = useAlertSnackbar();

  const validateCredentialsMutation = useMutation({
    mutationFn: (form: ResourceForm['validateCredentials']) => {
      if (form.credentialType === 'password') {
        return validateCredentials({ userId: form.userId, password: form.password });
      } else {
        return validateCredentials({ userId: form.userId, pin: form.pin });
      }
    },
    onSuccess: async () => {
      await onConfirm();
    },
    onError: () => {
      snackbar.error('Invalid Credentials. Please, try again.');
    },
  });

  const handleClose = () => {
    if (loading || validateCredentialsMutation.isLoading) return;
    if (credentialsStep) {
      setCredentialsStep(false);
    } else {
      onClose();
    }
  };

  const confirmButtonClickHandler = async () => {
    if (credentialsStep) {
      if (!userCredentialsFormikRef.current) {
        return;
      }
      const res = await userCredentialsFormikRef.current.validateForm();

      if (Object.keys(res).length === 0) {
        validateCredentialsMutation.mutate(userCredentialsFormikRef.current.values);
      }
    } else {
      if (requireCredentials) {
        setCredentialsStep(true);
      } else {
        onConfirm();
      }
    }
  };

  const cancelButtonClickHandler = () => {
    if (loading || validateCredentialsMutation.isLoading) return;
    if (credentialsStep) {
      setCredentialsStep(false);
    } else {
      onClose();
    }
  };

  const cancelButtonTextFinal = requireCredentials && credentialsStep ? 'Back' : cancelButtonText;

  const confirmButtonTextFinal = useMemo(() => {
    if (credentialsStep) {
      return validateCredentialsMutation.isLoading ? 'Verifying...' : 'Verify';
    }
    return loading ? 'Loading...' : confirmButtonText;
  }, [ credentialsStep, loading, confirmButtonText, validateCredentialsMutation.isLoading ]);

  const isConfirmButtonDisabled = loading || validateCredentialsMutation.isLoading;

  const content = useMemo(() => {
    const initialUserCredentialsState: ResourceForm['validateCredentials'] = {
      userId: meId,
      password: '',
      pin: '',
      credentialType: 'pin',
    };

    if (credentialsStep) {
      return (
        <DialogContent>
          <Formik
            innerRef={userCredentialsFormikRef}
            initialValues={initialUserCredentialsState}
            onSubmit={(value) => validateCredentialsMutation.mutateAsync(value)}
            validationSchema={validateUserCredentialsSchema}
          >
            <UserCredentialsForm {...userCredentialsFormProps} />
          </Formik>
        </DialogContent>
      );
    }

    return (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
    );
  }, [ credentialsStep, meId, text, userCredentialsFormProps, validateCredentialsMutation ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      topLeftIcon="close"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      autoHeight
    >
      {title && (
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      )}
      {content}
      <ModalActions>
        <Button onClick={cancelButtonClickHandler} color="inherit" disabled={isConfirmButtonDisabled}>
          {cancelButtonTextFinal}
        </Button>
        <Button
          onClick={confirmButtonClickHandler}
          variant="contained"
          disabled={isConfirmButtonDisabled}
        >
          {confirmButtonTextFinal}
        </Button>
      </ModalActions>
    </Modal>
  );
};