import { ApiResource, PickFromResource } from './resources.types';
import { User } from './user';

export type Event = ApiResource<{
  iconUrl: string | undefined;
  createdBy?: string;
  year: number;
  eventCollection: string;
  attention: boolean;
  timezone: string;

  name: string;
  eventOrganizer?: string;
  attendance?: number;
  vendors?: number;
  indoorOutdoor?: EventIndoorOurdoorEnum;
  rainOrShine?: boolean;

  eventManager?: string;
  team?: string;
  teamManager?: string;
  vehicle?: string;
  spaceSize: string;
  spaceNumber?: string;
  boothRequirements?: EventBoothRequirements;
  notes?: string;
  electricityWanted?: boolean;
  electricGeneratorAllowed?: boolean;
  electricityAvailable?: boolean;
  electricityFee?: boolean;
  electricityChoice?: EventElectricityChoiceEnum;
  onWebsite?: boolean;

  toDos?: EventToDo[];

  participationStatus?: EventParticipationStatusEnum;
  applicationOpenDate?: string;
  applicationDeadlineDate?: string;
  applicationIsOpen?: boolean;
  applicationOpenUntilFull?: boolean;
  applicationStatus?: EventApplicationStatusEnum;
  applicationPlatform?: EventApplicationPlatformEnum;
  acceptanceStatus?: EventAcceptanceStatusEnum;
  applicationDeniedReason: string;
  communicationLog?: EventCommunicationLog[];
  futureInterestStatus?: EventFutureInterestStatusEnum;

  juryFee?: boolean;
  juryFeeAmount?: number;
  juryFeePaid?: boolean;
  juryFeePaymentType?: EventPaymentTypeEnum;

  boothFee?: number;
  electricityFeeAmount?: number;
  applicationPayments?: EventPayment[];
  defaultPaymentType?: EventPaymentTypeEnum;
  paymentNote?: string;

  place: string;
  lodging?: EventLodging;
  insurance?: EventInsurance;
  externalLinks?: EventExternalLink[];
  files?: EventFile[];
}>;

// Indoor / Outdoor

export const enum EventIndoorOurdoorEnum {
  indoor = 'indoor',
  outdoor = 'outdoor',
}

// Assignments

export const enum EventElectricityChoiceEnum {
  offered = 'offered',
  generator = 'generator',
}

// Booth requirements

export type EventBoothRequirements = {
  hasRequirements: boolean;
  areTentsAllowed: boolean;

  isWhiteTopRequired: boolean;
  isFlameRetardantRequired: boolean;

  maximumHeight: number;
  isTrussAllowed: boolean;

  notes: string;
};

// Application

export const enum EventApplicationStatusEnum {
  needApply = 'needApply',
  inProccess = 'inProccess',
  applied = 'applied',
  research = 'research'
}

export const enum EventAcceptanceStatusEnum {
  accepted = 'accepted',
  denied = 'denied',
  followUp = 'followUp',
  waitListPending = 'waitListPending',
  waitListDenied = 'waitListDenied',
}

export const enum EventParticipationStatusEnum {
  interested = 'interested',
  participating = 'participating',
  canceled = 'canceled',
  notInterested ='notInterested',
  unable = 'unable',
}

export const enum EventApplicationPlatformEnum {
  mail = 'mail',
  email = 'email',
  hostsWebsite = 'hostsWebsite',
  zapplication = 'zapplication',
  entryThingy = 'entryThingy',
  submittable = 'submittable',
  eventeny = 'eventeny',
}

export const enum EventFutureInterestStatusEnum {
  yes = 'yes',
  yesAskNextYear = 'yesAskNextYear',
  no = 'no',
}

// Paymnets

export type EventPayment = ApiResource<{
  receiptNumber?: string;
  amount: number;
  type: EventPaymentTypeEnum;
  paymentDue?: string;
}>;

export const enum EventPaymentTypeEnum {
  credit = 'credit',
  check = 'check',
  creditForm = 'creditForm',
  wireTransfer = 'wireTransfer',
}

// Event Lodging

export type EventLodging = {
  isRequired?: boolean | null;
  places: {
    _id: string;
    place: string;
    checkIn: string;
    checkOut: string;
    bookingStatus?: EventLodgingBookingStatusEnum;
    cost?: number;
    finalCost?: number;
    website?: string;
    phone?: string;
    confirmationNumber?: string;
    needToSendCreditAuthorization?: boolean;
    creditAuthorizationSent?: boolean;
    notes?: string;
  }[];
  notes?: string;
};

export const enum EventLodgingBookingStatusEnum {
  toBook = 'toBook',
  inProgress = 'inProgress',
  booked = 'booked',
}

// Event Insurance

export type EventInsurance = {
  isRequired?: boolean | null;
  status?: EventInsuranceStatusEnum;
  shared?: EventInsuranceSharedEnum;
};

export const enum EventInsuranceStatusEnum {
  obtained = 'obtained',
}

export const enum EventInsuranceSharedEnum {
  shared = 'shared',
}

// Event Communication Log

export type EventCommunicationLog = ApiResource<{
  date: string;
  type: EventCommunicationLogTypeEnum;
  user?: PickFromResource<User, 'name'>;
  note?: string;
}>;

export const enum EventCommunicationLogTypeEnum {
  call = 'call',
  voicemail = 'voicemail',
  email = 'email',
  other = 'other',
}

// Event File

export type EventFile = {
  _id: string;
  name: string;
  url: string;
  type: EventFileTypeEnum;
};

export const enum EventFileTypeEnum {
  application = 'application',
  lodging = 'lodging',
  insurance = 'insurance',
  other = 'other',
}

// Event External Link

export type EventExternalLink = {
  _id: string;
  title: string;
  url: string;
};

// Event To Do

export type EventToDo = {
  _id: string;
  title: string;
  completed: boolean;
  description?: string;
  dateAsUtc?: string;
  assignees: string[];
};

// Queris

export const enum EventForResourceTypeEnum {
  current = 'current',
  upcoming = 'upcoming',
}