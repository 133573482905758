import { Box } from '@mui/material';
import { TextInput, MarkdownInput } from 'components/Inputs';
import { RadioGroupYesNoBoolean } from 'components/RadioGroup';
import { useFormikContext } from 'formik';
import React from 'react';
import { formSx } from 'styles';
import { EventPageSectionRowId, EventSectionRowForm } from '../types';
import { YesNoEnum } from 'types';

export const EventBoothRequirementsForm = () => {
  const { values } = useFormikContext<EventSectionRowForm[EventPageSectionRowId.BOOTH_REQUIREMENTS]>();

  return (
    <Box sx={formSx.formGroup}>
      <RadioGroupYesNoBoolean
        name="hasRequirements"
        label="Are there booth requirements?"
        includeUnknown
        row
      />
      {values.hasRequirements === YesNoEnum.yes && (
        <>
          <RadioGroupYesNoBoolean
            name="areTentsAllowed"
            label="Are tents allowed?"
            includeUnknown
            row
          />
          {values.areTentsAllowed === YesNoEnum.yes && (
            <>
              <RadioGroupYesNoBoolean
                name="isWhiteTopRequired"
                label="Must be white top?"
                includeUnknown
                row
              />
              <RadioGroupYesNoBoolean
                name="isFlameRetardantRequired"
                label="Must be Rated Flame Retartant?"
                includeUnknown
                row
              />
            </>
          )}
          {values.areTentsAllowed === YesNoEnum.no && (
            <>
              <TextInput
                trim
                fullWidth
                id="maximumHeight"
                name="maximumHeight"
                label="Maximum Height"
              />
              <RadioGroupYesNoBoolean
                name="isTrussAllowed"
                label="Is truss allowed?"
                includeUnknown
                row
              />
            </>
          )}
        </>
      )}
      <MarkdownInput name="notes" />
    </Box>
  );
};
