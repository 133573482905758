import { ApiResource } from './resources.types';

export type Order = ApiResource<{
  name: string;
  user?: string;
  storefront?: string;
  eventDate?: string;
  event?: string;
  payment: OrderPaymentEnum;
  type: OrderTypeEnum;
  team: string;
  customer?: string;
  shopifyOrderId?: string;
}>;

export const enum OrderFulFillmentStatusEnum {
  UNFULFILLED = 'unfulfilled',
  PARTIALLY_FULFILLED = 'partially_fulfilled',
  FULFILLED = 'fulfilled',
}

export const enum OrderSaleFulfillmentStatusEnum {
  UNFULFILLED = 'unfulfilled',
  FULFILLED = 'fulfilled',
}

export const enum OrderSaleFulfillmentTypeEnum {
  SHIPMENT = 'shipment',
  IN_PERSON = 'in_person',
}

export const enum OrderTypeEnum {
  manual = 'manual',
  live = 'live',
  online = 'online',
}

export const enum OrderPaymentEnum {
  cash = 'cash',
  card = 'card',
  split = 'split',
  online = 'online',
  venmo = 'venmo',
  other = 'other',
}