import { updateEvent, updateEventLodgingPlace, createEventLodgingPlace, deleteEventLodgingPlace } from 'api/actions';
import { RadioGroupYesNoBoolean } from 'components/RadioGroup';
import { SectionCard } from 'components/SectionCard';
import { processFormValueUpdate, removeUnchanged } from 'helpers';
import { yesNoUnknownSchema, UnknownEnum } from 'types';
import { object, string } from 'yup';
import { EventSectionCardRowEditable, EventSectionCardRowEditableList, EventLodgingPlaceCard } from '../components';
import { EventLodgingPlaceForm, eventLodgingPlaceValidationSchema } from '../forms';
import { EventPageSection, EventPageSectionRowId } from '../types';
import { useEventContext } from '../event.context';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useMemo, useState } from 'react';
import { DateService } from 'services';
import { MarkdownInput } from 'components/Inputs';
import { CardContentMarkdown } from 'components/CardContent';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';

type ShowLodgingOption = 'all' | 'current';

export const LodgingSection = () => {
  const { event } = useEventContext();
  const currentLodging = event.lodging?.places.find(p => {
    return DateService.dayjs().isAfter(p.checkIn) && DateService.dayjs().isBefore(p.checkOut);
  });
  const [ showLodgingOption, setShowLodgingOption ] = useState<ShowLodgingOption>(currentLodging ? 'current' : 'all');

  const listItems = useMemo(() => {
    if (showLodgingOption === 'current' && !!currentLodging) {
      return [ currentLodging ];
    }

    return event.lodging?.places ?? [];
  }, [ currentLodging, event.lodging?.places, showLodgingOption ]);

  return (
    <SectionCard title="Lodging" id={EventPageSection.LODGING}>
      <EventSectionCardRowEditable
        title="Lodging Required"
        rowId={EventPageSectionRowId.LODGING_REQUIRED}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { lodging: { isRequired: processFormValueUpdate.yesNoUnknown(values.isRequired) } }),
          initialValues: { isRequired: yesNoEnumHelpers.yesNo.getEnumValue(event.lodging?.isRequired) },
          validationSchema: object({ isRequired: yesNoUnknownSchema })
        }}
        form={(
          <RadioGroupYesNoBoolean
            label="Lodging Required *"
            name="isRequired"
            row
            includeUnknown
          />
        )}
      >
        {typeof event.lodging?.isRequired === 'boolean' ? `${!event.lodging?.isRequired ? 'Not' : ''} Required` : null}
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditableList
        title="Lodging places"
        listItems={listItems}
        rowId={EventPageSectionRowId.LODGING_PLACE}
        renderItem={(lodgingPlace) => <EventLodgingPlaceCard lodgingPlace={lodgingPlace} />}
        form={<EventLodgingPlaceForm />}
        createButtonLabel="Place"
        orderBy={{
          getField: (listItem) => listItem.checkIn,
          direction: 'desc',
        }}
        getFormikProps={(listItem) => {
          return {
            initialValues: {
              placeId: listItem.place.googlePlaceId,
              placeAddress: listItem.place.address ,
              placeName: listItem.place.name ?? '',
              cost: listItem.cost ?? 0,
              finalCost: listItem.finalCost ?? 0,
              bookingStatus: listItem.bookingStatus ?? UnknownEnum.unknown,
              checkIn: listItem.checkIn,
              checkOut: listItem.checkOut,
              website: listItem.website ?? '',
              phone: listItem.phone ?? '',
              confirmationNumber: listItem.confirmationNumber ?? '',
              needToSendCreditAuthorization: yesNoEnumHelpers.yesNo.getEnumValue(listItem.needToSendCreditAuthorization),
              creditAuthorizationSent: yesNoEnumHelpers.yesNo.getEnumValue(listItem.creditAuthorizationSent),
              notes: event.lodging?.notes ?? '',
            },
            onSubmit: (values, { initialValues }) => {
              const updates = removeUnchanged(values, initialValues);

              return updateEventLodgingPlace(event._id, listItem._id, {
                cost: processFormValueUpdate.number(updates.cost),
                finalCost: processFormValueUpdate.number(updates.finalCost),
                placeName: updates.placeName,
                placeId: updates.placeId,
                checkIn: updates.checkIn || undefined,
                checkOut: updates.checkOut || undefined,
                bookingStatus: processFormValueUpdate.enumWithUnknown(updates.bookingStatus),
                website: processFormValueUpdate.string(updates.website),
                phone: processFormValueUpdate.string(updates.phone),
                confirmationNumber: processFormValueUpdate.string(updates.confirmationNumber),
                notes: processFormValueUpdate.string(updates.notes),
                needToSendCreditAuthorization: processFormValueUpdate.yesNoUnknown(updates.needToSendCreditAuthorization),
                creditAuthorizationSent: processFormValueUpdate.yesNoUnknown(updates.creditAuthorizationSent),
              });
            },
            validationSchema: eventLodgingPlaceValidationSchema,
          };
        }}
        createFormikProps={{
          initialValues: {
            placeId: '',
            placeAddress: '',
            placeName: '',
            cost: 0,
            finalCost: 0,
            bookingStatus: UnknownEnum.unknown,
            checkIn: null,
            checkOut: null,
            website: '',
            phone: '',
            confirmationNumber: '',
            needToSendCreditAuthorization: UnknownEnum.unknown,
            creditAuthorizationSent: UnknownEnum.unknown,
            notes: '',
          },
          onSubmit: (values) => {
            return createEventLodgingPlace(event._id, {
              cost: processFormValueUpdate.number(values.cost),
              finalCost: processFormValueUpdate.number(values.finalCost),
              placeName: values.placeName,
              placeId: values.placeId,
              bookingStatus: processFormValueUpdate.enumWithUnknown(values.bookingStatus),
              checkIn: values.checkIn ?? '',
              checkOut: values.checkOut ?? '',
              website: processFormValueUpdate.string(values.website),
              phone: processFormValueUpdate.string(values.phone),
              confirmationNumber: processFormValueUpdate.string(values.confirmationNumber),
              notes: processFormValueUpdate.string(values.notes),
              needToSendCreditAuthorization: processFormValueUpdate.yesNoUnknown(values.needToSendCreditAuthorization),
              creditAuthorizationSent: processFormValueUpdate.yesNoUnknown(values.creditAuthorizationSent),
            });
          },
          validationSchema: eventLodgingPlaceValidationSchema,
        }}
        deleteMutationFn={(log) => deleteEventLodgingPlace(event._id, log._id)}
        actions={(
          <ToggleButtonGroup
            value={showLodgingOption}
            onChange={(_e, value) => {
              setShowLodgingOption(value);
            }}
            size="small"
            color="primary"
            exclusive
          >
            <ToggleButton value="all">All</ToggleButton>
            <ToggleButton value="current" disabled={!currentLodging}>Current</ToggleButton>
          </ToggleButtonGroup>
        )}
      />
      <EventSectionCardRowEditable
        title="Notes"
        rowId={EventPageSectionRowId.LODGING_NOTES}
        formikProps={{
          initialValues: { notes: event.lodging?.notes ?? '' },
          onSubmit: values => updateEvent(event._id, { lodging: { notes: processFormValueUpdate.string(values.notes) } }),
          validationSchema: object({ notes: string().default('') })
        }}
        form={<MarkdownInput name="notes" />}
        disableTypography
        formFullWidth
      >
        <CardContentMarkdown markdown={event.lodging?.notes} />
      </EventSectionCardRowEditable>
    </SectionCard>
  );
};
