import React, { UIEvent, useEffect, useMemo, useState } from 'react';
import { StickyStackProvider } from '../StickyStack';
import { Box, BoxProps } from '@mui/material';

export type ScrollableViewContextValue = {
  scrollTo: (scrollToOptions: ScrollToOptions) => void;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
};

export const ScrollableViewContext = React.createContext<ScrollableViewContextValue | null>(null);

const fallbackScrollableView: ScrollableViewContextValue = {
  scrollTo: (opts?: ScrollToOptions) => window.scrollTo(opts),
};

export const useScrollableView = () => {
  const context = React.useContext(ScrollableViewContext);

  return context ?? fallbackScrollableView;
};

export type ScrollableViewProps = {
  enableScrollShadow?: boolean;
  orientation?: 'horizontal' | 'vertical';
} & BoxProps;

export const ScrollableView: React.FC<ScrollableViewProps> = ({
  children,
  enableScrollShadow,
  orientation = 'vertical',
  ...props
}) => {
  const [ scrollState, setScrollState ] = useState({
    scrollPos: 0,
    scrollSize: 0,
    clientSize: 0,
  });
  const scrollContainer = React.useRef<HTMLDivElement>(null);

  const value = useMemo(() => ({
    scrollTo: (scrollToOptions: ScrollToOptions) => {
      scrollContainer.current?.scrollTo(scrollToOptions);
    },
    scrollContainerRef: scrollContainer,
  }), []);

  useEffect(() => {
    if (enableScrollShadow) {
      setScrollState({
        scrollPos: orientation === 'horizontal'
          ? scrollContainer.current?.scrollLeft ?? 0
          : scrollContainer.current?.scrollTop ?? 0,
        scrollSize: orientation === 'horizontal'
          ? scrollContainer.current?.scrollWidth ?? 0
          : scrollContainer.current?.scrollHeight ?? 0,
        clientSize: orientation === 'horizontal'
          ? scrollContainer.current?.clientWidth ?? 0
          : scrollContainer.current?.clientHeight ?? 0,
      });
    }
  }, [ children, enableScrollShadow, orientation ]);

  const boxShadow = useMemo(() => {
    const { clientSize, scrollSize, scrollPos } = scrollState;

    let boxShadow = 'none';

    if (!enableScrollShadow || scrollSize <= clientSize) {
      return boxShadow;
    }

    const scrollAmount = Math.floor(scrollSize - scrollPos);

    const isEnd = clientSize === scrollAmount;
    const isStart = scrollPos === 0;
    const isBetween = !isEnd && !isStart;

    const startShadow = orientation === 'horizontal'
      ? 'inset 8px 0 5px -5px rgba(0, 0, 0, 0.3)'
      : 'inset 0 8px 5px -5px rgba(0, 0, 0, 0.3)';
    const endShadow = orientation === 'horizontal'
      ? 'inset -8px 0 5px -5px rgba(0, 0, 0, 0.3)'
      : 'inset 0 -8px 5px -5px rgba(0, 0, 0, 0.3)';

    if (isStart) {
      boxShadow = endShadow;
    } else if (isBetween) {
      boxShadow = `${startShadow}, ${endShadow}`;
    } else if (isEnd) {
      boxShadow = startShadow;
    }

    return boxShadow;
  }, [ enableScrollShadow, scrollState, orientation ]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (enableScrollShadow) {
      setScrollState({
        scrollPos: orientation === 'horizontal'
          ? e.currentTarget.scrollLeft
          : e.currentTarget.scrollTop,
        scrollSize: orientation === 'horizontal'
          ? e.currentTarget.scrollWidth
          : e.currentTarget.scrollHeight,
        clientSize: orientation === 'horizontal'
          ? e.currentTarget.clientWidth
          : e.currentTarget.clientHeight,
      });
    }
  };

  return (
    <ScrollableViewContext.Provider value={value}>
      <StickyStackProvider>
        <Box {...props} flex={1} position="relative">
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            width="100%"
            height="100%"
            boxShadow={boxShadow}
            zIndex={1}
            sx={{ pointerEvents: 'none' }}
          />
          <Box
            component="div"
            ref={scrollContainer}
            sx={{
              display: 'flex',
              flexDirection: orientation === 'horizontal' ? 'row' : 'column',
              position: 'absolute',
              width: '100%',
              height: '100%',
              overflow: orientation === 'horizontal' ? 'auto hidden' : 'hidden auto',
            }}
            onScroll={handleScroll}
          >
            {children}
          </Box>
        </Box>
      </StickyStackProvider>
    </ScrollableViewContext.Provider>
  );
};