import React from 'react';
import { DateService } from 'services';

export type ActiveForCellProps = {
  value?: {
    date: string | Date;
    sinceDate: string | Date;
  };
};

export const ActiveForCell: React.FC<ActiveForCellProps> = ({ value }) => {
  if (!value) {
    return null;
  }

  return <>{DateService.dayjs(value.date).from(value.sinceDate, true)}</>;
};
