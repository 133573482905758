import React, { useMemo } from 'react';
import { VehiclePageSection, VehiclePageSectionProps, VehiclePageSectionRowId } from '../types';
import { RadioGroupYesNoBoolean, SectionCard, Select, TextInput } from 'components';
import { VehicleSectionCardRowEditable } from '../components/VehicleSectionCardRowEditable.component';
import { updateVehicle } from 'api/actions';
import { mixed, number, object, string } from 'yup';
import { getSelectOptionsFromEnumHelper, processFormValueUpdate, vehicleEnumHelpers } from 'helpers';
import { UnknownEnum, yesNoUnknownSchema } from 'types';
import { VehicleFuelTypeEnum } from 'api/resources';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';

export const OverviewSection: React.FC<VehiclePageSectionProps> = ({ vehicle }) => {
  const fuelTypeOptions = useMemo(() => getSelectOptionsFromEnumHelper(vehicleEnumHelpers.fuelType), []);

  return (
    <SectionCard title="Overview" id={VehiclePageSection.OVERVIEW}>
      <VehicleSectionCardRowEditable
        title="Name"
        rowId={VehiclePageSectionRowId.NAME}
        formikProps={{
          initialValues: { name: vehicle.name },
          onSubmit: values => updateVehicle(vehicle._id, { name: values.name.trim() }),
          validationSchema: object({ name: string().required('Vehicle name required') })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="name"
            name="name"
            label="Vehcile Name *"
            autoFocus
          />
        )}
        vehicle={vehicle}
      >
        {vehicle.name}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Year"
        rowId={VehiclePageSectionRowId.YEAR}
        formikProps={{
          initialValues: { year: vehicle.year ?? 0 },
          onSubmit: values => updateVehicle(vehicle._id, { year: processFormValueUpdate.number(values.year) }),
          validationSchema: object({ year: number().default(0) })
        }}
        form={<TextInput name="year" type="number" label="Year" />}
        vehicle={vehicle}
      >
        {vehicle.year}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Make"
        rowId={VehiclePageSectionRowId.MAKE}
        formikProps={{
          initialValues: { make: vehicle.make ?? '' },
          onSubmit: values => updateVehicle(vehicle._id, { make: processFormValueUpdate.string(values.make) }),
          validationSchema: object({ make: string().default('') })
        }}
        form={<TextInput name="make" label="Make" />}
        vehicle={vehicle}
      >
        {vehicle.make}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Model"
        rowId={VehiclePageSectionRowId.MODEL}
        formikProps={{
          initialValues: { model: vehicle.model ?? '' },
          onSubmit: values => updateVehicle(vehicle._id, { model: processFormValueUpdate.string(values.model) }),
          validationSchema: object({ model: string().default('') })
        }}
        form={<TextInput name="model" label="Model" />}
        vehicle={vehicle}
      >
        {vehicle.model}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Drivable"
        rowId={VehiclePageSectionRowId.DRIVABLE}
        formikProps={{
          initialValues: { drivable: yesNoEnumHelpers.yesNo.getEnumValue(vehicle.drivable) },
          onSubmit: values => updateVehicle(vehicle._id, { drivable: processFormValueUpdate.yesNoUnknown(values.drivable) }),
          validationSchema: object({ drivable: yesNoUnknownSchema })
        }}
        form={(
          <RadioGroupYesNoBoolean
            name="drivable"
            label="Is the Vehicle drivable?"
            includeUnknown
            row
          />
        )}
        vehicle={vehicle}
      >
        {yesNoEnumHelpers.yesNo.getLabelForValue(vehicle.drivable)}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Total Length"
        rowId={VehiclePageSectionRowId.LENGTH}
        formikProps={{
          initialValues: { length: vehicle.length ?? 0 },
          onSubmit: values => updateVehicle(vehicle._id, { length: processFormValueUpdate.number(values.length) }),
          validationSchema: object({ length: number().default(0) })
        }}
        form={<TextInput name="length" type="number" label="Total Length (in feet)" />}
        vehicle={vehicle}
      >
        {vehicle.length ? `${vehicle.length} ft` : ''}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Cargo Length"
        rowId={VehiclePageSectionRowId.CARGO_LENGTH}
        formikProps={{
          initialValues: { cargoLength: vehicle.cargoLength ?? 0 },
          onSubmit: values => updateVehicle(vehicle._id, { cargoLength: processFormValueUpdate.number(values.cargoLength) }),
          validationSchema: object({ cargoLength: number().default(0) })
        }}
        form={<TextInput name="cargoLength" type="number" label="Cargo Length (in feet)" />}
        vehicle={vehicle}
      >
        {vehicle.cargoLength ? `${vehicle.cargoLength} ft` : ''}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Gross Weight"
        rowId={VehiclePageSectionRowId.GROSS_WEIGHT}
        formikProps={{
          initialValues: { grossWeight: vehicle.grossWeight ?? 0 },
          onSubmit: values => updateVehicle(vehicle._id, { grossWeight: processFormValueUpdate.number(values.grossWeight) }),
          validationSchema: object({ grossWeight: number().default(0) })
        }}
        form={<TextInput name="grossWeight" type="number" label="Gross Weight (in lbs)" />}
        vehicle={vehicle}
      >
        {vehicle.grossWeight ? `${vehicle.grossWeight} lbs` : ''}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Tow Capabilities"
        rowId={VehiclePageSectionRowId.TOW_CAPABILITIES}
        formikProps={{
          initialValues: { towCapabilities: vehicle.towCapabilities ?? '' },
          onSubmit: values => updateVehicle(vehicle._id, { towCapabilities: processFormValueUpdate.string(values.towCapabilities) }),
          validationSchema: object({ towCapabilities: string().default('') })
        }}
        form={<TextInput name="towCapabilities" label="Tow Capabilities" />}
        vehicle={vehicle}
      >
        {vehicle.towCapabilities}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Tow Capacity"
        rowId={VehiclePageSectionRowId.TOW_CAPACITY}
        formikProps={{
          initialValues: { towCapacity: vehicle.towCapacity ?? 0 },
          onSubmit: values => updateVehicle(vehicle._id, { towCapacity: processFormValueUpdate.number(values.towCapacity) }),
          validationSchema: object({ towCapacity: number().default(0) })
        }}
        form={<TextInput name="towCapacity" type="number" label="Tow Capacity (in lbs)" />}
        vehicle={vehicle}
      >
        {vehicle.towCapacity ? `${vehicle.towCapacity} lbs` : ''}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Fuel Type"
        rowId={VehiclePageSectionRowId.FUEL_TYPE}
        formikProps={{
          initialValues: { fuelType: vehicle.fuelType ?? UnknownEnum.unknown },
          onSubmit: values => updateVehicle(vehicle._id, { fuelType: processFormValueUpdate.enumWithUnknown(values.fuelType) }),
          validationSchema: object({ fuelType: mixed<VehicleFuelTypeEnum | UnknownEnum>().oneOf(vehicleEnumHelpers.fuelType.enumValues) })
        }}
        form={<Select name="fuelType" options={fuelTypeOptions} label="Fuel Type"/>}
        vehicle={vehicle}
      >
        {vehicleEnumHelpers.fuelType.getLabel(vehicle.fuelType)}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Highway MPG"
        rowId={VehiclePageSectionRowId.MPG}
        formikProps={{
          initialValues: { mpg: vehicle.mpg ?? 0 },
          onSubmit: values => updateVehicle(vehicle._id, { mpg: processFormValueUpdate.number(values.mpg) }),
          validationSchema: object({ mpg: number().default(0) })
        }}
        form={<TextInput name="mpg" type="number" label="Highway MPG (Miles Per Galon)" />}
        vehicle={vehicle}
      >
        {vehicle.mpg}
      </VehicleSectionCardRowEditable>
      <VehicleSectionCardRowEditable
        title="Mileage"
        rowId={VehiclePageSectionRowId.MILEAGE}
        formikProps={{
          initialValues: { mileage: vehicle.mileage ?? 0 },
          onSubmit: values => updateVehicle(vehicle._id, { mileage: processFormValueUpdate.number(values.mileage) }),
          validationSchema: object({ mileage: number().default(0) })
        }}
        form={<TextInput name="mileage" type="number" label="Mileage" />}
        vehicle={vehicle}
      >
        {vehicle.mileage ? `${vehicle.mileage} miles` : ''}
      </VehicleSectionCardRowEditable>
    </SectionCard>
  );
};
