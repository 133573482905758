import { Box, ToggleButton } from '@mui/material';
import { TextInput, ToggleButtonGroup } from 'components';
import { getSelectOptionsFromEnumHelper, saleEnumHelpers } from 'helpers';
import React from 'react';
import { formSx } from 'styles';

export const OrderSaleForm = () => {
  return (
    <Box sx={formSx.formGroup}>
      <TextInput name="salePrice" type="number" label="Sale Price" fullWidth />
      <ToggleButtonGroup label="Frame" name="frame" color="primary" exclusive fullWidth>
        {getSelectOptionsFromEnumHelper(saleEnumHelpers.frame).map(({ value, label }) => (
          <ToggleButton value={value} key={value}>{label}</ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};
