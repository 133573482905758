import { EventInput } from '@fullcalendar/react';
import { GetEventsResponse, GetTeamsResponse, GetUsersResponse } from 'api/actions';
import { EventParticipationStatusEnum, EventApplicationStatusEnum, EventAcceptanceStatusEnum, EventInsuranceStatusEnum, EventInsuranceSharedEnum, EventFutureInterestStatusEnum, EventDateStaffStatusEnum, User, UserEmployeeRoleEnum } from 'api/resources';
import { FilterEnumFieldConfiguration, YearInputValue, FilterYearFieldConfiguration, YearInputValueOption, FilterResourceFieldConfiguration, FilterCheckboxFieldConfiguration } from 'components';
import { eventEnumHelpers, getEventStaffStatus, getEventDatesStatus, userEnumHelpers } from 'helpers';
import { getWarningsForEvent } from 'helpers/resource-warnings';
import { DateService } from 'services';
import { UnknownEnum, EventStaffStatusEnum, EventDatesStatusEnum, YesNoEnum } from 'types';
import { EventMissingField, eventMissingFieldEnumHelpers } from './helpers';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';

type CreateFilterEnumConfiguration<T extends string> = (arg: {
  defaultValue: FilterEnumFieldConfiguration<GetEventsResponse['data'][number], T>['defaultValue'];
  strict?: boolean;
  readOnly?: boolean;
}) => FilterEnumFieldConfiguration<GetEventsResponse['data'][number], T>;

export const eventParticipationStatusFilterCofiguration: CreateFilterEnumConfiguration<EventParticipationStatusEnum | UnknownEnum> = ({ defaultValue, readOnly }) => {
  return {
    field: 'participationStatus',
    label: 'Participation Status',
    type: 'enum',
    options: eventEnumHelpers.participationStatus.enumValues,
    getOptionLabel: eventEnumHelpers.participationStatus.getLabel,
    predicate: (row, value) => (row.participationStatus ?? UnknownEnum.unknown) === value,
    defaultValue,
    readOnly,
  };
};

export const eventApplicationStatusFilterCofiguration: CreateFilterEnumConfiguration<EventApplicationStatusEnum | UnknownEnum> = ({ defaultValue, readOnly }) => {
  return {
    field: 'applicationStatus',
    label: 'Application Status',
    type: 'enum',
    options: eventEnumHelpers.applicationStatus.enumValues,
    getOptionLabel: eventEnumHelpers.applicationStatus.getLabel,
    predicate: (row, value) => (row.applicationStatus ?? UnknownEnum.unknown) === value,
    defaultValue,
    readOnly,
  };
};

export const eventAcceptanceStatusFilterCofiguration: CreateFilterEnumConfiguration<EventAcceptanceStatusEnum | UnknownEnum> = ({ defaultValue, readOnly }) => {
  return {
    field: 'acceptanceStatus',
    label: 'Acceptance Status',
    type: 'enum',
    options: eventEnumHelpers.acceptanceStatus.enumValues,
    getOptionLabel: eventEnumHelpers.acceptanceStatus.getLabel,
    predicate: (row, value) => (row.acceptanceStatus ?? UnknownEnum.unknown) === value,
    defaultValue,
    readOnly,
  };
};

export const eventFutureInterestStatusFilterCofiguration: CreateFilterEnumConfiguration<EventFutureInterestStatusEnum | UnknownEnum> = ({ defaultValue, readOnly }) => {
  return {
    field: 'futureInterestStatus',
    label: 'Future Interest Status',
    type: 'enum',
    options: eventEnumHelpers.futureInterestStatus.enumValues,
    getOptionLabel: eventEnumHelpers.futureInterestStatus.getLabel,
    predicate: (row, value) => (row.futureInterestStatus ?? UnknownEnum.unknown) === value,
    defaultValue,
    readOnly,
  };
};

export const eventInsuranceStatusFilterCofiguration: CreateFilterEnumConfiguration<EventInsuranceStatusEnum | UnknownEnum> = ({ defaultValue, readOnly }) => {
  return {
    field: 'insuranceStatus',
    label: 'Insurance Status',
    type: 'enum',
    options: eventEnumHelpers.insuranceStatus.enumValues,
    getOptionLabel: eventEnumHelpers.insuranceStatus.getLabel,
    predicate: (row, value) => (row.insurance?.status ?? UnknownEnum.unknown) === value,
    defaultValue,
    readOnly,
  };
};

export const eventInsuranceSharedFilterCofiguration: CreateFilterEnumConfiguration<EventInsuranceSharedEnum | UnknownEnum> = ({ defaultValue, readOnly }) => {
  return {
    field: 'insuranceShared',
    label: 'Insurance Shared',
    type: 'enum',
    options: eventEnumHelpers.insuranceShared.enumValues,
    getOptionLabel: eventEnumHelpers.insuranceShared.getLabel,
    predicate: (row, value) => (row.insurance?.shared ?? UnknownEnum.unknown) === value,
    defaultValue,
    readOnly,
  };
};

export const eventStaffStatusFilterCofiguration: CreateFilterEnumConfiguration<EventStaffStatusEnum> = ({ defaultValue, readOnly }) => {
  return {
    field: 'staffStatus',
    label: 'Staff Status',
    type: 'enum',
    options: eventEnumHelpers.staffStatus.enumValues,
    getOptionLabel: eventEnumHelpers.staffStatus.getLabel,
    predicate: (row, value) => getEventStaffStatus(row.dates) === value,
    defaultValue,
    readOnly,
  };
};

export const eventDatesStatusFilterCofiguration: CreateFilterEnumConfiguration<EventDatesStatusEnum> = ({ defaultValue, readOnly }) => {
  return {
    field: 'datesStatus',
    label: 'Dates Status',
    type: 'enum',
    options: eventEnumHelpers.datesStatus.enumValues,
    getOptionLabel: eventEnumHelpers.datesStatus.getLabel,
    predicate: (row, value) => getEventDatesStatus(row.dates) === value,
    defaultValue,
    readOnly,
  };
};

export const createYesNoUnknown = (args: Omit<FilterEnumFieldConfiguration<GetEventsResponse['data'][number], YesNoEnum | UnknownEnum>, 'type' | 'options' | 'getOptionLabel' | 'defaultValue'>): CreateFilterEnumConfiguration<YesNoEnum | UnknownEnum> => {
  return ({ defaultValue, readOnly }) => ({
    ...args,
    type: 'enum',
    options: [ YesNoEnum.yes, YesNoEnum.no, UnknownEnum.unknown ],
    getOptionLabel: o => yesNoEnumHelpers.yesNo.getLabel(o),
    defaultValue,
    readOnly,
  });
};

export const eventInsuranceRequiredFilterCofiguration = createYesNoUnknown({
  field: 'insuranceRequired',
  label: 'Insurance Required',
  predicate: (row, value) => yesNoEnumHelpers.yesNo.getEnumValue(row.insurance?.isRequired) === value,
});

export const eventLodgingRequiredFilterCofiguration = createYesNoUnknown({
  field: 'lodgingRequired',
  label: 'Lodging Required',
  predicate: (row, value) => yesNoEnumHelpers.yesNo.getEnumValue(row.lodging?.isRequired) === value,
});

export const eventJuryFeeFilterCofiguration = createYesNoUnknown({
  field: 'juryFee',
  label: 'Is there a Jury fee?',
  predicate: (row, value) => yesNoEnumHelpers.yesNo.getEnumValue(row.juryFee) === value,
});

export const eventJuryFeePaidFilterCofiguration = createYesNoUnknown({
  field: 'juryFeePaid',
  label: 'Is Jury fee paid?',
  predicate: (row, value) => yesNoEnumHelpers.yesNo.getEnumValue(row.juryFeePaid) === value,
});

type DateType = 'specified' | 'notSpecified' | 'upcoming' | 'past';
const createSpecifiedDate = (args: Omit<FilterEnumFieldConfiguration<GetEventsResponse['data'][number], DateType>, 'type' | 'options' | 'getOptionLabel' | 'defaultValue'>): CreateFilterEnumConfiguration<DateType> => {
  return ({ defaultValue, strict, readOnly }) => ({
    ...args,
    type: 'enum',
    options: [ 'specified', 'notSpecified', 'upcoming', 'past' ],
    getOptionLabel: o => {
      switch (o) {
        case 'specified':
          return 'Specified';
        case 'notSpecified':
          return 'Not Specified';
        case 'upcoming':
          return 'Upcoming';
        case 'past':
        default:
          return 'Past';
      }
    },
    defaultValue,
    strict,
    readOnly,
  });
};

export const eventApplicationOpenDateFilterCofiguration = createSpecifiedDate({
  field: 'applicationOpenDate',
  label: 'Application Open Date',
  predicate: (row, value) => {
    if (value === 'specified') {
      return !!row.applicationOpenDate || !!row.applicationIsOpen;
    }
    if (value === 'notSpecified') {
      return !row.applicationOpenDate && !row.applicationIsOpen;
    }

    if(value === 'upcoming') {
      return !!row.applicationOpenDate && DateService.dayjs().isBefore(row.applicationOpenDate, 'day');
    }

    return row.applicationIsOpen || (!!row.applicationOpenDate && !DateService.dayjs().isBefore(row.applicationOpenDate, 'day'));
  }
});

export const eventApplicationDeadlineDateFilterCofiguration = createSpecifiedDate({
  field: 'applicationDeadlineDate',
  label: 'Application Deadline Date',
  predicate: (row, value) => {
    if (value === 'specified') {
      return !!row.applicationDeadlineDate || !!row.applicationOpenUntilFull;
    }
    if (value === 'notSpecified') {
      return !row.applicationDeadlineDate && !row.applicationOpenUntilFull;
    }

    if(value === 'upcoming') {
      return row.applicationOpenUntilFull || (!!row.applicationDeadlineDate && DateService.dayjs().isBefore(row.applicationDeadlineDate, 'day'));
    }

    return !!row.applicationDeadlineDate && !DateService.dayjs().isBefore(row.applicationDeadlineDate, 'day');
  }
});

type TYearInputValueOption = 'all' | 'upcoming';
export const eventYearFilterCofiguration = (defaultValue: YearInputValue<TYearInputValueOption>, readOnly?: boolean): FilterYearFieldConfiguration<GetEventsResponse['data'][number]> => {
  const options: YearInputValueOption<GetEventsResponse['data'][number], TYearInputValueOption>[] = [
    {
      value: 'all',
      label: 'All',
      predicate: () => true,
    },
    {
      value: 'upcoming',
      label: 'Upcoming',
      predicate: (row) => {
        const currentDate = DateService.dayjs();

        if (!row.endDate) {
          return row.year >= currentDate.year();
        }

        return !DateService.dayjsTz(row.endDate.dateAsUtc).isBefore(currentDate, 'day');
      },
    }
  ];

  return {
    label: 'Dates',
    field: 'year',
    type: 'year',
    defaultValue,
    options,
    yearPredicate: (row, value) => row.year === value,
    readOnly
  };
};

export const eventTeamsFilterCofiguration = (teams: GetTeamsResponse['data'], loading: boolean, defaultValue?: FilterResourceFieldConfiguration<GetEventsResponse['data'][number]>['defaultValue'], readOnly?: boolean): FilterResourceFieldConfiguration<GetEventsResponse['data'][number]> => {
  const teamOptions = teams.map(({ _id, name }) =>({ _id, label: name }));

  return {
    field: 'teams',
    label: 'Teams',
    type: 'resource',
    options: teamOptions,
    defaultValue: defaultValue ?? 'any',
    predicate: (row, value) => {
      if(value === 'notSelected') {
        return !row.team;
      }

      return row.team?._id === value;
    },
    includeAny: true,
    includeNotSelected: true,
    loading,
    readOnly,
  };
};

export const eventTeamLeadsFilterConfiguration = (users: GetUsersResponse['data'], loading: boolean, readOnly?: boolean): FilterResourceFieldConfiguration<GetEventsResponse['data'][number]> => {
  const userOptions = users.map(({ _id, name }) =>({ _id, label: name }));

  return {
    field: 'teamLeads',
    label: 'Team Leads',
    type: 'resource',
    options: userOptions,
    defaultValue: 'any',
    predicate: (row, value) => {
      const teamLeads = row.dates
        .reduce((r, { staff }) => {
          return [ ...r, ...staff.filter(staffMember => staffMember.isTeamLead) ];
        }, [])
        .filter(value => value);

      if(value === 'notSelected') {
        return teamLeads.length === 0;
      }

      return teamLeads.some(staffMember => staffMember.user._id === value);
    },
    includeAny: true,
    includeNotSelected: true,
    loading,
    readOnly,
  };
};

export const eventOnlyShowMissingInfoFilterConfigureation = (defaultValue: boolean, readOnly?: boolean): FilterCheckboxFieldConfiguration<GetEventsResponse['data'][number]> => {
  return {
    field: 'onlyShowMissingInfo',
    label: 'Only show events that are missing info',
    type: 'checkbox',
    predicate: (row, value) => {
      const missingInfo = getWarningsForEvent(row).warningItems.length;

      if(value) {
        return !!missingInfo;
      }

      return true;
    },
    defaultValue,
    readOnly,
  };
};

// Missing field

export const eventMissingFieldFilterConfiguration: CreateFilterEnumConfiguration<EventMissingField> = ({ defaultValue, readOnly }) => {
  return {
    field: 'showMissingData',
    label: 'Show events missing:',
    type: 'enum',
    options: eventMissingFieldEnumHelpers.enumValues,
    getOptionLabel: eventMissingFieldEnumHelpers.getLabel,
    predicate: (row, value) => {
      if (value === EventMissingField.participationStatus) {
        return !row.participationStatus;
      }

      if (value === EventMissingField.applicationOpenDate) {
        return (!row.applicationOpenDate && !row.applicationIsOpen);
      }

      if (value === EventMissingField.applicationDeadlineDate) {
        return (!row.applicationDeadlineDate && !row.applicationOpenUntilFull);
      }

      return false;
    },
    defaultValue,
    readOnly,
  };
};

// Events Calendar

export const eventsCalendarEventParticipatioStatusFilterConfiguration: FilterEnumFieldConfiguration<EventInput, EventParticipationStatusEnum | UnknownEnum> = {
  field: 'participationStatus',
  label: 'Participation Status',
  type: 'enum',
  options: eventEnumHelpers.participationStatus.enumValues,
  getOptionLabel: eventEnumHelpers.participationStatus.getLabel,
  predicate: (row, value) => (row.extendedProps?.event.participationStatus ?? UnknownEnum.unknown) === value,
  defaultValue: [ EventParticipationStatusEnum.interested, EventParticipationStatusEnum.participating ],
};

export const eventsCalendarEventAcceptanceStatusFilterConfiguration: FilterEnumFieldConfiguration<EventInput, EventAcceptanceStatusEnum | UnknownEnum> = {
  field: 'acceptanceStatus',
  label: 'Acceptance Status',
  type: 'enum',
  options: eventEnumHelpers.acceptanceStatus.enumValues,
  getOptionLabel: eventEnumHelpers.acceptanceStatus.getLabel,
  predicate: (row, value) => (row.extendedProps?.event.acceptanceStatus ?? UnknownEnum.unknown) === value,
  defaultValue: [ EventAcceptanceStatusEnum.accepted ],
};

export const eventsCalendarEventStaffStatusFilterConfiguration: FilterEnumFieldConfiguration<EventInput, EventStaffStatusEnum> = {
  field: 'staffStatus',
  label: 'Event Staff Status',
  type: 'enum',
  options: eventEnumHelpers.staffStatus.enumValues,
  getOptionLabel: eventEnumHelpers.staffStatus.getLabel,
  predicate: (row, value) => getEventStaffStatus(row.extendedProps?.eventDate) === value,
  defaultValue: [ EventStaffStatusEnum.fulfilled, EventStaffStatusEnum.pending ],
};

export const eventsCalendarEventEventManagerFilterConfiguration = (users: User[], usersLoading?: boolean): FilterResourceFieldConfiguration<EventInput> => {
  const userOptions = users
    .filter(user => user.employeeRoles.includes(UserEmployeeRoleEnum.eventManager))
    .map(({ _id, name }) =>({ _id, label: name }));

  return {
    field: 'eventManager',
    label: 'Event Manager',
    type: 'resource',
    options: userOptions,
    defaultValue: 'any',
    predicate: (row, value) => {
      if(value === 'notSelected') {
        return !row.extendedProps?.event.eventManager;
      }

      return row.extendedProps?.event.eventManager?._id === value;
    },
    includeAny: true,
    includeNotSelected: true,
    loading: usersLoading,
  };
};

export const eventsCalendarEventTeamManagerFilterConfiguration = (users: User[], usersLoading?: boolean): FilterResourceFieldConfiguration<EventInput> => {
  const userOptions = users
    .filter(user => user.employeeRoles.includes(UserEmployeeRoleEnum.teamLead))
    .map(({ _id, name }) =>({ _id, label: name }));

  return {
    field: 'teamManager',
    label: 'Team Manager',
    type: 'resource',
    options: userOptions,
    defaultValue: 'any',
    predicate: (row, value) => {
      if(value === 'notSelected') {
        return !row.extendedProps?.event.teamManager;
      }

      return row.extendedProps?.event.teamManager?._id === value;
    },
    includeAny: true,
    includeNotSelected: true,
    loading: usersLoading,
  };
};

export const eventsCalendarEventTeamFilterConfiguration = (teams: GetTeamsResponse['data'], teamsLoading?: boolean): FilterResourceFieldConfiguration<EventInput> => {
  const teamOptions = teams.map(({ _id, name }) =>({ _id, label: name }));

  return {
    field: 'teams',
    label: 'Teams',
    type: 'resource',
    options: teamOptions,
    defaultValue: 'any',
    predicate: (row, value) => {
      if(value === 'notSelected') {
        return !row.extendedProps?.event.team;
      }

      return row.extendedProps?.event.team?._id === value;
    },
    includeAny: true,
    includeNotSelected: true,
    loading: teamsLoading
  };
};

export const eventsCalendarEventUserStaffStatusFilterConfiguration = (user: User | null): FilterEnumFieldConfiguration<EventInput, EventDateStaffStatusEnum | UnknownEnum>  => ({
  field: 'userStaffStatus',
  label: 'User Staff Status',
  type: 'enum',
  options: [ ...userEnumHelpers.staffStatus.enumValues, UnknownEnum.unknown ],
  getOptionLabel: (option) => option === UnknownEnum.unknown ? 'Unknown' : userEnumHelpers.staffStatus.getLabel(option),
  predicate: (row, value) => {
    const staffStatus = row.extendedProps?.eventDate.staff.find((staff: GetEventsResponse['data'][number]['dates'][number]['staff'][number]) => staff.user._id === user?._id)?.status;

    if (!staffStatus && value === UnknownEnum.unknown) {
      return true;
    }

    return staffStatus === value;
  },
  defaultValue: [ EventDateStaffStatusEnum.confirmed, EventDateStaffStatusEnum.requested, EventDateStaffStatusEnum.deniedRequest, EventDateStaffStatusEnum.initial, UnknownEnum.unknown ],
});
