import { OrderFulFillmentStatusEnum } from 'api/resources';
import { StatusChipProps } from '../types';
import { StandardChip } from '../StandardChip.component';
import { orderEnumHelpers } from 'helpers';

export type OrderFulfillmentStatusChipProps = StatusChipProps<OrderFulFillmentStatusEnum>;

export const OrderFulfillmentStatusChip: React.FC<OrderFulfillmentStatusChipProps> = props => {
  return <StandardChip {...orderEnumHelpers.fulfillmentStatus} {...props} />;
};

