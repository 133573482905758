import { authAxiosRequest } from 'api/axios';
import { GetEventDateAddStaffUserOptions } from '../eventDate';
import { Event, EventDate, EventDateTypeEnum, EventForResourceTypeEnum, OrderPaymentEnum, User } from 'api/resources';
import { GetEventsResponse } from '../event';
import { ApiResource, PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { GetUserAvailabilityResponse } from '../user';
import { GetSalesAsResourceAnalyticsDataInput, GetSalesAsResourceAnalyticsDataResponse } from '../sale';
import { AnalyticsTimelineChartTimePeriodEnum } from 'components';

export type GetStaffSchedulingForEventInput = {
  eventId: string;
};

export type GetStaffSchedulingForEventResponse = {
  data: {
    [eventDateId: string]: {
      [userId: string]: Omit<GetEventDateAddStaffUserOptions['data'][number], 'conflictEvent'> & {
        conflictEvent?: {
          name: string;
          _id: string;
        };
      };
    };
  };
};

export const getStaffSchedulingForEvent = (data: GetStaffSchedulingForEventInput) => {
  return authAxiosRequest<GetStaffSchedulingForEventResponse>('/api/custom/get_staff_scheduling_for_event', { method: 'POST', data });
};

export type GetUpcomingEventsHashResponse = {
  data: {
    [resourceId: string]: {
      event: GetEventsResponse['data'][number];
      type: EventForResourceTypeEnum;
    }[];
  };
};

export type GetUpcomingEventsForTeamsHashInput = {
  teamId?: string;
  limit?: number;
};

export const getUpcomingEventsForTeamsHash = (data?: GetUpcomingEventsForTeamsHashInput) => {
  return authAxiosRequest<GetUpcomingEventsHashResponse>('/api/custom/get_upcoming_events_for_teams_hash', { method: 'POST', data });
};

export type GetUpcomingEventsForVehiclesHashInput = {
  vehicleId?: string;
  limit?: number;
};

export const getUpcomingEventsForVehiclesHash = (data?: GetUpcomingEventsForVehiclesHashInput) => {
  return authAxiosRequest<GetUpcomingEventsHashResponse>('/api/custom/get_upcoming_events_for_vehicles_hash', { method: 'POST', data });
};

export type GetUsersAvailabilitySetHashResponse = {
  data: {
    [userId: string]: {
      title: string;
      label: string;
      severity: 'error' | 'warning' | 'success';
      availabilitySlots: GetUserAvailabilityResponse['data'];
    };
  };
};

export const getUsersAvailabilitySetHash = () => {
  return authAxiosRequest<GetUsersAvailabilitySetHashResponse>('/api/custom/get_users_availability_set_hash');
};

export type GetEventSalesAnalyticsDataResponse = {
  data: {
    sales: GetSalesAsResourceAnalyticsDataResponse['data'];
    events: ResourceWithPopulated<PickFromResource<Event, 'year'>, {
      dateRange: { startAsUtc: string; endAsUtc: string };
      dates: ApiResource<{
        type: EventDateTypeEnum;
        dateAsUtc: string;
      }>[];
    }>[];
  };
};

export const getEventSalesAnalyticsData = (eventId: string) => {
  return authAxiosRequest<GetEventSalesAnalyticsDataResponse>('/api/custom/get_event_sales_analytics_data', { method: 'POST', data: { eventId } });
};

type GetSalesAnalyticsDataInput = {
  timePeriod: AnalyticsTimelineChartTimePeriodEnum;
  timezone: string;
};

type GetSalesAnalyticsDataRespose = {
  data: {
    labels: string[];
    data: number[];
  };
};

export type GetSalesAnalyticsDataWithCompareInput = GetSalesAnalyticsDataInput & {
  dateRange: { start: string; end: string };
  compareDateRange: { start: string; end: string } | null;
};

export type GetSalesAnalyticsDataWithCompareResponse = {
  data: GetSalesAnalyticsDataRespose['data'] & {
    compareData: GetSalesAnalyticsDataRespose['data']['data'];
  };
};

export const getSalesAnalyticsDataWithCompare = (data: GetSalesAnalyticsDataWithCompareInput) => {
  return authAxiosRequest<GetSalesAnalyticsDataWithCompareResponse>('/api/custom/get_sales_analytics_data_with_compare', { method: 'POST', data });
};

export type GetSalesReportDataResponse = {
  data: GetSalesAnalyticsDataRespose['data'] & {
    paymentHash: Record<OrderPaymentEnum, { count: number; revenue: number }>;
    totalRevenue: number;
    salesCount: number;
    dateRange: { start: string; end: string };
  };
};

export type GetSalesReportDataInput = GetSalesAnalyticsDataInput & GetSalesAsResourceAnalyticsDataInput;

export const getSalesReportData = (data: GetSalesReportDataInput) => {
  return authAxiosRequest<GetSalesReportDataResponse>('/api/custom/get_sales_report_data', { method: 'POST', data });
};

export type GetEventsForDashboardResponse = {
  data: {
    feedbackReminder: GetEventsResponse['data'];
    upcomingAttendEvents: (GetEventsResponse['data'][number] & {
      upcomingEventDate: ResourceWithPopulated<EventDate, {
        staff: ResourceWithPopulated<EventDate['staff'][number], { user: PickFromResource<User, 'name'> }>[];
      }>;
    })[];
    requestedEvents: (GetEventsResponse['data'][number] & {
      statusDates: ResourceWithPopulated<EventDate, {
        staff: ResourceWithPopulated<EventDate['staff'][number], {
          user: PickFromResource<User, 'name'>;
        }>[];
      }>[];
    })[];
    selfRequestedEvents: (GetEventsResponse['data'][number] & {
      statusDates: ResourceWithPopulated<EventDate, {
        staff: ResourceWithPopulated<EventDate['staff'][number], {
          user: PickFromResource<User, 'name'>;
        }>[];
      }>[];
    })[];
    calendarEvents: GetEventsResponse['data'];
  };
};

export const getEventsForDashboard = () => {
  return authAxiosRequest<GetEventsForDashboardResponse>('/api/custom/get_events_for_dashboard', { method: 'POST' });
};