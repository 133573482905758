import { Box, Button, Input, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { OrderPaymentEnum } from 'api/resources';
import { EventSalesLogProductListProps } from './EventSalesLogProductList.component';
import { GetProductsResponse } from 'api/actions';
import { SaleProductListItem } from 'components';
import { useShopifyProducts } from 'queries/shopify';
import { grey } from '@mui/material/colors';

export type ProductValuesType = {
  product: GetProductsResponse['data'][number];
} & Pick<EventSalesLogProductListProps, 'onAddProduct'>;

export type ProductValuesStateType = {
  imageUrl?: string;
  frame: string;
  price: string;
  shipping: string;
  variantId?: number;
};

export const ProductValues: React.FC<ProductValuesType> = ({ onAddProduct, product }) => {
  const [ loading, setLoading ] = useState(false);
  const initialValues = { frame: 'yes', payment: OrderPaymentEnum.cash, price: '50', shipping: 'no' };
  const [ values, setValues ] = useState<ProductValuesStateType>(initialValues);
  const { data: shopifyProducts = [] } = useShopifyProducts(product.shopifyProductId ? [ product.shopifyProductId ] : [], { enabled: !!product.shopifyProductId });

  const { frame, price, shipping, imageUrl, variantId } = values;

  const onFrameChange = (val: string) => {
    if (!val) return;
    setValues((p) => ({ ...p, frame: val }));
  };

  const onPriceChange = (val: string) => setValues((p) => ({ ...p, price: val }));
  const onShippingChange = (val: string) => setValues((p) => ({ ...p, shipping: val }));
  const onVariantChange = (val: number) => setValues((p) => ({ ...p, variantId: val, imageUrl: shopifyProducts[0]?.variants?.find(variant => variant.id === val)?.imageSrc }));

  const addProductHandler = async () => {
    setLoading(true);
    await onAddProduct({
      frame: frame === 'yes',
      shipping: shipping === 'yes',
      salePrice: Number(price),
      product: product,
      variantId,
      imageUrl,
    });
  };

  const variantError = !variantId && shipping === 'yes' ? 'Please select a variant' : undefined;

  return (
    <SaleProductListItem
      name={product.name}
      isProductImagesDisplay={!imageUrl}
      imageUrl={imageUrl ?? product.imageUrl}
      selectedVariant={values.variantId}
      onSetVariant={onVariantChange}
      variantError={variantError}
      variants={shopifyProducts[0]?.variants}
      openInFullBgColor={grey[200]}
      bottomContent={(
        <Box
          display="flex"
          alignItems={{ xs: 'flex-start', lg: 'center' }}
          justifyContent="space-between"
          mt={2}
          mb={3}
          ml={1}
          flexDirection={{ xs: 'column', lg: 'initial' }}
        >
          <Box
            display="flex"
            gap="2rem"
            alignItems="flex-start"
            flexDirection={{ xs: 'column', lg: 'initial' }}
            mb={{ xs: 3, md: 0 }}
          >
            <Box>
              <Typography variant="subtitle2" display="block">Frame</Typography>
              <ToggleButtonGroup
                size="small"
                value={frame}
                color="primary"
                exclusive
                onChange={(_e, val) => onFrameChange(val)}
                disabled={loading}
              >
                <ToggleButton value="yes">Yes</ToggleButton>
                <ToggleButton value="no">No</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box>
              <Typography variant="subtitle2" display="block">Shipping</Typography>
              <ToggleButtonGroup
                size="small"
                value={shipping}
                color="primary"
                exclusive
                onChange={(_e, val) => onShippingChange(val)}
                disabled={loading}
              >
                <ToggleButton value="yes">Yes</ToggleButton>
                <ToggleButton value="no">No</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box>
              <Typography variant="subtitle2" display="block">Price</Typography>
              <Input
                disabled={loading}
                value={price}
                size="small"
                name="price"
                type="number"
                onChange={(e) => onPriceChange(e.target.value)}
                sx={{ width: 64 }}
              />
            </Box>
          </Box>
          <Box width={{ sx: '100%', lg: 'initial' }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={addProductHandler}
              disabled={!price || loading || !!variantError}
              fullWidth
            >
              Add
            </Button>
          </Box>
        </Box>
      )}
    />
  );
};
