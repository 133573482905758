import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetTeamResponse, getTeam, getTeamVehicleChangeOptions, getTeams } from 'api/actions';
import { QUERY_KEY } from './query-keys';

export const useTeams = () => {
  return useQuery(QUERY_KEY.TEAMS, async () => {
    const response = await getTeams();

    return response.data;
  });
};

export const useTeam = (id: string, opts?: UseQueryOptions<GetTeamResponse['data']>) => {
  return useQuery(QUERY_KEY.TEAM(id), async () => {
    const response = await getTeam(id);

    return response.data;
  }, opts);
};

export const useTeamVehicleChangeOptions = (id: string) => {
  return useQuery(QUERY_KEY.TEAM_VEHICLE_CHANGE_OPTIONS(id), async () => {
    const response = await getTeamVehicleChangeOptions(id);

    return response.data;
  });
};
