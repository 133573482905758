import { Box, Button, Container, Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AttentionWarningIcon, EventDatePageSection, EventDatePageSectionRowId, JumpNav, PageContentContainer, PageHeadProps, ResourcePaginate, SalesReport, SectionCardsEditContextProvider, StickyStackItem, getInitialAnalyticsTimelineChartTimePeriodForDateRange, useEventContext } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { DateService } from 'services';
import { FORMAT_DATE } from 'constants/format-date';
import { createContext, useMemo, useState } from 'react';
import { DeleteEventDateModal } from './components';
import { getWarningsForEventDate } from 'helpers/resource-warnings/warning-config/event-date-warning-config';
import { useSalesForEvent } from 'queries/sale';
import { GetEventResponse } from 'api/actions';
import { EventPageHeader } from 'pages/Event/components';
import { useCurrentUser } from 'contexts';
import { EventSalesTable } from '../EventSales';
import { OverviewSection } from './sections/Overview.section';
import { StaffSection } from './sections/Staff.section';
import { PhotosSection } from './sections/Photos.section';
import { eventDatePageSectionHelpers, eventDatePageSectionRowHelpers } from './helpers';
import { GetWarningsForResourceOutput } from 'helpers/resource-warnings';
import { UserEmployeeRoleEnum } from 'api/resources';

export type AddStaffModalContainerType = {
  open: boolean;
  eventDate: GetEventResponse['data']['dates'][number];
  fullFormattedDate: string;
  onClose: () => void;
};

type ViewType = null | 'deleteDate';

type EventDatePageRowWarningsContextType = {
  getRowWarningDescription: (row: EventDatePageSectionRowId) => string[] | undefined;
};

export const EventDatePageRowWarningsContext = createContext<EventDatePageRowWarningsContextType>({
  getRowWarningDescription: () => undefined,
});

export const EventDate: React.FC = () => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const { dateId } = useParams();
  const { event } = useEventContext();
  const { data: sales = [], isInitialLoading: salesLoading } = useSalesForEvent(event._id);
  const [ view, setView ] = useState<ViewType>(null);

  const eventDate = event.dates.find((date) => date._id === dateId);
  const eventDateSales = sales.filter((sale) => sale.order.eventDate._id === dateId);

  const { warningItems, getRowWarningDescription } = useMemo(() => {
    if (eventDate) {
      return getWarningsForEventDate(eventDate);
    }

    return { warningItems: [], getRowWarningDescription: () => [] } as GetWarningsForResourceOutput<EventDatePageSection, EventDatePageSectionRowId>;
  }, [ eventDate ]);
  const tabs = useMemo(() => {
    const getBadgeContent = (sectionId: EventDatePageSection) => warningItems?.find(warningItem => warningItem.section === sectionId)?.reasonItems.length;

    return eventDatePageSectionHelpers.enumValues.map(sectionId => ({
      label: eventDatePageSectionHelpers.getLabel(sectionId),
      sectionId,
      badgeContent: getBadgeContent(sectionId),
      hidden: eventDatePageSectionHelpers.getIsHidden(sectionId, currentUser),
    }));
  }, [ currentUser, warningItems ]);

  if (!eventDate) {
    return null;
  }

  const isToday = DateService.dayjsTz(eventDate.dateAsUtc).isToday();
  const formattedDate = DateService.dayjsTz(eventDate.dateAsUtc).format(FORMAT_DATE.standardShort);

  const options: PageHeadProps['options'] = [
    {
      label: 'Delete Event Date',
      menuItemProps: { onClick: () => setView('deleteDate') },
      labelTypographyProps: { sx: { color: (theme) => theme.palette.error.dark } },
    },
  ];

  return (
    <PageContentContainer
      maxWidth="lg"
      preventContainer
      breadcrumbs={{
        prev: [
          { link: ROUTING_CONFIG.events, text: 'Events' },
          { link: `${ROUTING_CONFIG.events}/${event._id}`, text: event.name },
          { link: `${ROUTING_CONFIG.events}/${event._id}/dates`, text: 'Event Dates' },
        ],
        current: formattedDate
      }}
      header={(
        <EventPageHeader
          pageTitle="Event Date Page"
          options={options}
          attentionWarningIcon={<AttentionWarningIcon warningItems={warningItems} sectionEnumHelpers={eventDatePageSectionHelpers} rowEnumHelpers={eventDatePageSectionRowHelpers} />}
          subHeaderContent={formattedDate}
          actions={(
            <>
              <Button onClick={() => navigate(`/${ROUTING_CONFIG.events}/${event._id}/scheduling`)}>Scheduling</Button>
              {isToday && <Button onClick={() => navigate(`/${ROUTING_CONFIG.loggingPortal}/event-date/${eventDate._id}`)} variant="contained">Logging Portal</Button>}
            </>
          )}
          sticky
          tabs={(
            <JumpNav
              initialValue={EventDatePageSection.OVERVIEW}
              offsetTop={64}
              tabs={tabs}
            />
          )}
        />
      )}
    >
      {view === 'deleteDate' && (
        <DeleteEventDateModal
          eventDate={eventDate}
          eventId={event._id}
          eventDateSalesCount={eventDateSales.length}
          onClose={() => setView(null)}
        />
      )}

      <StickyStackItem placement="top" order={2}>
        <Box px={2} pt={1}>
          <ResourcePaginate
            resourceList={event.dates}
            currentResource={eventDate}
            basePath={`${ROUTING_CONFIG.events}/${event._id}/${ROUTING_CONFIG.dates}`}
          />
        </Box>
      </StickyStackItem>

      <Box display="flex" flexDirection="column" gap={5} pb={10}>

        <SectionCardsEditContextProvider>
          <EventDatePageRowWarningsContext.Provider value={{ getRowWarningDescription }}>
            <Container maxWidth="lg">
              <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5}>
                <OverviewSection eventDate={eventDate} />
                <StaffSection eventDate={eventDate} />
              </Box>
            </Container>

            <Container maxWidth="lg">
              <PhotosSection eventDate={eventDate} />
            </Container>


            {currentUser.isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]) && (
              <Box display="flex" flexDirection="column" gap={5} component="div" id={EventDatePageSection.SALES}>

                <Box bgcolor="background.paper">
                  <Box bgcolor="primary.background">
                    <Divider />
                    <Container maxWidth="lg">
                      <Box py={5}>
                        <SalesReport eventDateId={eventDate._id} timePeriod={getInitialAnalyticsTimelineChartTimePeriodForDateRange({ start: DateService.dayjsTz(eventDate.dateAsUtc), end: DateService.dayjsTz(eventDate.dateAsUtc) })} endToday={false} />
                      </Box>
                    </Container>
                    <Divider />
                  </Box>
                </Box>

                <Container maxWidth="lg">
                  <EventSalesTable sales={eventDateSales} loading={salesLoading} />
                </Container>

              </Box>
            )}
          </EventDatePageRowWarningsContext.Provider>
        </SectionCardsEditContextProvider>
      </Box>

    </PageContentContainer>
  );
};
