import { AddressAutocompleteFormType } from 'components/Inputs';
import { phoneRegex } from 'validation';
import { object, SchemaOf, string } from 'yup';

export type ShipmentInfo = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
} & AddressAutocompleteFormType;

export const shipmentInfoFormValidationSchema: SchemaOf<ShipmentInfo> = object().shape({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  place: object().shape({
    address: string().required('Address is required'),
    placeId: string().required('Place ID is required'),
    address2: string().default(''),
  }),
  email: string().email('Please enter a valid email').required('Email is required'),
  phone: string().matches(phoneRegex, { message: 'Phone number is not valid', excludeEmptyString: true }).default(''),
}, [ [ 'email', 'phone' ], [ 'phone', 'email' ] ]);
