import { GetEventResponse } from 'api/actions';
import { DateService } from 'services';

export const getSortedPaymentsDue = (paymentsDue: GetEventResponse['data']['paymentsDue']) => {
  return [ ...paymentsDue ].sort((a, b) => {
    if (a.dueWithApplication) {
      return -1;
    }
    if (b.dueWithApplication) {
      return -1;
    }

    if (a.dueAsSoonAsPossible) {
      return -1;
    }
    if (b.dueAsSoonAsPossible) {
      return -1;
    }

    return DateService.dayjsTz(a.dueDateAsUtc).isBefore(DateService.dayjsTz(b.dueDateAsUtc)) ? -1 : 1;
  });
};