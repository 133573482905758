import { OrderSaleFulfillmentStatusEnum } from 'api/resources';
import { StatusChipProps } from '../types';
import { StandardChip } from '../StandardChip.component';
import { orderEnumHelpers } from 'helpers';

export type OrderSaleFulfillmentStatusChipProps = StatusChipProps<OrderSaleFulfillmentStatusEnum>;

export const OrderSaleFulfillmentStatusChip: React.FC<OrderSaleFulfillmentStatusChipProps> = props => {
  return <StandardChip {...orderEnumHelpers.saleFulfillmentStatus} {...props} />;
};

