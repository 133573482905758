import { OpenInFullOutlined } from '@mui/icons-material';
import { Box, Divider, FormHelperText, IconButton, ListItemAvatar, ListItemText, Typography, alpha } from '@mui/material';
import { GetShopifyProductsResopnse } from 'api/actions';
import { OrderSaleFulfillmentTypeEnum } from 'api/resources';
import { missingImg } from 'assets';
import { EditableImage, ImagesCarousel, OrderSaleFulfillmentTypeChip, ScrollableView } from 'components';
import React, { useState } from 'react';

export type SaleProductListItemProps = {
  imageUrl: string | undefined;
  name: string;
  fulfillmentType?: OrderSaleFulfillmentTypeEnum;
  secondary?: string;
  selectedVariant?: number;
  variants?: GetShopifyProductsResopnse['data'][number]['variants'];
  variantError?: string;
  onSetVariant?: (variantId: number) => void;
  actions?: React.ReactNode;
  otherAction?: React.ReactNode;
  wrapActionsOnMobile?: boolean;
  bottomContent?: React.ReactNode;
  isProductImagesDisplay?: boolean;
  openInFullBgColor?: string;
};

export const SaleProductListItem: React.FC<SaleProductListItemProps> = props => {
  const [ openInFull, setOpenInFull ] = useState(false);
  const variants = props.variants;

  return (
    <Box
      component="li"
      display="flex"
      flexDirection="column"
    >
      {(openInFull && props.imageUrl) && (
        <ImagesCarousel
          initialIndex={0}
          imageSrcs={[ props.imageUrl ]}
          onClose={() => setOpenInFull(false)}
          isProductImagesDisplay={props.isProductImagesDisplay ?? true}
          renderInfo={() => <strong>{props.name}</strong>}
        />
      )}
      <Box
        display="flex"
        flexDirection={{ xs: props.wrapActionsOnMobile ? 'column' : 'row', sm: 'row' }}
        rowGap={2}
        columnGap={3}
        py={1}
        px={{ xs: 1, sm: 2 }}
      >
        <Box flex={1} display="flex" alignItems="center">
          <ListItemAvatar sx={{ mr: 2 }}>
            <IconButton disabled={!props.imageUrl} onClick={() => setOpenInFull(true)}>
              <Box
                display="flex"
                component="img"
                src={props.imageUrl || missingImg}
                height="58px"
                borderRadius={props.isProductImagesDisplay === false ? undefined : '50%'}
              />
              {!!props.imageUrl && (
                <OpenInFullOutlined
                  sx={{
                    position: 'absolute',
                    right: props.isProductImagesDisplay === false ? 3 : 5,
                    bottom: props.isProductImagesDisplay === false ? 5 : 10,
                    fontSize: 'small',
                    bgcolor: props.openInFullBgColor ?? (theme => theme.palette.background.paper),
                    borderRadius: '50%'
                  }}
                />
              )}
            </IconButton>
          </ListItemAvatar>
          <Box flex={1} display="flex">
            <ListItemText
              primary={props.name}
              primaryTypographyProps={{ fontWeight: 500 }}
              secondary={props.fulfillmentType ? (
                <Box mt={0.5}>
                  <OrderSaleFulfillmentTypeChip size="small" value={props.fulfillmentType} />
                  <Typography variant="body2" color="text.secondary" mt={0.5}>{props.secondary}</Typography>
                </Box>
              ) : props.secondary}
              sx={{ mr: 1 }}
            />
            {props.otherAction && (
              <Box display="flex" alignItems="center" gap={1}>
                {props.otherAction}
              </Box>
            )}
          </Box>
        </Box>
        {props.actions && (
          <Box
            display="flex"
            alignItems="center"
            px={{ xs: props.wrapActionsOnMobile ? 1 : 0, sm: 0 }}
          >
            {props.actions}
          </Box>
        )}
      </Box>
      {(variants && props.onSetVariant) && (
        <>
          <Divider />
          <Box bgcolor={theme => props.variantError ? theme.palette.error.background : undefined} pb={1}>
            <ScrollableView orientation="horizontal" minHeight={210} enableScrollShadow py={1}>
              <Box
                sx={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  gridTemplateRows: 'repeat(2, 1fr)',
                }}
              >
                {variants.map((variant, i) => {
                  const isSelected = props.selectedVariant === variant.id;

                  return (
                    <Box
                      key={i}
                      component="div"
                      my="auto"
                      pt={1}
                      px={2}
                      pb={2}
                      bgcolor={theme => isSelected ? alpha(theme.palette.primary.main, 0.2) : undefined}
                      position="relative"
                      // height="50%"
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: theme => isSelected ? undefined : theme.palette.primary.background,
                        }
                      }}
                      onClick={() => props.onSetVariant?.(variant.id)}
                      display="flex"
                      alignItems="flex-start"
                    >
                      <Box pb={1}>
                        <EditableImage
                          width="70px"
                          height="70px"
                          onUpload={()=>{}}
                          imageUrl={variant.imageSrc}
                          modalTitle=""
                          disabledEditable
                        />
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          position: 'absolute',
                          bottom: 1,
                          left: 0,
                          width: '95%',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          lineHeight: '1.1',
                        }}
                      >
                        {variant.title}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </ScrollableView>
            <FormHelperText error sx={{ textAlign: 'center', fontSize: theme => theme.typography.body2.fontSize }}>
              {props.variantError}
            </FormHelperText>
          </Box>
        </>
      )}
      {props.bottomContent && (
        <Box display="flex" alignItems="center">
          {props.bottomContent}
        </Box>
      )}
    </Box>
  );

  // return (
  //   <ListItem sx={{ flexWrap: 'wrap-reverse' }}>
  //     <ListItemAvatar sx={{ mr: 2 }}>
  //       <Box
  //         display="flex"
  //         component="img"
  //         src={props.imageUrl || missingImg}
  //         height="58px"
  //         borderRadius="50%"
  //       />
  //     </ListItemAvatar>
  //     <ListItemText
  //       primary={props.name}
  //       primaryTypographyProps={{ fontWeight: 500 }}
  //       secondary={props.secondary}
  //       sx={{ mr: 1 }}
  //     />
  //     {props.actions && (
  //       <ListItemSecondaryAction
  //         sx={{
  //           position: { xs: props.wrapActionsOnMobile ? 'unset' : undefined, sm: 'absolute' }
  //         }}
  //       >
  //         {props.actions}
  //       </ListItemSecondaryAction>
  //     )}
  //   </ListItem>
  // );
};