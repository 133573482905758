import { authAxiosRequest } from 'api/axios';
import { Event, EventDate, Order, Product, ProductStockUpdateLogTransferTypeEnum, ProductStockUpdateLogTypeEnum, Sale, Storefront, Team } from 'api/resources';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';

type UpdateLogStock = {
  team: PickFromResource<Team, 'name' | 'color'>;
  product: PickFromResource<Product, 'name'>;
};

export type GetProductsStockUpdateLogsResponse = {
  data: {
    _id: string;
    stock: UpdateLogStock;
    user: string;
    createdAt: string;
    previousQuantity: number;
    newQuantity: number;
    type: ProductStockUpdateLogTypeEnum;

    transferType?: ProductStockUpdateLogTransferTypeEnum;
    transferStock?: UpdateLogStock;
    sale?: ResourceWithPopulated<Sale, {
      order: ResourceWithPopulated<PickFromResource<Order, 'type' | 'event' | 'eventDate' | 'storefront'>, {
        eventDate?: PickFromResource<EventDate, 'dateAsUtc'>;
        event?: PickFromResource<Event, 'name'>;
        storefront?: PickFromResource<Storefront, 'name'>;
      }>;
    }>;
    event?: PickFromResource<Event, 'name'>;
  }[];
};

export type GetProductsStockUpdateLogsInput = {
  startDate?: string;
  endDate?: string;
  limit?: number;
  productId?: string;
  teamId?: string;
  batchId?: string;
};

export const getProductsStockUpdateLogs = (input: GetProductsStockUpdateLogsInput = {}) => {
  const { productId, batchId, teamId, ...params } = input;

  if (productId) {
    return authAxiosRequest<GetProductsStockUpdateLogsResponse>(`/api/products/${productId}/stock-update-logs`, { params });
  }

  if (teamId) {
    return authAxiosRequest<GetProductsStockUpdateLogsResponse>(`/api/teams/${teamId}/stock-update-logs`, { params });
  }

  if (batchId) {
    return authAxiosRequest<GetProductsStockUpdateLogsResponse>(`/api/inventory-batch/${batchId}/stock-update-logs`, { params });
  }

  return authAxiosRequest<GetProductsStockUpdateLogsResponse>('/api/stock-update-logs', { params });
};