import { OrderFulFillmentStatusEnum, OrderPaymentEnum, OrderSaleFulfillmentStatusEnum, OrderSaleFulfillmentTypeEnum } from 'api/resources';
import { EnumHelpers } from './types';

const payment: EnumHelpers<OrderPaymentEnum> = {
  enumValues: [
    OrderPaymentEnum.cash,
    OrderPaymentEnum.card,
    OrderPaymentEnum.split,
    OrderPaymentEnum.online,
  ],
  getLabel: value => {
    switch (value) {
      case OrderPaymentEnum.cash:
        return 'Cash';
      case OrderPaymentEnum.card:
        return 'Card';
      case OrderPaymentEnum.split:
        return 'Split';
      case OrderPaymentEnum.online:
        return 'Online';
      case OrderPaymentEnum.venmo:
        return 'Venmo';
      case OrderPaymentEnum.other:
        return 'Other';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown'
};

export const fulfillmentStatus: EnumHelpers<OrderFulFillmentStatusEnum> = {
  enumValues: [
    OrderFulFillmentStatusEnum.UNFULFILLED,
    OrderFulFillmentStatusEnum.PARTIALLY_FULFILLED,
    OrderFulFillmentStatusEnum.FULFILLED,
  ],
  getLabel: value => {
    switch (value) {
      case OrderFulFillmentStatusEnum.UNFULFILLED:
        return 'Unfulfilled';
      case OrderFulFillmentStatusEnum.PARTIALLY_FULFILLED:
        return 'Partially fulfilled';
      case OrderFulFillmentStatusEnum.FULFILLED:
        return 'Fulfilled';
      default:
        return 'Unknown';
    }
  },
  getColor: value => {
    switch (value) {
      case OrderFulFillmentStatusEnum.UNFULFILLED:
        return 'error';
      case OrderFulFillmentStatusEnum.PARTIALLY_FULFILLED:
        return 'warning';
      case OrderFulFillmentStatusEnum.FULFILLED:
        return 'success';
      default:
        return 'unknown';
    }
  },
};

export const saleFulfillmentStatus: EnumHelpers<OrderSaleFulfillmentStatusEnum> = {
  enumValues: [
    OrderSaleFulfillmentStatusEnum.UNFULFILLED,
    OrderSaleFulfillmentStatusEnum.FULFILLED,
  ],
  getLabel: value => {
    switch (value) {
      case OrderSaleFulfillmentStatusEnum.UNFULFILLED:
        return 'Unfulfilled';
      case OrderSaleFulfillmentStatusEnum.FULFILLED:
        return 'Fulfilled';
      default:
        return 'Unknown';
    }
  },
  getColor: value => {
    switch (value) {
      case OrderSaleFulfillmentStatusEnum.UNFULFILLED:
        return 'error';
      case OrderSaleFulfillmentStatusEnum.FULFILLED:
        return 'success';
      default:
        return 'unknown';
    }
  },
};

export const saleFulfillmentType: EnumHelpers<OrderSaleFulfillmentTypeEnum> = {
  enumValues: [
    OrderSaleFulfillmentTypeEnum.SHIPMENT,
    OrderSaleFulfillmentTypeEnum.IN_PERSON,
  ],
  getLabel: value => {
    switch (value) {
      case OrderSaleFulfillmentTypeEnum.SHIPMENT:
        return 'Shipment';
      case OrderSaleFulfillmentTypeEnum.IN_PERSON:
        return 'In person';
      default:
        return 'Unknown';
    }
  },
  getColor: value => {
    switch (value) {
      case OrderSaleFulfillmentTypeEnum.SHIPMENT:
        return 'primary';
      case OrderSaleFulfillmentTypeEnum.IN_PERSON:
        return 'unknown';
      default:
        return 'unknown';
    }
  },
};

export const orderEnumHelpers = {
  payment,
  fulfillmentStatus,
  saleFulfillmentStatus,
  saleFulfillmentType
};