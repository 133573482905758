import { EventParticipationStatusEnum } from 'api/resources';
import { ParticipationStatusChip } from 'components';

export type ParticipationStatusCellProps = {
  value?: EventParticipationStatusEnum;
};

export const ParticipationStatusCell: React.FC<ParticipationStatusCellProps> = ({ value }) => {

  if (!value) {
    return null;
  }

  return <ParticipationStatusChip size="small" value={value} />;
};