import { GetEventsResponse } from 'api/actions';
import { EventAcceptanceStatusEnum, EventApplicationStatusEnum, EventParticipationStatusEnum } from 'api/resources';
import { DateService } from 'services';
import { EventDatesStatusEnum, EventStaffStatusEnum, UnknownEnum, YesNoEnum } from 'types';
import { EventsManagementTreeViewNodeIdEnum, EventsManagementTreeViewTerminalNodeId } from '../helpers';
import { FilterFieldConfiguration, eventAcceptanceStatusFilterCofiguration, eventApplicationDeadlineDateFilterCofiguration, eventApplicationOpenDateFilterCofiguration, eventApplicationStatusFilterCofiguration, eventDatesStatusFilterCofiguration, eventFutureInterestStatusFilterCofiguration, eventInsuranceRequiredFilterCofiguration, eventInsuranceSharedFilterCofiguration, eventInsuranceStatusFilterCofiguration, eventLodgingRequiredFilterCofiguration, eventMissingFieldFilterConfiguration, eventParticipationStatusFilterCofiguration, eventStaffStatusFilterCofiguration, eventTeamLeadsFilterConfiguration, eventTeamsFilterCofiguration, eventYearFilterCofiguration } from 'components';
import { getWarningsForEvent } from 'helpers';
import { useContext, useMemo } from 'react';
import { EventsManagementPageContext } from '../EventsManagement.page';
import { EventMissingField } from 'components/Filter/custom/helpers';

export type ApplicationsFilterConfigurations = { [key in EventsManagementTreeViewTerminalNodeId]?: FilterFieldConfiguration<GetEventsResponse['data'][number]>[]  };

export const useEventsManagementFilterConfigurations = () => {
  const { teams: _teams, teamsLoading, users, usersLoading } = useContext(EventsManagementPageContext);

  const applicationsFilterConfigurations = useMemo((): ApplicationsFilterConfigurations => ({
    [EventsManagementTreeViewNodeIdEnum.eventsProSearch]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          EventParticipationStatusEnum.notInterested,
          EventParticipationStatusEnum.canceled,
          EventParticipationStatusEnum.unable,
          UnknownEnum.unknown
        ],
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied,
          EventApplicationStatusEnum.inProccess,
          EventApplicationStatusEnum.needApply,
          EventApplicationStatusEnum.research,
          UnknownEnum.unknown
        ],
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted,
          EventAcceptanceStatusEnum.denied,
          EventAcceptanceStatusEnum.followUp,
          EventAcceptanceStatusEnum.waitListDenied,
          EventAcceptanceStatusEnum.waitListPending,
          UnknownEnum.unknown
        ],
      }),
      eventStaffStatusFilterCofiguration({
        defaultValue: [
          EventStaffStatusEnum.fulfilled,
          EventStaffStatusEnum.pending,
        ]
      }),
      eventDatesStatusFilterCofiguration({
        defaultValue: [
          EventDatesStatusEnum.complete,
          EventDatesStatusEnum.attention,
        ]
      }),
      eventTeamsFilterCofiguration(_teams, teamsLoading),
      eventTeamLeadsFilterConfiguration(users, usersLoading),
      eventYearFilterCofiguration('upcoming')
    ],
    [EventsManagementTreeViewNodeIdEnum.eventsCalendar]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ]
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.research,
          EventApplicationStatusEnum.applied,
          EventApplicationStatusEnum.needApply,
          EventApplicationStatusEnum.inProccess,
          UnknownEnum.unknown,
        ]
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted,
          EventAcceptanceStatusEnum.followUp,
          EventAcceptanceStatusEnum.waitListPending,
          UnknownEnum.unknown
        ]
      }),
      eventStaffStatusFilterCofiguration({
        defaultValue: [
          EventStaffStatusEnum.fulfilled,
          EventStaffStatusEnum.pending,
        ]
      }),
      eventDatesStatusFilterCofiguration({
        defaultValue: [
          EventDatesStatusEnum.complete,
          EventDatesStatusEnum.attention,
        ]
      }),
      eventTeamsFilterCofiguration(_teams, teamsLoading),
      eventTeamLeadsFilterConfiguration(users, usersLoading),
    ],
    [EventsManagementTreeViewNodeIdEnum.eventsDuplicate]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          EventParticipationStatusEnum.unable,
          EventParticipationStatusEnum.canceled,
          UnknownEnum.unknown
        ]
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.research,
          EventApplicationStatusEnum.applied,
          EventApplicationStatusEnum.needApply,
          EventApplicationStatusEnum.inProccess,
          UnknownEnum.unknown,
        ]
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted,
          EventAcceptanceStatusEnum.denied,
          EventAcceptanceStatusEnum.followUp,
          EventAcceptanceStatusEnum.waitListPending,
          EventAcceptanceStatusEnum.waitListDenied,
          UnknownEnum.unknown
        ]
      }),
      eventFutureInterestStatusFilterCofiguration({
        defaultValue: [ UnknownEnum.unknown ]
      }),
      {
        field: 'showDuplicated',
        label: 'Show already duplicated events',
        type: 'checkbox',
        defaultValue: false,
        predicate: (row, value) => {
          if(value) {
            return true;
          }

          const isDuplicated = row.eventCollection.events.some(event => event.year === row.year + 1);

          return !isDuplicated;
        },
      },
      {
        label: 'Time Period',
        field: 'year',
        type: 'year',
        defaultValue: 'last365',
        options: [
          {
            value: 'all',
            label: 'All',
            predicate: () => true,
          },
          {
            value: 'last365',
            label: 'Last 365 days',
            predicate: (row) => {
              if(!row.endDate) {
                return false;
              }
              const diff = DateService.dayjs().diff(DateService.dayjsTz(row.endDate.dateAsUtc), 'day');

              return diff >= 0 && diff <= 365;
            }
          }
        ],
        yearPredicate: (row, value) => row.year === value,
      }
    ],
    [EventsManagementTreeViewNodeIdEnum.eventsMissingDates]: [
      {
        field: 'onlyShowMissingDates',
        label: 'Show only upcoming events scheduled for this year or later that are missing dates.',
        type: 'checkbox',
        defaultValue: true,
        predicate: (row, value) => {
          if(!value) {
            return true;
          }

          return row.year >= DateService.dayjs().year() && !row.dates.length;
        },
        readOnly: true,
      },
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
      }),
      {
        label: 'Years',
        field: 'year',
        type: 'year',
        defaultValue: 'current',
        options: [
          {
            value: 'current',
            label: 'Current',
            predicate: (row) => row.year === DateService.dayjs().year(),
          },
          {
            value: 'all',
            label: 'All',
            predicate: () => true,
          },
        ],
        yearPredicate: (row, value) => row.year === value,
      },
    ],
    [EventsManagementTreeViewNodeIdEnum.applicationsMissingData]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventMissingFieldFilterConfiguration({
        defaultValue: [ EventMissingField.participationStatus, EventMissingField.applicationOpenDate, EventMissingField.applicationDeadlineDate ],
      }),
      eventYearFilterCofiguration('upcoming')
    ],
    [EventsManagementTreeViewNodeIdEnum.applicationsComingSoon]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.inProccess,
          EventApplicationStatusEnum.needApply,
          EventApplicationStatusEnum.research,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationOpenDateFilterCofiguration({ defaultValue: [ 'upcoming', 'notSpecified' ] }),
      eventApplicationDeadlineDateFilterCofiguration({ defaultValue: [ 'upcoming', 'notSpecified' ] }),
      {
        label: 'Dates',
        field: 'year',
        type: 'year',
        defaultValue: 'upcoming',
        options: [
          {
            value: 'all',
            label: 'All',
            predicate: () => true,
          },
          {
            value: 'upcoming',
            label: '2 years from today',
            predicate: (row) => {
              const yearDiff = row.year - DateService.dayjs().year();

              return (!row.endDate || DateService.dayjsTz().isBefore(DateService.dayjsTz(DateService.dayjsTz(row.endDate.dateAsUtc)))) && yearDiff >= 0 && yearDiff < 2;
            }
          }
        ],
        yearPredicate: (row, value) => row.year === value,
      }
    ],
    [EventsManagementTreeViewNodeIdEnum.applicationsOpen]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.inProccess,
          EventApplicationStatusEnum.needApply,
          EventApplicationStatusEnum.research,
          UnknownEnum.unknown
        ]
      }),
      eventApplicationOpenDateFilterCofiguration({
        defaultValue: [ 'past' ],
      }),
      eventApplicationDeadlineDateFilterCofiguration({
        defaultValue: [ 'upcoming', 'notSpecified' ],
      }),
      eventYearFilterCofiguration('upcoming', true)
    ],
    [EventsManagementTreeViewNodeIdEnum.applicationsClosed]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.inProccess,
          EventApplicationStatusEnum.needApply,
          EventApplicationStatusEnum.research,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationDeadlineDateFilterCofiguration({
        defaultValue: [ 'past', 'specified' ],
        strict: true,
      }),
      eventYearFilterCofiguration('upcoming', true),
    ],
    [EventsManagementTreeViewNodeIdEnum.applicationsPending]: [
      eventParticipationStatusFilterCofiguration(
        {
          defaultValue: [
            EventParticipationStatusEnum.interested,
            EventParticipationStatusEnum.participating,
            UnknownEnum.unknown
          ],
          readOnly: true
        }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied,
        ],
        readOnly: true
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.followUp,
          UnknownEnum.unknown,
        ],
        readOnly: true
      }),
      eventYearFilterCofiguration('upcoming', true)
    ],
    [EventsManagementTreeViewNodeIdEnum.applicationsWaitListed]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied
        ],
        readOnly: true
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.waitListPending
        ],
        readOnly: true
      }),
      eventYearFilterCofiguration('upcoming', true),
    ],
    [EventsManagementTreeViewNodeIdEnum.applicationsDenied]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true,
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied
        ],
        readOnly: true
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.denied,
          EventAcceptanceStatusEnum.waitListDenied,
        ],
        readOnly: true
      }),
      eventYearFilterCofiguration('upcoming', true),
    ],
    [EventsManagementTreeViewNodeIdEnum.logisticsMissingData]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true,
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied
        ],
        readOnly: true,
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted
        ],
        readOnly: true,
      }),
      {
        field: 'onlyShowWithWarnings',
        label: 'Show only events that have warnings',
        type: 'checkbox',
        defaultValue: true,
        predicate: (row, value) => {
          if(!value) {
            return true;
          }

          const warningItems = getWarningsForEvent(row).warningItems;

          return !!warningItems.length;
        },
        readOnly: true,
      },
      eventYearFilterCofiguration('upcoming', true),
    ],
    [EventsManagementTreeViewNodeIdEnum.logisticsInsurance]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied,
        ],
        readOnly: true
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted,
        ],
        readOnly: true
      }),
      eventInsuranceRequiredFilterCofiguration({
        defaultValue: [
          YesNoEnum.yes,
          UnknownEnum.unknown
        ]
      }),
      eventInsuranceStatusFilterCofiguration({
        defaultValue: [
          UnknownEnum.unknown,
        ]
      }),
      eventInsuranceSharedFilterCofiguration({
        defaultValue: [
          UnknownEnum.unknown,
        ]
      }),
      eventYearFilterCofiguration('upcoming', true)
    ],
    [EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue]: [
      {
        field: 'onlyShowPaymentsDue',
        label: 'Only show events with payments due',
        type: 'checkbox',
        predicate: (row, value) => {
          if (!value) {
            return true;
          }

          const unpaidPaymentsDue = row.paymentsDue.filter(paymentDue => !paymentDue.isPaidInFull);

          return !!unpaidPaymentsDue.length;
        },
        defaultValue: true,
      },
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied,
          EventApplicationStatusEnum.inProccess,
          EventApplicationStatusEnum.needApply,
          EventApplicationStatusEnum.research,
          UnknownEnum.unknown,
        ],
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted,
          EventAcceptanceStatusEnum.followUp,
          EventAcceptanceStatusEnum.waitListPending,
          UnknownEnum.unknown,
        ],
      }),
      eventYearFilterCofiguration('upcoming'),
    ],
    [EventsManagementTreeViewNodeIdEnum.logisticsLodging]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied,
        ],
        readOnly: true
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted,
        ],
        readOnly: true
      }),
      eventLodgingRequiredFilterCofiguration({
        defaultValue: [
          YesNoEnum.yes,
          UnknownEnum.unknown,
        ],
        readOnly: true
      }),
      eventYearFilterCofiguration('upcoming', true)
    ],
    [EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied
        ],
        readOnly: true
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted,
        ],
        readOnly: true
      }),
      eventStaffStatusFilterCofiguration({
        defaultValue: [
          EventStaffStatusEnum.pending
        ]
      }),
      eventYearFilterCofiguration('upcoming', true)
    ],
    [EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied
        ],
        readOnly: true
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted,
        ],
        readOnly: true
      }),
      eventTeamsFilterCofiguration(_teams, teamsLoading, [ 'notSelected' ]),
      eventYearFilterCofiguration('upcoming', true)
    ],
    [EventsManagementTreeViewNodeIdEnum.logisticsEventDates]: [
      eventParticipationStatusFilterCofiguration({
        defaultValue: [
          EventParticipationStatusEnum.interested,
          EventParticipationStatusEnum.participating,
          UnknownEnum.unknown
        ],
        readOnly: true
      }),
      eventApplicationStatusFilterCofiguration({
        defaultValue: [
          EventApplicationStatusEnum.applied
        ],
        readOnly: true
      }),
      eventAcceptanceStatusFilterCofiguration({
        defaultValue: [
          EventAcceptanceStatusEnum.accepted,
        ],
        readOnly: true
      }),
      eventDatesStatusFilterCofiguration({
        defaultValue: [
          EventDatesStatusEnum.attention,
        ]
      }),
      eventYearFilterCofiguration('upcoming', true)
    ],
  }), [ _teams, teamsLoading, users, usersLoading ]);

  return applicationsFilterConfigurations;
};