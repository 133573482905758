import {  Box, Button, CssBaseline, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import { logoImg } from 'assets';
import { AppBar } from 'components/MainLayout/Appbar.component';
import { useLoggingPortalContext } from '../../LoggingPortal.base';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { KeyboardBackspaceOutlined } from '@mui/icons-material';
import { useLogSaleContext } from '../utils';
import { REACT_APP_ENVIRONMENT } from 'config/environment.config';
import { AreYouSureModal } from 'components';

export type LogSaleLayoutProps = {
  children: React.ReactNode;
  userId?: string;
};

export const LogSaleLayout: React.FC<LogSaleLayoutProps> = props => {
  const loggingPortalContext = useLoggingPortalContext();
  const logSaleContext = useLogSaleContext();
  const navigate = useNavigate();

  // context is undefined when this component is used for loading
  const userOptions = loggingPortalContext?.userOptions;
  const onClearAndExit = logSaleContext?.onClearAndExit;

  const userSelect = useMemo(() => {
    if (props.userId && userOptions) {
      return (
        <Box display="flex" alignItems="center" gap={1} color="white">
          <Typography variant="subtitle1" display={{ xs: 'none', md: 'initial' }}>Logging as: </Typography>
          <Select
            size="small"
            value={props.userId}
            onChange={(e) => navigate(`?user=${e.target.value}`)}
            sx={{ background: theme => theme.palette.background.paper, width: { xs: '100px', sm: '150px' } }}
          >
            {userOptions.map((user) => {

              return <MenuItem key={user._id} value={user._id}>{user.name}</MenuItem>;
            })}
          </Select>
        </Box>
      );
    }

    return null;
  }, [ props.userId, userOptions, navigate ]);

  const [ exitAreYouSureModalOpen, setExitAreYouSureModalOpen ] = useState(false);

  const exitAreYouSureModal = useMemo(() => {
    if (!exitAreYouSureModalOpen) {
      return null;
    }

    return (
      <AreYouSureModal
        topLeftIcon="close"
        onConfirm={onClearAndExit}
        description="You are about to exit without saving any changes."
        onClose={() => setExitAreYouSureModalOpen(false)}
      />
    );
  }, [ exitAreYouSureModalOpen, onClearAndExit ]);

  const onExit = () => {
    if (logSaleContext.state.saleProducts.length > 0) {
      setExitAreYouSureModalOpen(true);
    } else {
      onClearAndExit();
    }
  };

  return (
    <Box display="flex" height="100%">
      <CssBaseline />
      {exitAreYouSureModal}
      <AppBar variant="outlined" color="primary">
        <Toolbar variant="dense">
          <Box flex={1} display="flex" alignItems="center" gap={1}>
            <Box display="flex" gap={1} alignItems="center">
              {!!onClearAndExit && <Button color="inherit" startIcon={<KeyboardBackspaceOutlined />} variant="outlined" onClick={onExit}>Exit</Button>}
              {REACT_APP_ENVIRONMENT === 'staging' && <Typography color="primary.contrastText" fontWeight={700} variant="h5">STAGING ENVIRONMENT</Typography>}
            </Box>
          </Box>
          <Box justifyContent="center" gap={1} display="flex">
            <Box
              component="img"
              sx={{ height: (theme) => theme.spacing(4), display: { xs: 'none', sm: 'flex' } }}
              src={logoImg}
            />
            <Typography variant="h5" fontWeight={500}>Log Sale</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" flex={1}>
            {userSelect}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={() => {
          return {
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'hidden',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
          };
        }}
      >
        <Toolbar variant="dense" />
        <Box flexGrow={1} display="flex" flexDirection="column">
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};

// <Box flexGrow={1} display="flex" flexDirection="column">
// <Box pt={2} px={2}>
//   <Box
//     bgcolor={theme => theme.palette.background.paper}
//     py={1}
//     px={3}
//     mx="auto"
//     borderRadius={theme => theme.shape.borderRadius}
//     border={theme => `1px solid ${theme.palette.divider}`}
//     display="flex"
//     alignItems="center"
//     flexDirection={{ xs: 'column', sm: 'row' }}
//     columnGap={1}
//     width={{ xs: '100%', sm: 'fit-content' }}
//   >
//     <Typography component="span" fontWeight={500}>{event.name}&nbsp;</Typography>
//     <Typography component="span" display={{ xs: 'none', sm: 'initial' }}>•</Typography>
//     <Typography component="span" fontWeight={500}>{DateService.dayjs(eventDate.date).format('dddd, MMM Do, YYYY')}</Typography>
//     <Typography component="span" display={{ xs: 'none', sm: 'initial' }}>•</Typography>
//     <Typography component="span" color={event.team ? 'primary' : 'warning.main'}>{event.team?.name ?? 'Team Unassigned'}</Typography>
//   </Box>
// </Box>
// <Container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} maxWidth="md">
//   <Box flex={1} mt={{ xs: 1, sm: 2, md: 4 }}>
//     {props.children}
//   </Box>
// </Container>
// </Box>