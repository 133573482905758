import { Box } from '@mui/material';
import { missingImg } from 'assets';
import { PageContentContainer, PageContentResourceHeader, SectionCardsEditContextProvider } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useSaleOutletContext } from './Sale.base';
import { OverviewSection } from './sections';
import { getSaleDateAndPointContent } from 'helpers';

export const Sale: React.FC = () => {
  const { sale } = useSaleOutletContext();

  return (
    <PageContentContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.salesLog, text: 'Sales' } ],
        current: 'Sale Page',
      }}
      header={(
        <PageContentResourceHeader
          title={sale.product.name}
          subHeaderContent={getSaleDateAndPointContent(sale)}
          pageTitle="Sale Page"
          imageSrc={sale.product.imageUrl || missingImg}
        />
      )}
    >
      <SectionCardsEditContextProvider>
        <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5}>
          <OverviewSection />
        </Box>
      </SectionCardsEditContextProvider>
    </PageContentContainer>
  );
};
