import { StrictForm, YesNoEnum } from 'types';

export const enum TeamPageSection {
  OVERVIEW = 'overview',
  EVENTS = 'events',
  INVENTORY = 'inventory',
  SALES = 'sales',
}

export const enum TeamPageSectionRowId {
  COLOR = 'color',
  VEHICLE = 'vehicle',
  TEAM_LEAD = 'user',
  PAUSE_INVENTORY = 'pause_inventory',
}

export type TeamSectionRowForm = {
  [TeamPageSectionRowId.COLOR]: StrictForm<{
    color: string;
  }>;
  [TeamPageSectionRowId.VEHICLE]: StrictForm<{
    vehicle: string;
  }>;
  [TeamPageSectionRowId.TEAM_LEAD]: StrictForm<{
    teamLead: string;
  }>;
  [TeamPageSectionRowId.PAUSE_INVENTORY]: StrictForm<{
    pauseInventory: YesNoEnum;
  }>;
};