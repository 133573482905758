import { Box, Button, Divider, Typography } from '@mui/material';
import { useEventsForDashboard } from 'queries';
import React, { useMemo } from 'react';
import { DashboardPageEventsOverviewSection, DashboardPageSection, dashboardPageEventsOverviewSectionEnumHelpers } from '../types';
import { GetEventsForDashboardResponse, getEvent } from 'api/actions';
import { EventsOverviewUpcomingEventsCard } from './EventsOverviewUpcomingEventsCard.component';
import { EventsOverviewFeedbackReminderCard } from './EventsOverviewFeedbackReminderCard.component';
import { EventsOverviewStaffStatusCard } from './EventsOverviewStaffStatusCard.comonent';
import { EventDateStaffStatusEnum } from 'api/resources';
import { EventPreviewContainer, JumpNav, StickyStackItem } from 'components';
import { EventsOverviewCalendar } from './EventsOverviewCalendar.component';
import { useCurrentUser } from 'contexts';
import { QUERY_KEY } from 'queries/query-keys';

const eventsPlaceholder: GetEventsForDashboardResponse['data'] = {
  feedbackReminder: [],
  upcomingAttendEvents :[],
  requestedEvents: [],
  selfRequestedEvents: [],
  calendarEvents: [],
};

export const EVENTS_OVERVIEW_CARD_HEIGHT = 522;
export const EVENTS_OVERVIEW_CARD_WIDTH = 450;

export const EventsOverview = () => {
  const currentUser = useCurrentUser();
  const { data: events = eventsPlaceholder, isInitialLoading: eventsLoading } = useEventsForDashboard();

  const tabs = useMemo(() => {
    const getBadgeContent = (sectionId: DashboardPageEventsOverviewSection) => {
      switch (sectionId) {
        case DashboardPageEventsOverviewSection.MY_EVENTS:
          return events.upcomingAttendEvents.length + events.feedbackReminder.length;
        case DashboardPageEventsOverviewSection.PENDING_REQUESTS:
          return events.requestedEvents.reduce((r, { statusDates }) => r + statusDates.length, 0) + events.selfRequestedEvents.reduce((r, { statusDates }) => r + statusDates.length, 0);
        case DashboardPageEventsOverviewSection.CALENDAR:
        default:
          return undefined;
      }
    };

    return dashboardPageEventsOverviewSectionEnumHelpers.enumValues.map(sectionId => ({
      label: dashboardPageEventsOverviewSectionEnumHelpers.getLabel(sectionId),
      sectionId,
      badgeContent: getBadgeContent(sectionId),
    }));
  }, [ events.feedbackReminder.length, events.requestedEvents, events.selfRequestedEvents, events.upcomingAttendEvents.length ]);

  return (
    <Box id={DashboardPageSection.EVENTS}>
      <EventPreviewContainer
        events={events.calendarEvents}
        loading={eventsLoading}
        userId={currentUser._id}
        invalidateQueriesHandler={async (queryClient, eventId) => {
          if (!eventId) {
            return;
          }

          await queryClient.invalidateQueries(QUERY_KEY.EVENT(eventId));

          const event = await queryClient.fetchQuery(QUERY_KEY.EVENT(eventId), async () => {
            const response = await getEvent(eventId);

            return response.data;
          });

          queryClient.setQueryData<GetEventsForDashboardResponse['data']>(QUERY_KEY.EVENTS_FOR_DASHBOARD, (events) => {
            if (!events) {
              return undefined;
            }

            return Object.keys(events).reduce((r, key: keyof GetEventsForDashboardResponse['data']) => {
              return {
                ...r,
                [key]: events[key].map(_event => _event._id === eventId ? { ..._event, ...event } : _event),
              };
            }, eventsPlaceholder);
          });
        }}
      >
        <StickyStackItem placement="top" order={1}>
          <Box zIndex={2}>
            <Box display="flex" justifyContent="space-between" bgcolor={theme => theme.palette.background.default}>
              <Typography variant="h6" my={1}>Events</Typography>
              <JumpNav
                initialValue={DashboardPageEventsOverviewSection.MY_EVENTS}
                offsetTop={114}
                tabs={tabs}
              />
            </Box>
            <Box mx={-4}><Divider /></Box>
          </Box>
        </StickyStackItem>
        <Box mt={2} display="flex" flexDirection="column" gap={3}>
          <Box id={DashboardPageEventsOverviewSection.MY_EVENTS} maxWidth={1300}>
            <Typography variant="h6" fontWeight={400} mb={1}>My Events</Typography>
            <Box display="flex" gap={2} flexWrap="wrap">
              <EventsOverviewUpcomingEventsCard upcomingAttendEvents={events.upcomingAttendEvents} loading={eventsLoading} />
              <EventsOverviewFeedbackReminderCard feedbackReminder={events.feedbackReminder} loading={eventsLoading} />
            </Box>
          </Box>
          <Box id={DashboardPageEventsOverviewSection.PENDING_REQUESTS} maxWidth={1300}>
            <Typography variant="h6" fontWeight={400} mb={1}>Pending Requests</Typography>
            <Box display="flex" gap={2} flexWrap="wrap">
              <EventsOverviewStaffStatusCard
                title={totalRequests => `Event request${totalRequests === 1 ? '' : 's'} awaiting your decision`}
                events={events.requestedEvents}
                loading={eventsLoading}
                actions={(updateStaffStatus, loading) => (
                  <Box display="flex" gap={0.5} flexWrap="wrap" alignItems="center" justifyContent="center" mt="5px">
                    <Button
                      color="error"
                      variant="outlined"
                      size="small"
                      sx={{ height: 25 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateStaffStatus(EventDateStaffStatusEnum.deniedRequest);
                      }}
                      disabled={loading}
                    >
                      Deny
                    </Button>
                    <Button
                      color="success"
                      variant="contained"
                      size="small"
                      sx={{ height: 25 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateStaffStatus(EventDateStaffStatusEnum.confirmed);
                      }}
                      disabled={loading}
                    >
                      Accept
                    </Button>
                  </Box>
                )}
              />
              <EventsOverviewStaffStatusCard
                title={totalRequests => `Pending self request${totalRequests === 1 ? '' : 's'}`}
                events={events.selfRequestedEvents}
                loading={eventsLoading}
                actions={(updateStaffStatus, loading) => (
                  <Box display="flex" gap={0.5} flexWrap="wrap" alignItems="center" justifyContent="center" mt="5px" minWidth={200}>
                    <Button
                      color="unknown"
                      variant="outlined"
                      size="small"
                      sx={{ height: 25 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateStaffStatus(null);
                      }}
                      disabled={loading}
                    >
                      Withdraw
                    </Button>
                    <Button
                      color="warning"
                      variant="contained"
                      size="small"
                      sx={{ height: 25 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateStaffStatus(EventDateStaffStatusEnum.selfRequested);
                      }}
                      disabled={loading}
                    >
                      Resend request
                    </Button>
                  </Box>
                )}
              />
            </Box>
          </Box>
          <EventsOverviewCalendar events={events.calendarEvents} loading={eventsLoading} />
        </Box>
      </EventPreviewContainer>
    </Box>
  );
};
