import { ProductStockUpdateLogTransferTypeEnum, ProductStockUpdateLogTypeEnum, OrderTypeEnum } from 'api/resources';
import { TStockUpdateLogTableRow } from './StockUpdateLogTable.component';
import { DateService } from 'services';
import { FORMAT_DATE } from 'constants/format-date';

type GetDetailsFunc = (row: TStockUpdateLogTableRow) => React.ReactNode;

export const getStockUpdateLogDetails: GetDetailsFunc = (row) => {
  const updateAmount = Math.abs(row.newQuantity - row.previousQuantity);

  const transferType = row.transferType as ProductStockUpdateLogTransferTypeEnum;
  const transferTeam = row.transferStock?.team?.name as string;
  const transferProduct = row.transferStock?.product?.name as string;

  // event sale
  const saleEventName = row.sale?.order.event?.name as string;
  const saleEventDate = row.sale?.order.eventDate?.dateAsUtc ? DateService.dayjsTz(row.sale.order.eventDate.dateAsUtc).format(FORMAT_DATE.standard) : '';
  const eventName = row?.event?.name as string;
  // storefront sale
  const saleStorefront = row.sale?.order.storefront?.name as string;

  switch(row.type) {
    case ProductStockUpdateLogTypeEnum.set:
      return `Stock set to ${row.newQuantity}`;
    case ProductStockUpdateLogTypeEnum.unary:
      return `Stock incremented by ${updateAmount}`;
    case ProductStockUpdateLogTypeEnum.damaged:
      return `Stock decremented by ${updateAmount} due to damage`;
    case ProductStockUpdateLogTypeEnum.missing:
      return `Stock decremented by ${updateAmount} due to missing`;
    case ProductStockUpdateLogTypeEnum.transfer:
      if (transferType === ProductStockUpdateLogTransferTypeEnum.to) {
        return `Stock incremented by ${updateAmount} due to transfer from ${transferTeam}`;
      } else {
        return `Stock decremented by ${updateAmount} due to transfer to ${transferTeam}`;
      }
    case ProductStockUpdateLogTypeEnum.deleteSale:
      return `Stock incremented by ${updateAmount} due to sale being deleted`;
    case ProductStockUpdateLogTypeEnum.sale:
      if (!row.sale) {
        return '';
      }

      let saleTypeDetail = '';

      if ('event' in row.sale.order) {
        saleTypeDetail = `sale at event (${saleEventName}) on ${saleEventDate}`;
      }

      if ('storefront' in row.sale.order) {
        saleTypeDetail = `storefront sale (${saleStorefront})`;
      }

      if (row.sale.order.type === OrderTypeEnum.online) {
        saleTypeDetail = 'online sale';
      }

      return `Stock decremented by ${updateAmount} due to ${saleTypeDetail}`;
    case ProductStockUpdateLogTypeEnum.saleProductChangeTransfer:
      if (transferType === ProductStockUpdateLogTransferTypeEnum.to) {
        return `Stock incremented by ${updateAmount} due to sale product being updated to ${transferProduct}`;
      } else {
        return `Stock decremented by ${updateAmount} due to sale product being updated to ${transferProduct}`;
      }

    case ProductStockUpdateLogTypeEnum.eventTeamChangeTransfer:
      if (transferType === ProductStockUpdateLogTransferTypeEnum.to) {
        return `Stock incremented by ${updateAmount} due to event (${eventName}) team being updated to ${transferTeam}`;
      } else {
        return `Stock decremented by ${updateAmount} due to event (${eventName}) team being updated to ${transferTeam}`;
      }

    default:
      break;
  }

  return '';
};