import { Box, Divider, IconButton, List, Typography } from '@mui/material';
import { NavigateButtons } from '../components/NavigateButtons.component';
import { ProductFinder } from '../components/ProductFinder.component';
import { LogSaleStepEnum, listDividersSx, useLogSaleContext } from '../utils';
import { DeleteOutlined, ProductionQuantityLimitsOutlined } from '@mui/icons-material';
import { SaleProductListItem } from 'components';

export const ProductsStep: React.FC = () => {
  const { onAddProduct, onRemoveProduct, onSetStep, saleProductsTransformed } = useLogSaleContext();

  const noProducts = !saleProductsTransformed.length;

  return (
    <Box flex={1} display="flex" flexDirection={{ xs: 'column-reverse', sm: 'row' }}>
      <NavigateButtons
        onNext={() => onSetStep(LogSaleStepEnum.discount)}
        nextDisabled={noProducts}
      />
      <Box flex={1} display="flex" flexDirection="column" minWidth={{ xs: 'unset', sm: '400px' }}>
        <ProductFinder
          onProductSelect={(product, dropShippingOnly) => {
            return onAddProduct(
              product._id,
              product.shopifyProductId ?? 0,
              dropShippingOnly,
              product.imageUrl,
            );
          }}
        />
      </Box>
      <Box
        width={{ xs: '100%', sm: '400px' }}
        borderLeft={theme => `1px solid ${theme.palette.divider}`}
        bgcolor={theme => theme.palette.background.paper}
      >
        <List disablePadding sx={listDividersSx({ divideBottom: true })}>
          {saleProductsTransformed.map(({ product, id, imageSrc }) => {
            return (
              <SaleProductListItem
                key={id}
                name={product.name}
                imageUrl={imageSrc}
                actions={(
                  <IconButton onClick={() => onRemoveProduct(id)}>
                    <DeleteOutlined color="error" />
                  </IconButton>
                )}
              />
            );
          })}
          {noProducts && (
            <Box>
              <Box display="flex" justifyContent="center" py={6} flexDirection="column" alignItems="center" gap={2}>
                <ProductionQuantityLimitsOutlined fontSize="large" />
                <Typography fontWeight={500} color="text.secondary" ml={1}>No products added</Typography>
              </Box>
              <Divider sx={{ display: { xs: undefined, sm: 'none' } }} />
            </Box>
          )}
        </List>
      </Box>
    </Box>
  );
};