import { Box, Typography, Select, SelectChangeEvent, MenuItem, Checkbox, SxProps, Theme } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { GetTeamsResponse, GetInventoryBatchesResponse } from 'api/actions';
import { InventoryBatchStatusEnum } from 'api/resources';
import { InventoryBatchStatusChip } from 'components/Chips';
import { TeamCell, LoggedAtDateCell, InventoryBatchStatusCell, ActiveForCell } from 'components/Table/Cells';
import { Table } from 'components/Table/Table.component';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { inventoryBatchEnumHelpers } from 'helpers';
import { useLocalStorage } from 'hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type InventoryBatchesTableProps = {
  teams: GetTeamsResponse['data'];
  inventoryBatches: GetInventoryBatchesResponse['data'];
  loading?: boolean;
  sx?: SxProps<Theme>;
};

type InventoryBatchRow = GetInventoryBatchesResponse['data'][number];

const getColumns = (): GridColDef<InventoryBatchRow>[] => {
  return [
    {
      field: 'name',
      headerName: 'Batch ID',
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      renderCell: ({ row }) => {
        const type = row.type;
        const label = inventoryBatchEnumHelpers.type.getLabel(type);
        const Icon = inventoryBatchEnumHelpers.type.getIcon(type);

        return (
          <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
            <Icon fontSize="small" />
            <Typography variant="body2">{label}</Typography>
          </Box>
        );
      }
    },
    {
      field: 'team',
      headerName: 'Team',
      valueGetter: ({ row }) => row.team.name,
      renderCell: ({ row }) => <TeamCell team={row.team} />,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: InventoryBatchStatusCell,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'user',
      headerName: 'User',
      valueGetter: ({ row }) => row.user.name,
    },
    {
      field: 'reviewUser',
      headerName: 'Reviewer',
      valueGetter: ({ row }) => row.reviewUser?.name,
    },
    {
      field: 'updates',
      headerName: 'Updates',
      valueGetter: ({ row }) => row.updates.length,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'items',
      headerName: 'Items',
      valueGetter: ({ row }) => row.updates.reduce((r, update) => r + update.quantity, 0),
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      renderCell: LoggedAtDateCell,
      width: 200,
    },
    {
      field: 'activeFor',
      headerName: 'Active For',
      valueGetter: ({ row }) => {
        if (row.status === InventoryBatchStatusEnum.canceled) {
          return { date: row.updatedAt, sinceDate: row.createdAt };
        }

        return { date: row.stockUpdatedAt, sinceDate: row.createdAt };
      },
      renderCell: ActiveForCell,
      width: 120,
    },
    {
      field: 'stockUpdatedAt',
      headerName: 'Closed At',
      renderCell: LoggedAtDateCell,
      width: 200,
    },
  ];
};

export const InventoryBatchesTable: React.FC<InventoryBatchesTableProps> = ({ inventoryBatches, teams, loading, sx }) => {
  const navigate = useNavigate();
  const columns = getColumns();
  const statusFilterLocalStorage = useLocalStorage<InventoryBatchStatusEnum[]>('inventory-batches-status-filter');
  const [ statusFilter, setStatusFilter ] = useState(statusFilterLocalStorage.getResource([ InventoryBatchStatusEnum.open, InventoryBatchStatusEnum.review ]));
  const [ teamFilter, setTeamFilter ] = useState<string>('all');

  const filteredInventoryBatches = inventoryBatches.filter((inventoryBatch) => {
    const hasTeam = teamFilter === 'all' || inventoryBatch.team._id === teamFilter;

    return hasTeam && statusFilter.includes(inventoryBatch.status);
  });

  return (
    <Table
      pagination
      initialState={{ pagination: { paginationModel: { pageSize: 5, page: 0 } } }}
      pageSizeOptions={[ 5, 10, 25 ]}
      loading={loading}
      rows={filteredInventoryBatches}
      columns={columns}
      getRowId={row => row._id}
      onRowClick={({ row }: GridRowParams<InventoryBatchRow>) => navigate(`/${ROUTING_CONFIG.inventoryBatches}/${row._id}`)}

      slotProps={{
        toolbar: {
          searchOnly: true,
          actions: (
            <Box display="flex" gap={2}>
              <Select
                value={teamFilter}
                size="small"
                sx={{ width: '120px' }}
                onChange={(e: SelectChangeEvent<string>) => setTeamFilter(e.target.value)}
              >
                {teams.map((team) => <MenuItem key={team._id} value={team._id}>{team.name}</MenuItem>)}
                <MenuItem value="all">All Teams</MenuItem>
              </Select>
              <Select
                autoComplete="off"
                value={statusFilter}
                multiple
                size="small"
                sx={{ minWidth: '120px' }}
                MenuProps={{
                  anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                }}
                onChange={(e: SelectChangeEvent<InventoryBatchStatusEnum[]>) => {
                  const value = e.target.value;

                  if (typeof value === 'string') {
                    return;
                  }

                  statusFilterLocalStorage.setResource(value);
                  setStatusFilter(value);
                }}
                renderValue={(statuses: InventoryBatchStatusEnum[]) => <Box display="flex" gap={0.5}>{statuses.map(status => <InventoryBatchStatusChip key={status} value={status} />)}</Box>}
              >
                {inventoryBatchEnumHelpers.status.enumValues.map((status) => (
                  <MenuItem key={status} value={status} sx={{ pl: 0.5, '&.Mui-selected':{ background: 'inherit' } }}>
                    <Checkbox checked={statusFilter.includes(status)} />
                    <InventoryBatchStatusChip value={status} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )
        }
      }}
      sx={{
        ...sx,
        '& .MuiDataGrid-row': {
          cursor: 'pointer'
        }
      }}
      emptyMessage="No batches found"
    />
  );
};