import { useQuery } from 'hooks';
import { LogSaleLayout } from './components/LogSaleLayout.component';
import { LogSaleContextProvider, LogSaleStepEnum, getLoggingPortalTypeRoute, useLogSaleContext } from './utils';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { Navigate } from 'react-router-dom';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { ProductsStep } from './steps/ProductsStep.component';
import { DiscountAndShippingStep } from './steps/DiscountAndShippingStep.component';
import { PaymentStep } from './steps/PaymentStep.compoent';
import { ValidateUser } from './steps/ValidateUser.component';

export type LogSaleRawProps = {
  userId: string;
};

export const LogSaleRaw: React.FC<LogSaleRawProps> = props => {
  const { state } = useLogSaleContext();

  if (state.validatedUser?._id !== props.userId) {
    return <ValidateUser userId={props.userId} />;
  }

  const stepHash: Record<LogSaleStepEnum, React.ReactNode> = {
    [LogSaleStepEnum.products]: <ProductsStep />,
    [LogSaleStepEnum.discount]: <DiscountAndShippingStep />,
    [LogSaleStepEnum.payment]: <PaymentStep userId={props.userId} />,
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{stepHash[state.step]}</>;
};

export const LogSale: React.FC = () => {
  const { userOptions, resourceType, resourceId } = useLoggingPortalContext();
  const query = useQuery();

  const userId = query.get('user');

  if (!userId || !userOptions.find(user => user._id === userId)) {
    return <Navigate to={`/${ROUTING_CONFIG.loggingPortal}/${getLoggingPortalTypeRoute(resourceType)}/${resourceId}`} replace />;
  }

  return (
    <LogSaleContextProvider>
      <LogSaleLayout userId={userId}>
        <LogSaleRaw userId={userId} />
      </LogSaleLayout>
    </LogSaleContextProvider>
  );
};