import { GetOrderResponse } from 'api/actions';
import { Loading } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useOrder } from 'queries';
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';

type UseOrderOutletContext = {
  order: GetOrderResponse['data'];
  loading: boolean;
};

export const useOrderOutletContext = () => useOutletContext<UseOrderOutletContext>();

const OrderBaseRaw: React.FC<{orderId: string}> = ({ orderId }) => {
  const { data: order, isInitialLoading: orderLoading } = useOrder(orderId, { retry: false });

  if (orderLoading) {
    return <Loading />;
  }

  if (!order) {
    return <Navigate to={`/${ROUTING_CONFIG.orders}`} replace />;
  }

  return <Outlet context={{ order, loading: orderLoading }} />;
};

export const OrderBase = () => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={`/${ROUTING_CONFIG.orders}`} replace />;
  }

  return <OrderBaseRaw orderId={id} />;
};
