import { GetEventSalesAnalyticsDataResponse } from 'api/actions';
import { AnalyticsTimelineChartConfig, AnalyticsTimelineChart, useEventContext } from 'components';
import findLast from 'lodash/findLast';
import { DateService, currencyFormatter } from 'services';
import { EventSalesChartProps } from './types';
import { EventDateTypeEnum } from 'api/resources';

export const EventsSalesYearsTimelineChart: React.FC<EventSalesChartProps> = ({ data: { events, sales }, isLoading }) => {
  const { event } = useEventContext();

  const otherYearsEvents = events.filter(_event => _event._id !== event._id);
  const eventSalesStart = event.dates.find(date => date.type === EventDateTypeEnum.regular)?.dateAsUtc;
  const eventSalesEnd = findLast(event.dates, date => date.type === EventDateTypeEnum.regular)?.dateAsUtc;
  const eventDateRange = { start: DateService.dayjsTz(eventSalesStart), end: DateService.dayjsTz(eventSalesEnd) };
  const eventSalesRevenue = currencyFormatter.format(sales.filter(sale => sale.order.event?._id === event._id).reduce((r, sale) => sale.salePrice + r, 0));
  const config: AnalyticsTimelineChartConfig<GetEventSalesAnalyticsDataResponse['data']['sales'][number]> = {
    baseConfig: {
      data: sales.filter(sale => sale.order.event?._id === event._id),
      dateRange: eventDateRange,
      getValue: () => 1,
      getDate: (sale) => DateService.dayjs(sale.saleDate)
    },
    compareConfigs: otherYearsEvents.map(event => ({
      data: sales.filter(sale => sale.order.event?._id === event._id),
      dateRange: { start: DateService.dayjsTz(event.dateRange.startAsUtc), end: DateService.dayjsTz(event.dateRange.endAsUtc) }
    }))
  };

  return (
    <AnalyticsTimelineChart
      header="Years Timeline"
      dateRange={eventDateRange}
      subheader={eventSalesRevenue}
      config={config}
      loading={isLoading}
    />
  );
};