import { Box, Typography } from '@mui/material';
import { useEventContext } from '../event.context';
import { CardContentMarkdown } from 'components/CardContent';

export const EventBoothRequirements = () => {
  const { event: { boothRequirements } } = useEventContext();

  if (!boothRequirements) {
    return null;
  }

  const { hasRequirements, areTentsAllowed, isWhiteTopRequired, isFlameRetardantRequired, maximumHeight, isTrussAllowed, notes } = boothRequirements;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <RequirementText
        value={hasRequirements}
        trueLabel={<Typography>The event <i>has</i> booth requirements</Typography>}
        falseLabel={<Typography>The event <i>does not have</i> booth requirements</Typography>}
      />
      {hasRequirements && (
        <>
          <RequirementText
            value={areTentsAllowed}
            trueLabel={<Typography><b>Tents</b> <i>are allowed</i> at the event.</Typography>}
            falseLabel={<Typography><b>Tents</b> <i>are not allowed</i> at the event.</Typography>}
          />
          {areTentsAllowed && (
            <>
              <RequirementText
                value={isWhiteTopRequired}
                trueLabel={<Typography>A <b>white top</b> <i>is required </i> for the booth.</Typography>}
                falseLabel={<Typography>A <b>white top</b> <i>is not required</i> for the booth.</Typography>}
              />
              <RequirementText
                value={isFlameRetardantRequired}
                trueLabel={<Typography><b>Flame retardant material</b> <i>is required</i>.</Typography>}
                falseLabel={<Typography><b>Flame retardant materia</b> <i>is not required</i>.</Typography>}
              />
            </>
          )}
          {areTentsAllowed === false && (
            <>
              <Typography>
                {maximumHeight !== undefined && (
                  <>
                    The <b>maximum allowed height</b> for structures is <i>{maximumHeight}</i> meters.
                  </>
                )}
              </Typography>
              <RequirementText
                value={isTrussAllowed}
                trueLabel={<Typography><b>Trusses</b> <i>are allowed</i> for booth structures.</Typography>}
                falseLabel={<Typography><b>Trusses</b> <i>are not allowed</i> for booth structures.</Typography>}
              />
            </>
          )}
        </>
      )}
      <CardContentMarkdown markdown={notes} />
    </Box>
  );
};

type RequirementTextProps = {
  value: boolean | undefined;
  trueLabel: React.ReactNode;
  falseLabel: React.ReactNode;
};

const RequirementText: React.FC<RequirementTextProps> = ({ value, trueLabel, falseLabel }) => {
  if (value === true) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{trueLabel}</>;
  }
  if (value === false) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{falseLabel}</>;
  }

  return null;
};
