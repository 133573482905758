import { GetEventsResponse } from 'api/actions';
import { EventsManagementTreeViewTerminalNodeId } from '../helpers';
import { useEventsManagementFilterConfigurations } from './useEventsManagementFilterConfigurations.hook';
import { useFilter } from 'components';

export type UseEventsManagementFilterProps = {
  events: GetEventsResponse['data'];
  nodeId: EventsManagementTreeViewTerminalNodeId;
};

export const useEventsManagementFilter = (props: UseEventsManagementFilterProps) => {
  const filterConfigurations = useEventsManagementFilterConfigurations();
  const filterConfiguration = filterConfigurations[props.nodeId];
  const { modal, setShowModal, filteredRows, filter, setFilter, nonDefaultFilterApplied } = useFilter({
    localStorageId: `${props.nodeId}_filter`,
    rows: props.events,
    filterConfigurations: filterConfiguration ?? [],
  });

  if (filterConfiguration) {
    return {
      filter,
      nonDefaultFilterApplied,
      modal,
      setShowModal,
      filteredRows,
      setFilter
    };
  }

  return {
    filter: {},
    nonDefaultFilterApplied: false,
    modal: null,
    setShowModal: (_arg: boolean) => {},
    filteredRows: props.events,
    setFilter: (_: {}) => {},
  };
};
