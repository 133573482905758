import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getNotFulfilledOrders, getOrder, GetOrderResponse, getOrders, GetOrdersInput } from 'api/actions';

export const useOrders = (input: GetOrdersInput) => {
  return useQuery(QUERY_KEY.ORDERS(input), async () => {
    const response = await getOrders(input);

    return response.data;
  });
};

export const useNotFulfilledOrders = (input: GetOrdersInput) => {
  return useQuery(QUERY_KEY.NOT_FULFILLED_ORDERS(input), async () => {
    const response = await getNotFulfilledOrders(input);

    return response.data;
  });
};

export const useOrder = (orderId: string, opts?: UseQueryOptions<GetOrderResponse['data']>) => {
  return useQuery(QUERY_KEY.ORDER(orderId), async () => {
    const response = await getOrder(orderId);

    return response.data;
  }, opts);
};