import { YesNoEnum, UnknownEnum } from 'types';
import { yesNoEnumHelpers } from './enums/yes-no-enum.helpers';


export const processFormValueUpdate = {
  string(value?: string) {
    if (value === undefined) {
      return undefined;
    }

    if (value.trim() === '') {
      return null;
    }

    return value.trim();
  },
  yesNoUnknown(value?: YesNoEnum | UnknownEnum) {
    if (value === undefined) {
      return undefined;
    }

    return yesNoEnumHelpers.yesNo.getValue(value);
  },
  yesNo(value?: YesNoEnum | null){
    if (value === undefined) {
      return undefined;
    }

    return processFormValue.yesNoOrNull(value);
  },
  number: (value?: number | string) => {
    if (value === undefined) {
      return undefined;
    }

    if (value === 0 || (typeof value === 'string' && value.trim() === '')) {
      return null;
    }

    return Number(value);
  },
  enumWithUnknown<T extends string>(value?: T | UnknownEnum.unknown) {
    if (value === undefined) {
      return undefined;
    }

    if (value === UnknownEnum.unknown) {
      return null;
    }

    return value;
  }
} as const;

export const processFormValue = {
  yesNoOrNull(value: YesNoEnum | null) {
    if (value === null) {
      return false;
    }

    const processedValue = yesNoEnumHelpers.yesNo.getValue(value);

    if (!processedValue) {
      return false;
    }

    return processedValue;
  }
} as const;