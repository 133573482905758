import {
  FormControl, FormControlLabel, FormControlLabelProps, FormHelperText, FormLabel, Radio, RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps, Box,
} from '@mui/material';
import { useField } from 'formik';

export type RadioItemValueType =
    | string
    | {
      label: React.ReactNode;
      value: string | boolean | null;
      disabled?: boolean;
    } & Omit<FormControlLabelProps, 'control'>;

export type RadioGroupProps = MuiRadioGroupProps & {
  name: string;
  label?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  values: RadioItemValueType[];
  helperText?: string | React.ReactNode;
  color?: 'primary' | 'secondary';
  fullWidth?: boolean;
};

type RadioItemProps = {
  value: RadioItemValueType;
  color?: RadioGroupProps['color'];
};

const RadioItem: React.FC<RadioItemProps> = ({ value, color }) => {
  if (typeof value === 'string') {
    return (
      <FormControlLabel
        label={value}
        value={value}
        control={
          <Radio color={color} />
        }
      />
    );
  }

  return (
    <FormControlLabel
      disabled={value.disabled}
      control={
        <Radio color={color} />
      }
      {...value}
    />
  );
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  label,
  Icon,
  values,
  fullWidth = false,
  ...props
}) => {
  const [ field, meta ] = useField(name);

  const hasError = Boolean(meta.error && meta.touched);
  const helperText = (meta.touched && meta.error) || props.helperText;

  return (
    <FormControl
      component="fieldset"
      error={hasError}
      fullWidth={fullWidth}
    >
      <Box display="flex" alignItems="center" gap={1}>
        {Icon && <Icon color="disabled" />}{label && <FormLabel component="legend" color={props.color}>{label}</FormLabel>}
      </Box>
      <MuiRadioGroup
        name={name}
        value={field.value ?? null}
        onChange={field.onChange}
        {...props}
      >
        {values.map((value, i) => {
          // eslint-disable-next-line react/no-array-index-key
          return <RadioItem value={value} key={i} color={props.color} />;
        })}
      </MuiRadioGroup>
      {helperText && (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
