import { GetEventResponse } from 'api/actions';
import { EventDateSetupBreakdownTypeEnum, EventDateStaffStatusEnum, EventDateTypeEnum } from 'api/resources';
import { StrictForm, YesNoEnum } from 'types';

export type EventDateSectionProps = {
  eventDate: GetEventResponse['data']['dates'][number];
};

export const enum EventDatePageSection {
  OVERVIEW = 'overview',
  STAFF = 'staff',
  SALES = 'sales',
  PHOTOS = 'photos',
}

export const enum EventDatePageSectionRowId {
  STAFF_NEEDED = 'staff_needed',
  TYPE = 'date_type',
  LODGING = 'date_lodging',
  NOTES = 'date_notes',
  TIMES = 'times',
  STAFF = 'staff',
}

export type EventDateSectionRowForm = {
  [EventDatePageSectionRowId.TYPE]: StrictForm<{
    type: EventDateTypeEnum;
  }>;
  [EventDatePageSectionRowId.LODGING]: StrictForm<{
    isLodging: YesNoEnum;
  }>;
  [EventDatePageSectionRowId.STAFF_NEEDED]: StrictForm<{
    staffNeeded: number;
    teamLeadNeeded: YesNoEnum | null;
  }>;
  [EventDatePageSectionRowId.TIMES]: StrictForm<{
    confirmedTimes: boolean;
    startTime: string;
    endTime: string;
    setupType: EventDateSetupBreakdownTypeEnum;
    breakdownType: EventDateSetupBreakdownTypeEnum;
    setupEndTime: string;
    arriveAsEarlyAs: string;
    noVehiclesAllowedAfter: string;
    allVehiclesMustBeRemovedBy: string;
    bufferTimeInMinutes: number;
    displaySetupInMinutes: number;
    breakdownInMinutes: number;
  }>;
  [EventDatePageSectionRowId.NOTES]: StrictForm<{
    notes: string;
  }>;
  [EventDatePageSectionRowId.STAFF]: StrictForm<{
    user: string;
    status: EventDateStaffStatusEnum;
    isTeamLead: boolean;
  }>;
};