import { getDistanceInMiles } from 'helpers';
import { EnumHelpers } from 'helpers/enums/types';
import { DateService } from 'services';
import { UseCurrentUserOutput } from 'contexts';
import { GetEventResponse } from 'api/actions';
import { EventPageSection, EventPageSectionRowId } from './types';
import { EventPaymentDue, UserEmployeeRoleEnum } from 'api/resources';

export const getApplicationDateRangeValue = (applicationOpenDate?: string, applicationIsOpen?: boolean, applicationDeadlineDate?: string, applicationOpenUntilFull?: boolean) => {
  if(!applicationIsOpen && !applicationOpenUntilFull) {
    return DateService.getFormattedDateRange(applicationOpenDate, applicationDeadlineDate, false);
  }

  const openLabel = applicationIsOpen ? 'Open' : `Open date is ${applicationOpenDate ? DateService.getFormattedDate(applicationOpenDate, 'MM-D-YY') : 'unassigned'}`;
  const deadlineLabel = applicationOpenUntilFull ? 'is open until full' : `deadline date is ${applicationDeadlineDate ?DateService.getFormattedDate(applicationDeadlineDate, 'MM-D-YY') : 'unassigned'}`;

  return `${openLabel}, ${deadlineLabel}`;
};

export const getTimeLabelFromValue = (value: number | null) => {
  if (!value) return 'Unknown';

  return DateService.dayjs.duration(value * 1000).days() ?
    '> 1 day' :
    DateService.dayjs.duration(value * 1000).format('HH:mm');
};

export const getDistanceLabelFromValue = (value: number | null) => {
  if (!value) return 'Unknown';
  const miles = getDistanceInMiles(value).toFixed(1);

  return `${miles} miles`;
};

export const getFormattedPaymentDue = (paymentDue: EventPaymentDue) => {
  if (paymentDue.dueWithApplication) {
    return 'Due with application';
  } else if (paymentDue.dueAsSoonAsPossible) {
    return 'Due as soon as possible';
  } else {
    return `Due on ${DateService.getFormattedDate(DateService.dayjsTz(paymentDue.dueDateAsUtc))}`;
  }
};

export const eventPageSectionEnumHelpers: EnumHelpers<EventPageSection> & {
  getIsHidden: (value: EventPageSection, event: GetEventResponse['data'], currentUser: UseCurrentUserOutput) => boolean;
} = {
  enumValues: [
    EventPageSection.OVERVIEW,
    EventPageSection.ASSIGNMENTS,
    EventPageSection.DATES,
    EventPageSection.TO_DOS,
    EventPageSection.PHOTOS,
    EventPageSection.SALES,
    EventPageSection.APPLICATION,
    EventPageSection.PAYMENTS,
    EventPageSection.LOCATION,
    EventPageSection.LODGING,
    EventPageSection.INSURANCE,
    EventPageSection.LINKS,
    EventPageSection.FILES,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventPageSection.OVERVIEW:
        return 'Overview';
      case EventPageSection.ASSIGNMENTS:
        return 'Assignments';
      case EventPageSection.TO_DOS:
        return 'To Dos';
      case EventPageSection.DATES:
        return 'Dates / Staff';
      case EventPageSection.PHOTOS:
        return 'Photos';
      case EventPageSection.SALES:
        return 'Sales';
      case EventPageSection.APPLICATION:
        return 'Applications';
      case EventPageSection.LOCATION:
        return 'Location';
      case EventPageSection.LODGING:
        return 'Lodging';
      case EventPageSection.PAYMENTS:
        return 'Payments';
      case EventPageSection.INSURANCE:
        return 'Insurance';
      case EventPageSection.LINKS:
        return 'Links';
      case EventPageSection.FILES:
      default:
        return 'Files';
    }
  },
  getIsHidden: (value, event, currentUser) => {
    if([ EventPageSection.APPLICATION, EventPageSection.TO_DOS, EventPageSection.PAYMENTS, EventPageSection.INSURANCE ].includes(value)) {
      return !currentUser.isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]);
    }

    if (value === EventPageSection.SALES) {
      return !currentUser.isAdmin;
    }

    if (value === EventPageSection.PHOTOS) {
      return !event.dates.filter(eventDate => !DateService.dayjs().isBefore(DateService.dayjsTz(eventDate.dateAsUtc))).length;
    }

    return false;
  },
  getColor: () => 'unknown',
};

export const eventPageSectionRowEnumHelpers: EnumHelpers<EventPageSectionRowId> = {
  enumValues: [
    EventPageSectionRowId.NAME,
    EventPageSectionRowId.EVENT_ORGANIZER,
    EventPageSectionRowId.TEAM,
    EventPageSectionRowId.EVENT_MANAGER,
    EventPageSectionRowId.ATTENDANCE,
    EventPageSectionRowId.SPACE_SIZE,
    EventPageSectionRowId.SPACE_NUMBER,
    EventPageSectionRowId.VENDORS,
    EventPageSectionRowId.INDOOR_OUTDOOR,
    EventPageSectionRowId.ON_WEBSITE,
    EventPageSectionRowId.RAIN_OR_SHINE,
    EventPageSectionRowId.FUTURE_INTEREST_STATUS,
    EventPageSectionRowId.ELECTRICITY,
    EventPageSectionRowId.NOTES,
    EventPageSectionRowId.TO_DO,
    EventPageSectionRowId.PARTICIPATION_STATUS,
    EventPageSectionRowId.APPLICATION_DATE_RANGE,
    EventPageSectionRowId.APPLICATION_STATUS,
    EventPageSectionRowId.APPLICATION_PLATFORM,
    EventPageSectionRowId.ACCEPTANCE_STATUS,
    EventPageSectionRowId.COMMUNICATION_LOG,
    EventPageSectionRowId.ADDRESS,
    EventPageSectionRowId.VEHICLE,
    EventPageSectionRowId.LODGING_REQUIRED,
    EventPageSectionRowId.LODGING_PLACE,
    EventPageSectionRowId.LODGING_NOTES,
    EventPageSectionRowId.PAYMENTS_DUE,
    EventPageSectionRowId.PAYMENTS,
    EventPageSectionRowId.PAYMENT_NOTE,
    EventPageSectionRowId.JURY_FEE,
    EventPageSectionRowId.BOOTH_FEE,
    EventPageSectionRowId.ELECTRICITY_FEE,
    EventPageSectionRowId.INSURANCE,
    EventPageSectionRowId.APPLICATION_FILES,
    EventPageSectionRowId.LODGING_FILES,
    EventPageSectionRowId.INSURANCE_FILES,
    EventPageSectionRowId.OTHER_FILES,
    EventPageSectionRowId.EXTERNAL_LINKS
  ],
  getLabel: (value) => {
    switch (value) {
      case EventPageSectionRowId.NAME:
        return 'Name';
      case EventPageSectionRowId.EVENT_ORGANIZER:
        return 'Event Organizer';
      case EventPageSectionRowId.TEAM:
        return 'Team';
      case EventPageSectionRowId.EVENT_MANAGER:
        return 'Event Manager';
      case EventPageSectionRowId.ATTENDANCE:
        return 'Attendance';
      case EventPageSectionRowId.SPACE_SIZE:
        return 'Space Size';
      case EventPageSectionRowId.SPACE_NUMBER:
        return 'Space Number';
      case EventPageSectionRowId.VENDORS:
        return 'Vendors';
      case EventPageSectionRowId.INDOOR_OUTDOOR:
        return 'Indoor / Outdoor';
      case EventPageSectionRowId.ON_WEBSITE:
        return 'On Website';
      case EventPageSectionRowId.RAIN_OR_SHINE:
        return 'Rain or Shine';
      case EventPageSectionRowId.FUTURE_INTEREST_STATUS:
        return 'Future Interest Status';
      case EventPageSectionRowId.ELECTRICITY:
        return 'Electricity';
      case EventPageSectionRowId.NOTES:
        return 'Notes';
      case EventPageSectionRowId.TO_DO:
        return 'To Do';
      case EventPageSectionRowId.PARTICIPATION_STATUS:
        return 'Participation Status';
      case EventPageSectionRowId.APPLICATION_DATE_RANGE:
        return 'Application Date Range';
      case EventPageSectionRowId.APPLICATION_STATUS:
        return 'Application Status';
      case EventPageSectionRowId.APPLICATION_PLATFORM:
        return 'Application Platform';
      case EventPageSectionRowId.ACCEPTANCE_STATUS:
        return 'Acceptance Status';
      case EventPageSectionRowId.APPLICATION_DENIED_REASON:
        return 'Application Denied Reason';
      case EventPageSectionRowId.COMMUNICATION_LOG:
        return 'Communication Log';
      case EventPageSectionRowId.ADDRESS:
        return 'Address';
      case EventPageSectionRowId.VEHICLE:
        return 'Vehicle';
      case EventPageSectionRowId.LODGING_REQUIRED:
        return 'Lodging Required';
      case EventPageSectionRowId.LODGING_PLACE:
        return 'Lodging Place';
      case EventPageSectionRowId.LODGING_NOTES:
        return 'Lodging Notes';
      case EventPageSectionRowId.PAYMENTS_DUE:
        return 'Payments Due';
      case EventPageSectionRowId.JURY_FEE:
        return 'Jury Fee';
      case EventPageSectionRowId.BOOTH_FEE:
        return 'Booth Fee';
      case EventPageSectionRowId.ELECTRICITY_FEE:
        return 'Electricity Fee';
      case EventPageSectionRowId.PAYMENTS:
        return 'Payments';
      case EventPageSectionRowId.PAYMENT_NOTE:
        return 'Payment Note';
      case EventPageSectionRowId.INSURANCE:
        return 'Insurance';
      case EventPageSectionRowId.APPLICATION_FILES:
        return 'Application Files';
      case EventPageSectionRowId.LODGING_FILES:
        return 'Lodging Files';
      case EventPageSectionRowId.INSURANCE_FILES:
        return 'Insurance Files';
      case EventPageSectionRowId.OTHER_FILES:
        return 'Other Files';
      case EventPageSectionRowId.EXTERNAL_LINKS:
        return 'External Links';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown',
};