import { Box } from '@mui/material';
import { EventElectricityChoiceEnum } from 'api/resources';
import { EventPageSectionRowId, EventSectionRowForm, RadioGroup, RadioGroupYesNoBoolean } from 'components';
import { useFormikContext } from 'formik';
import { eventEnumHelpers } from 'helpers';
import { useEffect, useMemo } from 'react';
import { formSx } from 'styles';
import { UnknownEnum, YesNoEnum } from 'types';

const getElectricityChoiceOptionDisabled = (value: EventElectricityChoiceEnum | UnknownEnum, values: EventSectionRowForm[EventPageSectionRowId.ELECTRICITY]) => {
  if (value === EventElectricityChoiceEnum.generator && values.electricGeneratorAllowed !== YesNoEnum.yes) {
    return true;
  }

  if (value === EventElectricityChoiceEnum.offered && values.electricityAvailable !== YesNoEnum.yes) {
    return true;
  }

  return false;
};

export const ElectricityForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<EventSectionRowForm[EventPageSectionRowId.ELECTRICITY]>();
  const electricityChoiceOptions = useMemo(() => eventEnumHelpers.electricityChoice.enumValues.map(value => ({
    label: eventEnumHelpers.electricityChoice.getLabel(value),
    value,
    disabled: getElectricityChoiceOptionDisabled(value, values),
  })), [ values ]);

  useEffect(() => {
    if (getElectricityChoiceOptionDisabled(values.electricityChoice, values)) {
      setFieldValue('electricityChoice', UnknownEnum.unknown);
    }
  }, [ setFieldValue, values ]);

  return (
    <Box sx={formSx.formGroup}>
      <RadioGroupYesNoBoolean
        name="electricityWanted"
        label="Is electricity wanted?"
        includeUnknown
        row
      />
      {values.electricityWanted === YesNoEnum.yes && (
        <>
          <RadioGroupYesNoBoolean
            name="electricityAvailable"
            label="Is electricity available at a show?"
            includeUnknown
            row
          />
          {values.electricityAvailable === YesNoEnum.yes && (
            <RadioGroupYesNoBoolean
              name="electricityFeeRequired"
              label="Is there an electricity fee?"
              includeUnknown
              row
            />
          )}
          <RadioGroupYesNoBoolean
            name="electricGeneratorAllowed"
            label="Is electric generator allowed?"
            includeUnknown
            row
          />
          <RadioGroup
            values={electricityChoiceOptions}
            name="electricityChoice"
            label="Electricity Choice"
            row
          />
        </>
      )}
    </Box>
  );
};