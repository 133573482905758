import { FrameEnum, StrictForm } from 'types';

export const enum SalePageSectionRowId {
  PRODUCT = 'product',
  SALE_PRICE = 'sale_price',
  FRAME = 'frame',
}

export type SaleSectionRowForm = {
  [SalePageSectionRowId.PRODUCT]: StrictForm<{
    product: string;
  }>;
  [SalePageSectionRowId.SALE_PRICE]: StrictForm<{
    salePrice: number;
  }>;
  [SalePageSectionRowId.FRAME]: StrictForm<{
    frame: FrameEnum;
  }>;
};