import { EventsManagementTable } from 'pages/EventsManagement/components/EventsManagementTable.component';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React from 'react';

export const LogisticsInsurance = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.logisticsInsurance}
      localStorageArgs={{
        initialVisible: [ 'name', 'eventTimeframe', 'acceptanceStatus', 'insuranceRequired', 'insuranceStatus' ],
      }}
      editable={{ insuranceRequired: true, insuranceStatus: true, insuranceShared: true }}
    />
  );
};

