import { Box, Button, Card, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, List, Typography } from '@mui/material';
import { DiscountEnum, LogSaleStepEnum, discountsMeta, getPriceAmountChar, getPriceAmountColor, listDividersSx, useLogSaleContext } from '../utils';
import { NavigateButtons } from '../components/NavigateButtons.component';
import { AddCircleOutline, DeleteOutlined, EditOutlined, RemoveCircleOutline } from '@mui/icons-material';
import { currencyFormatter, currencyFormatterWithoutDecimals } from 'services';
import { TotalsOverview } from '../components/TotalsOverview.component';
import { DiscountChip } from '../components/DiscountChip.component';
import { useState } from 'react';
import { ShipmentInfoModal } from '../components/ShipmentInfo.modal';
import { Formik } from 'formik';
import { number, object } from 'yup';
import { useShopifyProducts } from 'queries/shopify';
import { Loading, SaleProductListItem } from 'components';
import { UserRoleEnum } from 'api/resources';

type ProductsVariantsForm = {
  [id: string]: number;
};

export const DiscountAndShippingStep: React.FC = () => {
  const [ shipmentInfoModalOpen, setShipmentInfoModalOpen ] = useState(false);
  const { state, onUpdateSaleProduct, onUpdateSaleProducts, onSetStep, onRemoveProduct, saleProductsTransformed, onToggleDiscount } = useLogSaleContext();
  const { data: shopifyProducts = [], isInitialLoading: shopifyProductsLoading } = useShopifyProducts(state.saleProducts.map(product => product.shopifyProductId));
  const [ selectVariantProductId, setSelectVariantProductId ] = useState<number | null>(saleProductsTransformed[0]?.id ?? null);

  if (shopifyProductsLoading) {
    return <Loading />;
  }

  const getShopifyProduct = (shopifyProductId: number) => {
    return shopifyProducts.find(shopifyProduct => shopifyProduct.id === shopifyProductId);
  };

  const getVariantImageUrl = (shopifyProductId: number, variantId: number) => {
    return getShopifyProduct(shopifyProductId)?.variants
      .find(variant => variant.id === variantId)?.imageSrc;
  };

  const onSubmit = (values: ProductsVariantsForm) => {
    onUpdateSaleProducts(Object.keys(values).map((id) => {
      const product = saleProductsTransformed.filter(product => product.id === Number(id))[0];
      const variantImageUrl = getVariantImageUrl(product.shopifyProductId, values[id]);

      return {
        id: Number(id),
        updates: {
          variantId: values[id],
          imageSrc: variantImageUrl ?? product.product.imageUrl,
        }
      };
    }));

    const shippmentInfoRequired = saleProductsTransformed.reduce((acc, product) => {
      if (product.shipping) {
        return true;
      }

      return acc;
    }, false);

    if (!shippmentInfoRequired) {
      onSetStep(LogSaleStepEnum.payment);
    } else {
      setShipmentInfoModalOpen(true);
    }
  };

  const initialValues = saleProductsTransformed.reduce((r, saleProduct) => ({
    ...r,
    [saleProduct.id]: saleProduct.variantId,
  }), {});

  const productsVariantsFormValidationSchema = object(saleProductsTransformed.reduce((r, saleProduct) => {
    const isOnShopify = !!getShopifyProduct(saleProduct.shopifyProductId);

    if (!saleProduct.shipping ||!isOnShopify) {
      return r;
    }

    return {
      ...r,
      [saleProduct.id]: number().required(`Background variant for "${saleProduct.product.name}" is required`),
    };
  }, {}));

  return (
    <Container sx={{ flex: 1, pt: { xs: 3, lg: 4 }, pb: 10 }} maxWidth="md">
      {shipmentInfoModalOpen && <ShipmentInfoModal onClose={() => setShipmentInfoModalOpen(false)} />}
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={productsVariantsFormValidationSchema}
      >
        {formik => {
          return (
            <>
              <NavigateButtons
                onNext={() => formik.handleSubmit()}
                onBack={() => onSetStep(LogSaleStepEnum.products)}
              />
              <Box display="flex" flexDirection="column" gap={1} mx={{ xs: -2, sm: 0 }}>
                <Card variant="outlined">
                  <List disablePadding sx={listDividersSx()}>
                    {saleProductsTransformed.map(({ product, frame, colorVinyl, id, discount, globalDiscount, total, shipping, dropShippingOnly }, index) => {
                      const discountDisplay = `${getPriceAmountChar(discount)}${currencyFormatterWithoutDecimals.format(Math.abs(discount))}`;
                      const variants = product.shopifyProductId ? getShopifyProduct(product.shopifyProductId)?.variants : undefined;
                      const imageSrc = getVariantImageUrl(product.shopifyProductId ?? 0, formik.values[id]) ?? product.imageUrl;
                      const variantSelectionDisabled = !shipping;

                      const isSelectVariant = formik.errors[id] || selectVariantProductId === id;
                      const selectVariantProps = (!variantSelectionDisabled && variants) ? {
                        variants,
                        selectedVariant: formik.values[id],
                        onSetVariant: (variantId: number) => {
                          formik.setFieldValue(id.toString(), variantId, !!formik.errors[id.toString()]);
                          const nextId = saleProductsTransformed
                            .slice(index + 1)
                            .find(product => {
                              return product.shopifyProductId && getShopifyProduct(product.shopifyProductId) && product.shipping;
                            })?.id ?? null;

                          if (nextId && !formik.values[nextId]) {
                            setSelectVariantProductId(nextId);
                          } else {
                            setSelectVariantProductId(null);
                          }
                        },
                        variantError: formik.errors[id],
                      } : undefined;

                      return (
                        <SaleProductListItem
                          key={id}
                          wrapActionsOnMobile
                          isProductImagesDisplay={!formik.values[id]}
                          {...(isSelectVariant ? selectVariantProps : {})}
                          name={product.name}
                          imageUrl={imageSrc}
                          otherAction={(
                            <IconButton onClick={() => onRemoveProduct(id)}>
                              <DeleteOutlined color="error" />
                            </IconButton>
                          )}
                          actions={(
                            <Box display="flex" gap={1} alignItems="center" justifyContent="space-between" flex={1}>
                              <FormControlLabel
                                control={<Checkbox checked={frame} onChange={() => onUpdateSaleProduct(id, { frame: !frame })} />}
                                label={(
                                  <Typography>
                                    Frame&nbsp;
                                    <Typography component="span" color="text.secondary" variant="body2">($5)</Typography>
                                  </Typography>
                                )}
                              />
                              <FormControlLabel
                                control={<Checkbox checked={colorVinyl} onChange={() => onUpdateSaleProduct(id, { colorVinyl: !colorVinyl })} />}
                                label={(
                                  <Typography>
                                    Color vinyl&nbsp;
                                    <Typography component="span" color="text.secondary" variant="body2">($15)</Typography>
                                  </Typography>
                                )}
                              />
                              <FormControlLabel
                                control={<Checkbox checked={shipping} disabled={dropShippingOnly} onChange={() => onUpdateSaleProduct(id, { shipping: !shipping })} />}
                                disabled={!variants || !product.availability?.dropShipping}
                                label="Shipping"
                              />
                              {state.validatedUser?.role === UserRoleEnum.admin && (
                                <Box display="flex" alignItems="center">
                                  <IconButton size="small" onClick={() => onUpdateSaleProduct(id, { discount: discount - 5 })}>
                                    <RemoveCircleOutline fontSize="small" />
                                  </IconButton>
                                  <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    fontSize="large"
                                    lineHeight={1}
                                    color={getPriceAmountColor(discount)}
                                    width="42px"
                                    textAlign="center"
                                  >
                                    {discountDisplay}
                                  </Typography>
                                  <IconButton size="small" onClick={() => onUpdateSaleProduct(id, { discount: discount + 5 })}>
                                    <AddCircleOutline fontSize="small" />
                                  </IconButton>
                                </Box>
                              )}
                            </Box>
                          )}
                          bottomContent={(
                            <Box display="flex" flexDirection="column" gap={1} alignItems="stretch" width="100%">
                              {!variantSelectionDisabled && variants && !isSelectVariant && (
                                <Button
                                  sx={{ height: '100px', mx: 1 }}
                                  size="large"
                                  variant="outlined"
                                  onClick={() => setSelectVariantProductId(id)}
                                >
                                  <EditOutlined fontSize="large" />
                                  <Typography variant="h6" ml={1}>
                                    {formik.values[id] ? 'Change background' : 'Select background'}
                                  </Typography>
                                </Button>
                              )}
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                                flex={1}
                                px={2}
                                pb={0.5}
                                pt={{ xs: 1, sm: 0.5 }}
                                rowGap={1}
                                columnGap={2}
                                flexWrap="wrap"
                                bgcolor={theme => theme.palette.primary.background}
                              >
                                <DiscountChip discount={DiscountEnum.threeOrMore} />
                                <Box display="flex" gap={{ xs: 1, sm: 2 }} alignItems="center">
                                  <Box display="flex" gap={{ xs: 0.5, sm: 1 }} alignItems="center">
                                    <Typography variant="body2" color="text.secondary">Discounts:</Typography>
                                    <Typography
                                      fontWeight={500}
                                      color={getPriceAmountColor(globalDiscount)}
                                    >
                                      {currencyFormatterWithoutDecimals.format(globalDiscount)}
                                    </Typography>
                                  </Box>
                                  <Box display="flex" gap={{ xs: 0.5, sm: 1 }} alignItems="center">
                                    <Typography variant="body2" color="text.secondary">Adjustment:</Typography>
                                    <Typography
                                      fontWeight={500}
                                      color={getPriceAmountColor(discount)}
                                    >
                                      {discountDisplay}
                                    </Typography>
                                  </Box>
                                  <Box display="flex" gap={{ xs: 0.5, sm: 1 }} alignItems="center">
                                    <Typography variant="body2">Total:</Typography>
                                    <Typography fontWeight={500}>{currencyFormatter.format(total)}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        />
                      );
                    })}
                  </List>
                </Card>
                <Card variant="outlined">
                  <Box display="flex" pl={3} pr={2} py={2} flexDirection={{ xs: 'column', sm: 'row' }} gap={3}>
                    <Box flex={1}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Select Discounts</FormLabel>
                        <FormGroup>
                          {discountsMeta.map(({ label, value, getDisabled }) => {
                            const disabled = getDisabled?.(state.saleProducts) ?? false;

                            return (
                              <FormControlLabel
                                key={value}
                                control={<Checkbox checked={state.discounts.includes(value)} onChange={() => onToggleDiscount(value)} />}
                                label={label}
                                disabled={disabled}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </Box>
                    <TotalsOverview />
                  </Box>
                </Card>
              </Box>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};