import { Box, Typography } from '@mui/material';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import { useMemo } from 'react';

export type YesNoUnknownIconSwitchProps = {
  fontSize?: 'small' | 'medium' | 'large' | 'inherit';
  value: boolean | null;
};

export const YesNoUnknownIconSwitch: React.FC<YesNoUnknownIconSwitchProps> = ({ fontSize, value }) => {
  const Icon = yesNoEnumHelpers.yesNo.getIconForValue(value);
  const sx = yesNoEnumHelpers.yesNo.getIconSxForValue(value);

  return <Icon sx={sx} fontSize={fontSize} />;
};

export type YesNoUnknownIconSwitchWithLabelProps = YesNoUnknownIconSwitchProps & {
  children?: React.ReactNode;
};

export const YesNoUnknownIconSwitchWithLabel: React.FC<YesNoUnknownIconSwitchWithLabelProps> = ({ fontSize, value, children }) => {
  const Icon = yesNoEnumHelpers.yesNo.getIconForValue(value);
  const sx = yesNoEnumHelpers.yesNo.getIconSxForValue(value);

  const label = useMemo(() => {
    if (children) {
      return typeof children === 'string' ? <Typography>{children}</Typography> : <Box>{children}</Box>;
    }

    return yesNoEnumHelpers.yesNo.getLabelForValue(value);
  }, [ children, value ]);

  return (
    <Box display="flex" alignItems="center" gap={1.5}>
      <Icon sx={sx} fontSize={fontSize} />
      {label}
    </Box>
  );
};
