import React from 'react';
import { EventSalesChartProps } from './types';
import { GetEventSalesAnalyticsDataResponse } from 'api/actions';
import { AnalyticsChart, AnalyticsChartConfig } from 'components';
import { DateService } from 'services';

export const EventSalesYearsChart: React.FC<EventSalesChartProps> = ({ data: { sales, events }, isLoading }) => {
  const getEventById = (id: string) => events.find(event => event._id === id);

  const dateRange = events.length
    ? { start: DateService.dayjsTz(events[0].dateRange.startAsUtc), end: DateService.dayjsTz(events[events.length - 1].dateRange.endAsUtc) }
    : { start: DateService.dayjs(), end: DateService.dayjs() };
  const eventYearsConfig: AnalyticsChartConfig<GetEventSalesAnalyticsDataResponse['data']['sales'][number]> = {
    baseConfig: {
      data: sales,
      dateRange,
      getId: sale => sale.order.event?._id ?? '',
      getLabel: sale => (getEventById(sale.order.event?._id ?? '')?.year ?? '').toString(),
      getValue: () => 1,
    },
  };

  return (
    <AnalyticsChart
      header="Years"
      config={eventYearsConfig}
      loading={isLoading}
    />
  );
};
