import { Box, Card, List, ListItem, ListItemText, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { GetEventsResponse } from 'api/actions';
import { ProgressBar, getFormattedPaymentDue } from 'components';
import { getEventOwed, getSortedPaymentsDue } from 'helpers';
import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React from 'react';
import { currencyFormatter } from 'services';

export const LogisticsPaymentsDue = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue}
      getDetailPanelContent={({ row }: GridRowParams<GetEventsResponse['data'][number]>) => {

        return (
          <List sx={{ ml: 3 }}>
            {getSortedPaymentsDue(row.paymentsDue)
              .map((paymentDue, index) => {
                return (
                  <ListItem components={{ Root: 'div' }} key={paymentDue._id} disableGutters disablePadding>
                    <Box display="flex" alignItems="stretch" mt={1}>
                      <Typography
                        component="span"
                        minWidth="20px"
                        mr={1}
                        pr={1}
                        color="primary"
                        fontWeight={500}
                        display="flex"
                        alignItems="center"
                        borderRight={theme => `2px solid ${theme.palette.primary.main}`}
                      >
                      #{index + 1}
                      </Typography>

                      <ListItemText
                        primary={(
                          <Typography sx={{ textDecoration: paymentDue.isPaidInFull ? 'line-through' : 'none' }}>
                            {currencyFormatter.format(paymentDue.amountDue)}
                          </Typography>
                        )}
                        secondary={(
                          <Typography variant="body2" color="text.secondary" sx={{ textDecoration: paymentDue.isPaidInFull ? 'line-through' : 'none' }}>
                            {getFormattedPaymentDue(paymentDue)}
                          </Typography>
                        )}
                      />
                    </Box>
                  </ListItem>
                );
              })}
          </List>
        );
      }}
      getDetailPanelHeight={() => 'auto'}
      localStorageArgs={{
        actionNotInitialVisible: true,
        initialVisible: [ 'name', 'daysUntilEvent', 'cost', 'paid', 'owed', 'untilPaymentDue', 'paymentsDueDate', 'acceptanceStatus', 'nextPaymentDue', 'paymentNumber' ],
        initialSortModel: [ { field: 'untilPaymentDue', sort: 'asc' } ]
      }}
      renderTopContent={(events) => {
        const currentAmount = events.reduce((r, event) => r + event.paid, 0);
        const owedAmount = events.reduce((r, event) => r + (getEventOwed(event) ?? 0), 0);

        return (
          <Card variant="outlined" sx={{ borderRadius: 0, borderBottom: 'none' }}>
            <Box m={4} mt={2}>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="body2">Total Paid: <Box component="span" fontWeight={700}>{currencyFormatter.format(currentAmount)}</Box></Typography>
                <Typography variant="body2">Total Owed: <Box component="span" fontWeight={700}>{currencyFormatter.format(owedAmount)}</Box></Typography>
              </Box>
              <ProgressBar currentAmount={currentAmount} expectedAmount={currentAmount + owedAmount} isCurrency />
            </Box>
          </Card>
        );
      }}
    />
  );
};
