import { useCurrentUser } from 'contexts';
import isEqual from 'lodash/isEqual';

export const parseJson = <T extends unknown>(json: string) => {
  let value: string;

  try {
    value = JSON.parse(json);
  } catch (error) {
    value = json;
  }
  return value as T;
};

export const useLocalStorage = <Resource extends unknown>(id: string) => {
  const { sessionId } = useCurrentUser();

  const checkIfValid = (obj1: Resource, obj2: Resource) => {
    if (sessionId) {
      const sessionKey = `${id}_session`;
      const storedSession = localStorage.getItem(sessionKey);

      if(storedSession !== sessionId) {
        localStorage.setItem(sessionKey, sessionId);
        return false;
      }
    }

    if (typeof obj1 !== 'object' || obj1 === null || Array.isArray(obj1)) {
      return true;
    }
    if (typeof obj2 !== 'object' || obj2 === null || Array.isArray(obj2)) {
      return true;
    }

    const keyify = (obj: any, prefix = ''): string[] =>
      Object.keys(obj).reduce((res, el) => {
        if(typeof obj[el] === 'object' && obj[el] !== null && !Array.isArray(obj[el])) {
          return [ ...res, ...keyify(obj[el], prefix + el + '.') ];
        }
        return [ ...res, prefix + el ];
      }, [] as string[]);

    const obj1Keys = keyify(obj1);
    const obj2Keys = keyify(obj2);

    return isEqual(obj1Keys, obj2Keys);
  };

  const setResource = (resource: Resource) => {
    const json = JSON.stringify(resource);

    localStorage.setItem(id, json);
  };

  const getResource = (defaultValue: Resource) => {
    const localStorageJson = localStorage.getItem(id);

    if (localStorageJson) {
      const localStorageValue = parseJson<Resource>(localStorageJson);

      const isValid = checkIfValid(defaultValue, localStorageValue);

      if (!isValid) {
        setResource(defaultValue);
        return defaultValue;
      }

      return localStorageValue;
    }

    return defaultValue;
  };


  const removeResource = () => {
    localStorage.removeItem(id);
  };

  return {
    removeResource,
    setResource,
    getResource
  };
};