import { PageContentContainer, PageContentResourceHeader, SectionCardsEditContextProvider } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import React from 'react';
import { useOrderOutletContext } from './Order.base';
import { Box } from '@mui/material';
import { OverviewSection } from './sections/Overview.section';
import { CustomerSection } from './sections/Customer.section';

export const Order = () => {
  const { order } = useOrderOutletContext();

  return (
    <PageContentContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.orders, text: 'Orders' } ],
        current: 'Order Page',
      }}
      header={<PageContentResourceHeader title={order.name} pageTitle="Order Page" />}
    >
      <SectionCardsEditContextProvider>
        <Box display="flex" flexDirection="column" gap={5}>
          <Box maxWidth="1000px" width="100%">
            <OverviewSection />
          </Box>
          <Box maxWidth="700px" width="100%">
            <CustomerSection />
          </Box>
        </Box>
      </SectionCardsEditContextProvider>
    </PageContentContainer>
  );
};
