import { SectionCardRowEditable, SectionCardRowEditableProps } from 'components';
import { SalePageSectionRowId, SaleSectionRowForm } from '../types';
import { QUERY_KEY } from 'queries/query-keys';
import { useSaleOutletContext } from '../Sale.base';
import { useCurrentUser } from 'contexts';

export type SaleSectionCardRowEditableProps<RowId extends SalePageSectionRowId> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableProps<SaleSectionRowForm[RowId]>, 'rowId' | 'invalidateQueryKeys' | 'disableEditable'>;

export const SaleSectionCardRowEditable = <RowId extends SalePageSectionRowId>(props: SaleSectionCardRowEditableProps<RowId>) => {
  const { sale } = useSaleOutletContext();
  const { isAdmin } = useCurrentUser();

  return (
    <SectionCardRowEditable
      {...props}
      invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.SALE(sale._id) })}
      disableEditable={!isAdmin}
    />
  );
};
