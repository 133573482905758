import { GetEventsResponse } from 'api/actions';
import { EventDate, EventDateImageTypeEnum, EventDateSetupBreakdownTypeEnum, EventDateStaffStatusEnum, EventDateTypeEnum } from 'api/resources';
import { DateService } from 'services';
import { EventDatesStatusEnum, EventStaffStatusEnum } from 'types';

type GetEventDatesStaffStatusArgs = {
  staffNeeded?: number;
  staff: { status: EventDateStaffStatusEnum }[];
};

export const getEventDateStaffStatus = ({ staffNeeded, staff }: GetEventDatesStaffStatusArgs): EventStaffStatusEnum => {
  const confirmedStaff = staff.reduce((r, staff) => r + Number(staff.status === EventDateStaffStatusEnum.confirmed), 0);

  const staffFulfilled = !!Number(staffNeeded) && Number(staffNeeded) <= confirmedStaff;

  return staffFulfilled ? EventStaffStatusEnum.fulfilled : EventStaffStatusEnum.pending;
};

export const getEventStaffStatus = (dates: GetEventDatesStaffStatusArgs[]): EventStaffStatusEnum => {
  if (!dates.length) {
    return EventStaffStatusEnum.pending;
  }

  const allFulfilled = dates.every((date) => getEventDateStaffStatus(date) === EventStaffStatusEnum.fulfilled);

  return allFulfilled ? EventStaffStatusEnum.fulfilled : EventStaffStatusEnum.pending;
};

export const getEventDatesStatus = (dates: Omit<EventDate, 'staff' | 'createdBy' | 'event'>[]): EventDatesStatusEnum => {
  if (!dates.length) {
    return EventDatesStatusEnum.attention;
  }

  const datesNeedAttention = dates.some((date) => {
    const invalidStaffNeeded = !date.staffNeeded;
    const invalidConfirmedTimes = !date.confirmedTimes;

    return invalidStaffNeeded || invalidConfirmedTimes;
  });

  return datesNeedAttention ? EventDatesStatusEnum.attention : EventDatesStatusEnum.complete;
};

export const getEventDatesStaffList = (eventDates: Pick<GetEventsResponse['data'][number]['dates'][number], 'staff'>[]) => {
  return eventDates.reduce((r: GetEventsResponse['data'][number]['dates'][number]['staff'], date) => [
    ...r,
    ...date.staff.filter((dateStaff) => !r.find((staff) => staff.user._id === dateStaff.user._id))
  ], []);
};

export const getEventDateImagesWarnings = (eventDate: GetEventsResponse['data'][number]['dates'][number], eventDates: GetEventsResponse['data'][number]['dates']) => {
  const warningsHash: Record<EventDateImageTypeEnum, string | null> = {
    [EventDateImageTypeEnum.arrival]: null,
    [EventDateImageTypeEnum.boothStraightOut]: null,
    [EventDateImageTypeEnum.boothStreetLeft]: null,
    [EventDateImageTypeEnum.boothStreetRight]: null,
    [EventDateImageTypeEnum.insideVehicle]: null,
    [EventDateImageTypeEnum.setupComplete]: null,
    [EventDateImageTypeEnum.other]: null,
  };
  const eventDateIdx = eventDates.findIndex(_eventDate => _eventDate._id === eventDate._id);
  const firstRegularDateIdx = eventDates.findIndex(_eventDate => _eventDate.type === EventDateTypeEnum.regular);
  // const previousEventDate = eventDateIdx ? eventDates[eventDateIdx - 1] : null;

  const eventDateHasImageType = (type: EventDateImageTypeEnum) => eventDate.images.find(image => image.type === type);

  if (!eventDateHasImageType(EventDateImageTypeEnum.arrival)) {
    warningsHash[EventDateImageTypeEnum.arrival] = 'Arrival photo (taken to confirm team has arrived to an event) - 0 of 1';
  }

  if (eventDate.setupType === EventDateSetupBreakdownTypeEnum.hard) {
    if (!eventDateHasImageType(EventDateImageTypeEnum.setupComplete)) {
      warningsHash[EventDateImageTypeEnum.setupComplete] = 'Complete booth setup photo - 0 of 1';
    }
  }

  if (eventDateIdx === firstRegularDateIdx) {
    if (!eventDateHasImageType(EventDateImageTypeEnum.boothStraightOut)) {
      warningsHash[EventDateImageTypeEnum.boothStraightOut] = 'A photo looking straight out of the booth - 0 of 1';
    }
    if (!eventDateHasImageType(EventDateImageTypeEnum.boothStreetLeft)) {
      warningsHash[EventDateImageTypeEnum.boothStreetLeft] = 'Left booth street view photo is missing - 0 of 1';
    }
    if (!eventDateHasImageType(EventDateImageTypeEnum.boothStreetRight)) {
      warningsHash[EventDateImageTypeEnum.boothStreetRight] = 'Right booth street view photo is missing - 0 of 1';
    }
  }


  if (eventDate.type === EventDateTypeEnum.breakdown || eventDate.breakdownType === EventDateSetupBreakdownTypeEnum.hard) {
    const [ endHour, endMinute ] = eventDate.endTime.split(':');
    const endTime = DateService.dayjsTz(eventDate.dateAsUtc).set('hour', Number(endHour)).set('minute', Number(endMinute));

    if (!eventDateHasImageType(EventDateImageTypeEnum.insideVehicle) && DateService.dayjs().isAfter(endTime)) {
      warningsHash[EventDateImageTypeEnum.insideVehicle] = 'A photo showing the inside of the vehicle is missing - 0 of 1';
    }
  }

  return warningsHash;
};


export const getEventDateAutoSetupTime = (type: EventDateSetupBreakdownTypeEnum, staffNeeded?: number) => {
  const hasOneStaffMember = (staffNeeded ?? 0) < 2;

  if (type === EventDateSetupBreakdownTypeEnum.soft) {
    if (hasOneStaffMember) {
      return 20;
    }

    return 15;
  }

  if (hasOneStaffMember) {
    return 150;
  }

  return 120;
};

export const getEventDateAutoBreakdownTime = (type: EventDateSetupBreakdownTypeEnum, staffNeeded?: number) => {
  const hasOneStaffMember = (staffNeeded ?? 0) < 2;

  if (type === EventDateSetupBreakdownTypeEnum.soft) {
    if (hasOneStaffMember) {
      return 20;
    }

    return 15;
  }

  if (hasOneStaffMember) {
    return 150;
  }

  return 120;
};