import { Autocomplete } from 'components';
import { useTeamVehicleChangeOptions } from 'queries';
import React from 'react';
import { useTeamOutletContext } from '../Team.base';
import { Typography } from '@mui/material';

export const VehicleForm = () => {
  const { team } = useTeamOutletContext();
  const { data: teamVehicleChangeOptions = [], isInitialLoading: teamVehicleChangeOptionsLoading } = useTeamVehicleChangeOptions(team._id);

  return (
    <Autocomplete
      id="vehicle"
      name="vehicle"
      label="Assigned vehicle"
      loading={teamVehicleChangeOptionsLoading}
      options={teamVehicleChangeOptions}
      transformValue={(team) => team?.id}
      getOptionDisabled={(team) => Boolean(team.teamConflicts?.length)}
      getOptionLabel={(team) => team?.name}
      autoFocus
      openOnFocus
      renderOption={(props, option) => {
        const teamConflicts = option.teamConflicts;

        if (teamConflicts) {
          return (
            <li {...props}>
              <div>
                <Typography>{option.name}</Typography>
                {teamConflicts.map((teamConflict, index) => {
                  return <Typography key={index} variant="body2"><Typography component="span" color="error" variant="inherit">CONFLICT:</Typography> {teamConflict.teamName}</Typography>;
                })}
              </div>
            </li>
          );
        }

        return <li {...props}><Typography>{option.name}</Typography></li>;
      }}
    />
  );
};
