import { Box, Rating } from '@mui/material';
import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { GetEventsResponse } from 'api/actions';
import { DateService, currencyFormatter } from 'services';
import { ThemePalette, UnknownEnum } from 'types';
import {
  eventEnumHelpers,
  getApplicationDateRangeLabel,
  getEventStaffStatus,
  getEventDatesStatus,
  createEnumComparer,
  getSelectOptionsFromEnumHelper,
  getUntilApplicationDeadlineColorCodeConfig,
  getUntilApplicationOpenColorCodeConfig,
  getApplicationHasBeenOpenColorCodeConfig,
  getEventOwed,
  getEventUnpaidPaymentsDue,
  getEventClosestDue,
  getUntilApplicationOpenValue,
  getApplicationHasBeenOpenValue,
  getUntilApplicationDeadlineValue
} from 'helpers';

import { YesNoUnknownIconSwitch } from 'components/IconSwitch';
import { getMatrixDisplayText } from 'components/PlaceDistanceAndDurationContent';
import { StaffList } from 'components/StaffList';
import {
  StandardDateCell,
  OwedCell,
  TeamCell,
  ColorCodeCell,
  ParticipationStatusCell,
  ApplicationStatusCell,
  AcceptanceStatusCell,
  ApplicationPlatformCell,
  DatesStatusCell,
  InsuranceStatusCell,
  StaffStatusCell,
  InsuranceSharedCell
} from '../Cells';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import { EventAcceptanceStatusEnum } from 'api/resources';

export const eventSalesColumns: GridColDef[] = [
  {
    field: 'salesRevenue',
    headerName: 'Sales Revenue',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value))
  },
  {
    field: 'salesCount',
    headerName: 'Sales #',
    width: 80,
  },
];

export const eventStaffListField: GridColDef = {
  field: 'staffList',
  headerName: 'Staff List',
  width: 200,
  valueGetter: (params: GridValueGetterParams<{ dates: Pick<GetEventsResponse['data'][number]['dates'][number], 'staff'>[] }>) => {
    const staffList = params.row.dates.reduce((r: GetEventsResponse['data'][number]['dates'][number]['staff'], date) => [
      ...r,
      ...date.staff.filter((staff) => !r.find((element) => element.user._id === staff.user._id))
    ], []);

    return staffList;
  },
  renderCell: ({ value }) => <StaffList staffList={value} />
};

export const eventTimeframeField: GridColDef = {
  field: 'eventTimeframe',
  headerName: 'Event Daterange',
  width: 340,
  valueGetter: (params: GridValueGetterParams<Pick<GetEventsResponse['data'][number], 'startDate' | 'endDate'>>) => [ params.row.startDate?.dateAsUtc, params.row.endDate?.dateAsUtc ],
  valueFormatter: ({ value }: GridValueFormatterParams<[ string | undefined, string | undefined ]>) => DateService.getFormattedDateRange(value[0] ? DateService.dayjsTz(value[0]) : value[0], value[1] ? DateService.dayjsTz(value[1]) : value[1]),
  sortComparator: DateService.dateRangeComparer,
};

const numberOrNullOrUndefinedComparator = (a?: number | null, b?: number | null) => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }

  return a - b;
};

export const eventsTableColumns: GridColDef<GetEventsResponse['data'][number]>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
  },
  {
    field: 'year',
    headerName: 'Year',
    width: 70,
  },
  {
    field: 'daysUntilEvent',
    headerName: 'Days until Event',
    valueGetter: ({ row }) => {
      if (!row.startDate) {
        return undefined;
      }

      return -DateService.dayjs().startOf('day').diff(row.startDate.dateAsUtc, 'days');
    },
    renderCell: ({ value, row }) => {
      let label = '';
      let color: ThemePalette = 'unknown';
      const isPast = row.endDate && -DateService.dayjs().startOf('day').diff(row.endDate.dateAsUtc, 'days') < 0;

      if (value === undefined) {
        label = 'Unknown';
      } else if (value <= 0 || value === null) {
        if(isPast) {
          label = 'Past';
        } else {
          label = 'Ongoing';
        }
      } else {
        label = `${value} day${value === 1 ? '' : 's'}`;
      }

      if (value === undefined || isPast) {
        color = 'unknown';
      } else if (value === null || value <= 15) {
        color = 'error';
      } else if (value <= 30) {
        color = 'warning';
      } else {
        color = 'success';
      }

      return <ColorCodeCell color={color} label={label} />;
    },
    width: 170,
    cellClassName: 'padding-0',
    sortComparator: (a, b) => {
      if(a === undefined && b === undefined) {
        return 0;
      }
      if(a === undefined) {
        return 1;
      }
      if(b === undefined) {
        return -1;
      }

      return a - b;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    valueFormatter: ({ value }) => DateService.dayjs(value, 'MM/DD/YYYY'),
    width: 150,
    type: 'date',
    renderCell: StandardDateCell,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    valueGetter: ({ row }) => row.startDate?.dateAsUtc,
    valueFormatter: ({ value }) => DateService.getFormattedDate(DateService.dayjsTz(value), 'standardTableCell'),
    width: 150,
    type: 'date',
    renderCell: StandardDateCell,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    valueGetter: ({ row }) => row.endDate?.dateAsUtc,
    valueFormatter: ({ value }) => DateService.getFormattedDate(DateService.dayjsTz(value), 'standardTableCell'),
    width: 150,
    type: 'date',
    renderCell: StandardDateCell,
  },
  eventTimeframeField,
  {
    field: 'daysCount',
    headerName: '# of Days',
    valueGetter: ({ row }) => row.dates.length,
    width: 80,
  },
  {
    field: 'filesCount',
    headerName: 'Files #',
    valueGetter: ({ row }) => row.files?.length ?? 0,
    width: 70,
  },
  {
    field: 'juryFee',
    headerName: 'Jury Fee',
    width: 70,
    valueGetter: ({ row }) => row.juryFee,
    renderCell: ({ value }) => (
      <Box width="100%" display="flex" justifyContent="center">
        <YesNoUnknownIconSwitch value={value} />
      </Box>
    )
  },
  {
    field: 'juryFeeAmount',
    headerName: 'Jury Fee $',
    valueGetter: ({ row }) => row.juryFeeAmount ? currencyFormatter.format(row.juryFeeAmount) : undefined,
    width: 100,
  },
  {
    field: 'juryFeePaid',
    headerName: 'Is Jury fee paid?',
    width: 130,
    valueGetter: ({ row }) => row.juryFeePaid,
    renderCell: ({ value }) => (
      <Box width="100%" display="flex" justifyContent="center">
        <YesNoUnknownIconSwitch value={value} />
      </Box>
    )
  },
  {
    field: 'juryFeePaymentType',
    headerName: 'Jury fee payment',
    width: 150,
    valueGetter: ({ row }) => row.juryFeePaymentType,
    valueFormatter: ({ value }) => eventEnumHelpers.paymentType.getLabel(value)
  },
  {
    field: 'cost',
    headerName: 'Cost',
    valueGetter: ({ row }) => row.cost ? currencyFormatter.format(row.cost) : null,
    width: 100,
  },
  {
    field: 'paid',
    headerName: 'Paid',
    valueGetter: ({ row }) => currencyFormatter.format(row.paid ?? 0),
    width: 100,
  },
  {
    field: 'owed',
    headerName: 'Owed',
    width: 120,
    valueGetter: ({ row }) => getEventOwed(row),
    renderCell: OwedCell,
  },
  ...eventSalesColumns,
  {
    field: 'teamName',
    headerName: 'Team',
    width: 130,
    valueGetter: ({ row }) => row.team?.name,
    renderCell: ({ row }) => <TeamCell team={row.team ?? null} />,
  },
  {
    field: 'eventOrganizerName',
    headerName: 'Event Organizer',
    valueGetter: ({ row }) => row.eventOrganizer?.name,
    width: 200,
  },
  {
    field: 'eventManagerName',
    headerName: 'Event Manager',
    valueGetter: ({ row }) => row.eventManager?.name,
    width: 150,
  },
  {
    field: 'location',
    headerName: 'Location',
    valueGetter: ({ row }) => row.place.address,
    width: 300,
  },
  {
    field: 'vehicleName',
    headerName: 'Vehicle',
    valueGetter: ({ row }) => row.vehicle?.name,
    width: 120,
  },
  {
    field: 'attendance',
    headerName: 'Attendance',
    width: 100,
  },
  {
    field: 'vendors',
    headerName: '# of Vendors',
    width: 100,
  },
  {
    field: 'spaceSize',
    headerName: 'Space Size',
    width: 150,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 200,
  },
  {
    field: 'applicationOpenDate',
    headerName: 'Application Open',
    valueGetter: ({ row }) => row.applicationIsOpen ? null : row.applicationOpenDate,
    valueFormatter: ({ value }) => {
      if(value === null) {
        return 'Is open';
      }
      if(value === undefined) {
        return 'Unknown';
      }

      return DateService.dayjs(value).format('MM/DD/YY(ddd)');
    },
    width: 150,
    sortComparator: DateService.dateComparator(true),
  },
  {
    field: 'applicationHasBeenOpen',
    headerName: 'Application has been open',
    valueGetter: ({ row }) => getApplicationHasBeenOpenValue(row.applicationOpenDate, row.applicationIsOpen),
    renderCell: ({ row }) => {
      const colorCodeConfig = getApplicationHasBeenOpenColorCodeConfig(row.applicationOpenDate, row.applicationIsOpen);

      return <ColorCodeCell {...colorCodeConfig} />;
    },
    width: 200,
    cellClassName: 'padding-0',
    sortComparator: numberOrNullOrUndefinedComparator,
  },
  {
    field: 'untilApplicationOpen',
    headerName: 'Days until Open',
    valueGetter: ({ row }) => getUntilApplicationOpenValue(row.applicationOpenDate, row.applicationIsOpen),
    renderCell: ({ row }) => {
      const colorCodeConfig = getUntilApplicationOpenColorCodeConfig(row.applicationOpenDate, row.applicationIsOpen);

      return <ColorCodeCell {...colorCodeConfig} />;
    },
    width: 170,
    cellClassName: 'padding-0',
    sortComparator: numberOrNullOrUndefinedComparator,
  },
  {
    field: 'applicationDeadlineDate',
    headerName: 'Application Deadline',
    valueGetter: ({ row }) => row.applicationOpenUntilFull ? null : row.applicationDeadlineDate,
    valueFormatter: ({ value }) => {
      if(value === null) {
        return 'Is open until full';
      }
      if(value === undefined) {
        return 'Unknown';
      }
      return DateService.dayjs(value).format('MM/DD/YYYY(ddd)');
    },
    width: 150,
    sortComparator: DateService.dateComparator(true),
  },
  {
    field: 'untilApplicationDeadline',
    headerName: 'Days until Deadline',
    valueGetter: ({ row }) => getUntilApplicationDeadlineValue(row.applicationDeadlineDate, row.applicationOpenUntilFull),
    renderCell: ({ row }) => {
      const colorCodeConfig = getUntilApplicationDeadlineColorCodeConfig(row.applicationDeadlineDate, row.applicationOpenUntilFull);

      return <ColorCodeCell {...colorCodeConfig} />;
    },
    width: 170,
    cellClassName: 'padding-0',
    sortComparator: numberOrNullOrUndefinedComparator,
  },
  {
    field: 'applicationOpenTimeframe',
    valueGetter: ({ row }) => getApplicationDateRangeLabel(
      row.applicationOpenDate,
      row.applicationIsOpen,
      row.applicationDeadlineDate,
      row.applicationOpenUntilFull,
    ),
    headerName: 'App. Open Timeframe',
    width: 300,
  },
  {
    field: 'untilPaymentDue',
    headerName: 'Days until payment due',
    valueGetter: ({ row }) => {
      const unpaidPaymentsDue = getEventUnpaidPaymentsDue(row);

      if (!row.cost || !unpaidPaymentsDue.length || row.acceptanceStatus !== EventAcceptanceStatusEnum.accepted) {
        return Infinity;
      }

      if (unpaidPaymentsDue.find(paymentDue => paymentDue.dueWithApplication || paymentDue.dueAsSoonAsPossible)) {
        return -1;
      }

      const { daysUntilDue } = getEventClosestDue(row);

      return daysUntilDue;
    },
    renderCell: ({ row }) => {
      const unpaidPaymentsDue = getEventUnpaidPaymentsDue(row);
      const { daysUntilDue, dueType, paymentDue } = getEventClosestDue(row);

      if (!row.cost) {
        return <ColorCodeCell color="error" label="Cost unassigned" />;
      }

      if (dueType === null) {
        return <ColorCodeCell color="success" label="Paid in full!" />;
      }

      if (dueType === 'withApplication') {
        return <ColorCodeCell color="warning" label="Due with application" />;
      }

      if (row.acceptanceStatus !== EventAcceptanceStatusEnum.accepted) {
        return <ColorCodeCell color="unknown" label="No payments due until accepted" caption={dueType ? `Then ${dueType === 'asap' ? 'ASAP' : `on ${DateService.getFormattedDate(paymentDue?.dueDateAsUtc ?? '')}`}` : undefined} />;
      }

      if (unpaidPaymentsDue.find(paymentDue => paymentDue.dueAsSoonAsPossible)) {
        return <ColorCodeCell color="error" label="Due ASAP" />;
      }

      let solid = false;
      let label = '';
      let color: ThemePalette = 'unknown';

      if(daysUntilDue === undefined) {
        label = 'Unknown';
      } else if(daysUntilDue < 0) {
        label = `Passed ${-daysUntilDue} days ago`;
      } else if(daysUntilDue === 0) {
        label = 'Due today';
      }else {
        label = `${daysUntilDue} day${daysUntilDue === 1 ? '' : 's'}`;
      }

      if (daysUntilDue <= 14) {
        color = 'error';
        solid = true;
      } else if (daysUntilDue > 14) {
        color = 'warning';
      }

      return <ColorCodeCell color={color} label={label} solid={solid} caption={DateService.getFormattedDate(DateService.dayjsTz(paymentDue?.dueDateAsUtc))} />;
    },
    width: 210,
    cellClassName: 'padding-0'
  },
  {
    field: 'nextPaymentDue',
    headerName: 'Next Payment Due',
    valueGetter: ({ row }) =>  {
      const { paymentDue } = getEventClosestDue(row);
      const paid = row.applicationPayments
        .filter(applicationPayment => applicationPayment.paymentDue === paymentDue?._id)
        .reduce((r, applicationPayment) => r + applicationPayment.amount, 0);

      return Math.max((paymentDue?.amountDue ?? 0) - paid, 0);
    },
    valueFormatter: ({ value }) => value ? currencyFormatter.format(value) : 'N/A',
    width: 140,
  },
  {
    field: 'paymentNumber',
    headerName: 'Payment #',
    valueGetter: ({ row }) =>  {
      const unpaidPaymentsDue = getEventUnpaidPaymentsDue(row);

      return `${row.paymentsDue.length - unpaidPaymentsDue.length + 1} of ${row.paymentsDue.length}`;
    },
    width: 90,
    align: 'center',

  },
  {
    field: 'participationStatus',
    headerName: 'Participation Status',
    valueGetter: ({ row }) => row.participationStatus ?? UnknownEnum.unknown,
    sortComparator: createEnumComparer(eventEnumHelpers.participationStatus.enumValues),
    width: 150,
    renderCell: ParticipationStatusCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.participationStatus),
  },
  {
    field: 'applicationStatus',
    headerName: 'Application Status',
    valueGetter: ({ row }) => row.applicationStatus ?? UnknownEnum.unknown,
    sortComparator: createEnumComparer(eventEnumHelpers.applicationStatus.enumValues),
    width: 140,
    renderCell: ApplicationStatusCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.applicationStatus),
  },
  {
    field: 'acceptanceStatus',
    headerName: 'Acceptance Status',
    valueGetter: ({ row }) => row.acceptanceStatus ?? UnknownEnum.unknown,
    sortComparator: createEnumComparer(eventEnumHelpers.acceptanceStatus.enumValues),
    width: 150,
    renderCell: AcceptanceStatusCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.acceptanceStatus),
  },
  {
    field: 'staffStatus',
    headerName: 'Staff Status',
    valueGetter: ({ row }) => getEventStaffStatus(row.dates),
    width: 100,
    renderCell: StaffStatusCell,
  },
  eventStaffListField,
  {
    field: 'datesStatus',
    headerName: 'Dates Status',
    valueGetter: ({ row }) => getEventDatesStatus(row.dates),
    width: 100,
    renderCell: DatesStatusCell,
  },
  {
    field: 'applicationPlatform',
    headerName: 'Application Platform',
    valueGetter: ({ row }) => row.applicationPlatform ?? UnknownEnum.unknown,
    width: 160,
    renderCell: ApplicationPlatformCell,
  },
  {
    field: 'paymentNote',
    headerName: 'Payment Note',
    valueGetter: ({ row }) => row.paymentNote,
    width: 200,
  },
  {
    field: 'lodgingRequired',
    headerName: 'Lodging Required',
    width: 130,
    valueGetter: ({ row }) => row.lodging?.isRequired,
    renderCell: ({ value }) => (
      <Box width="100%" display="flex" justifyContent="center">
        <YesNoUnknownIconSwitch value={value} />
      </Box>
    ),
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(yesNoEnumHelpers.yesNo),
  },
  {
    field: 'insuranceRequired',
    headerName: 'Insurance Required',
    width: 150,
    valueGetter: ({ row }) => row.insurance?.isRequired,
    renderCell: ({ value }) => (
      <Box width="100%" display="flex" justifyContent="center">
        <YesNoUnknownIconSwitch value={value} />
      </Box>
    ),
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(yesNoEnumHelpers.yesNo),
  },
  {
    field: 'insuranceStatus',
    headerName: 'Insurance Status',
    valueGetter: ({ row }) => row.insurance?.status ?? UnknownEnum.unknown,
    width: 200,
    renderCell: InsuranceStatusCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.insuranceStatus),
  },
  {
    field: 'insuranceShared',
    headerName: 'Insurance Shared',
    valueGetter: ({ row }) => row.insurance?.shared ?? UnknownEnum.unknown,
    width: 200,
    renderCell: InsuranceSharedCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.insuranceShared),
  },
  {
    field: 'studioToEvent',
    headerName: 'Studio → Event',
    width: 220,
    valueGetter: ({ row }) => row.place.fromStudioMatrix,
    valueFormatter: ({ value }) => getMatrixDisplayText(value),
  },
  {
    field: 'averageOverallRating',
    headerName: 'Average Rating',
    width: 140,
    renderCell: ({ value }) => {
      return <Rating value={value} readOnly />;
    }
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    valueGetter: ({ row }) => row.createdBy?.name,
    width: 140,
  }
];
