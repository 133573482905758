import { SectionCardRowEditable, SectionCardRowEditableList, SectionCardRowEditableListProps, SectionCardRowEditableProps } from 'components';
import { useCurrentUser } from 'contexts';
import { OrderPageSectionRowId, OrderSectionRowForm } from '../types';
import { QUERY_KEY } from 'queries/query-keys';
import { useOrderOutletContext } from '../Order.base';

export type OrderSectionCardRowEditableProps<RowId extends OrderPageSectionRowId> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableProps<OrderSectionRowForm[RowId]>, 'rowId' | 'invalidateQueriesHandler' | 'warningDescription'>;

export const OrderSectionCardRowEditable = <RowId extends OrderPageSectionRowId>(props: OrderSectionCardRowEditableProps<RowId>) => {
  const { order } = useOrderOutletContext();
  const { isAdmin } = useCurrentUser();

  return (
    <SectionCardRowEditable
      {...props}
      invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.ORDER(order._id) })}
      disableEditable={props.disableEditable ?? !isAdmin}
    />
  );
};

export type OrderSectionCardRowEditableListProps<RowId extends keyof OrderSectionRowForm, ListItem extends {_id: string}> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableListProps<OrderSectionRowForm[RowId], ListItem>, 'rowId' | 'invalidateQueriesHandler'>;

export const OrderSectionCardRowEditableList = <RowId extends keyof OrderSectionRowForm, ListItem extends {_id: string}>(props: OrderSectionCardRowEditableListProps<RowId, ListItem>) => {
  const { order } = useOrderOutletContext();
  const { isAdmin } = useCurrentUser();

  return (
    <SectionCardRowEditableList
      {...props}
      invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.ORDER(order._id) })}
      disableEditable={props.disableEditable ?? !isAdmin}
    />
  );
};