import { authAxiosRequest } from 'api/axios';
import { Customer, Event, EventDate, Order, OrderFulFillmentStatusEnum, OrderSaleFulfillmentStatusEnum, OrderSaleFulfillmentTypeEnum, Place, Product, Sale, Storefront, User } from 'api/resources';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';

export type GetOrderResponse = {
  data: ResourceWithPopulated<Order, {
    fulfillmentStatus: OrderFulFillmentStatusEnum;
    storefront?: PickFromResource<Storefront, 'name'>;
    event?: PickFromResource<Event, 'name'>;
    eventDate?: PickFromResource<EventDate, 'dateAsUtc'>;
    user?: PickFromResource<User, 'name'>;
    sales: ResourceWithPopulated<Sale, {
      product: PickFromResource<Product, 'name' | 'imageUrl'>;
      saleDate: string;
      fulfillmentStatus: OrderSaleFulfillmentStatusEnum;
      fulfillmentType: OrderSaleFulfillmentTypeEnum;
    }>[];
    customer?: ResourceWithPopulated<Customer, {
      place: Place;
    }>;
  }>;
};

export const getOrder = (orderId: string) => {
  return authAxiosRequest<GetOrderResponse>(`/api/orders/${orderId}`);
};

export type GetOrdersResponse = {
  data: {
    orders: GetOrderResponse['data'][];
    totalCount: number;
  };
};

export type GetOrdersInput = {
  page: number;
};

export const getOrders = (input: GetOrdersInput) => {
  return authAxiosRequest<GetOrdersResponse>(`/api/orders?page=${input.page}`);
};


export const getNotFulfilledOrders = (input: GetOrdersInput) => {
  return authAxiosRequest<GetOrdersResponse>(`/api/orders/not_fulfilled?page=${input.page}`);
};

