import { Box, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { GetOrdersResponse } from 'api/actions';
import { missingImg } from 'assets';
import { OrderSaleFulfillmentStatusChip, OrderSaleFulfillmentTypeChip } from 'components';
import React from 'react';

export const OrdersTableDetailsPanelContent: React.FC<GridRowParams<GetOrdersResponse['data']['orders'][number]>> = ({ row }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2} ml={7} my={1}>
      {row.sales.map(sale => (
        <Box key={sale._id} display="flex" gap={1}>
          <Box minWidth="60px" width="60px" height="60px">
            <img style={{ maxWidth: '100%', height: 'auto', maxHeight: '100%' }} src={sale.product.imageUrl || missingImg} alt="header" />
          </Box>
          <Box>
            <Typography variant="body2" mb={2} fontWeight={500}>{sale.product.name}</Typography>
            <Box display="flex" gap={1}>
              <OrderSaleFulfillmentTypeChip value={sale.fulfillmentType} />
              <OrderSaleFulfillmentStatusChip value={sale.fulfillmentStatus} />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
