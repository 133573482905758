import { Box } from '@mui/material';
import { useEventContext } from '../event.context';
import React, { useMemo } from 'react';
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers-pro';
import { DateObjectType, DateService } from 'services';
import { GetEventResponse } from 'api/actions';

export type EventDatesPreviewCalendarsProps = {

};

type ServerDayProps = PickersDayProps<DateObjectType> & { highlightedDays?: number[] };

const ServerDay: React.FC<ServerDayProps> = (props) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
        !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <PickersDay
      {...other}
      sx={{
        ...other.sx,
        '&.Mui-selected': {
          backgroundColor: 'primary.background',
          border: theme => `1px solid ${theme.palette.primary.main}`,
          borderRadius: 0,
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: 'primary.background',
          },
          '&:focus': {
            backgroundColor: 'primary.background',
          },
          '&:active': {
            backgroundColor: 'primary.background',
          }
        }
      }}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      selected={isSelected}
    />
  );
};

type CalendarPreviewConfiguration = {
  month: number;
  dates: GetEventResponse['data']['dates'];
};

export const EventDatesPreviewCalendars: React.FC<EventDatesPreviewCalendarsProps> = () => {
  const { event } = useEventContext();

  const calendarPreviews = useMemo(() => {
    if (event.startDate && event.endDate) {
      const startMonth = DateService.dayjsTz(event.startDate.dateAsUtc).month();
      const endMonth = DateService.dayjsTz(event.endDate.dateAsUtc).month();

      return Array.from({ length: endMonth - startMonth + 1 }, (_, i) => {
        const currentMonth = startMonth + i;

        return {
          month: currentMonth,
          dates: event.dates.filter(date => DateService.dayjsTz(date.dateAsUtc).month() === currentMonth),
        };
      });
    }

    return [];
  }, [ event.dates, event.endDate, event.startDate ]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {calendarPreviews.map((config, idx) => {

        return (
          <PreviewCalendar {...config} key={idx} />
        );
      })}
    </Box>
  );
};


const PreviewCalendar: React.FC<CalendarPreviewConfiguration> = props => {
  const { event } = useEventContext();
  const month = DateService.dayjs().year(event.year).month(props.month).startOf('month');

  const width = 200;
  const rows = month.day() >= 4 ? 6 : 5;
  const cellWidth = width / 7;
  const cellHeight = cellWidth - 4;

  return (
    <Box width="fit-content">
      <DateCalendar
        sx={{
          width: width,
          '& .MuiPickersCalendarHeader-root': {
            p: 0,
            m: 0,
            minHeight: 'unset',
            justifyContent: 'center',
            py: 0.5
          },
          '& .MuiPickersCalendarHeader-labelContainer': {
            margin: 'unset'
          },
          '& .MuiPickersCalendarHeader-label': {
            fontWeight: 500,
            margin: 'unset',
            fontSize: '14px',
          },
          '& .MuiDayCalendar-weekDayLabel, .MuiPickersDay-root': {
            width: cellWidth,
            height: cellHeight,
            fontSize: '12px',
          },
          '& .MuiPickersArrowSwitcher-root': {
            display: 'none'
          },
          '& .MuiDayCalendar-weekDayLabel': {
            height: '20px',
          },
          '& .MuiDayCalendar-slideTransition': {
            minHeight: cellHeight * rows + 2 * (rows + 1),
          },
          '& .MuiDayCalendar-weekContainer': {
            '&:last-child': {
              marginBottom: 0,
            },
            '&:first-child': {
              marginTop: 0,
            }
          }
        }}
        defaultValue={month}
        readOnly
        slots={{
          switchViewButton: () => null,
          nextIconButton: () => null,
          previousIconButton: () => null,
          day: ServerDay,
        }}
        views={[ 'day' ]}
        slotProps={{
          day: {
            highlightedDays: props.dates.map(date => DateService.dayjsTz(date.dateAsUtc).date()),
          } as ServerDayProps,
        }}
      />
    </Box>
  );
};