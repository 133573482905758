import { ColorCodeCellProps } from 'components';
import { DateService } from 'services';
import { ThemePalette } from 'types';

export const getApplicationDateRangeLabel = (applicationOpenDate?: string, applicationIsOpen?: boolean, applicationDeadlineDate?: string, applicationOpenUntilFull?: boolean) => {
  if(!applicationIsOpen && !applicationOpenUntilFull) {
    return DateService.getFormattedDateRange(applicationOpenDate, applicationDeadlineDate, false);
  }

  const openLabel = applicationIsOpen ? 'Open' : `Open date is ${applicationOpenDate ? DateService.getFormattedDate(applicationOpenDate, 'MM-D-YY') : 'unassigned'}`;
  const deadlineLabel = applicationOpenUntilFull ? 'is open until full' : `deadline date is ${applicationDeadlineDate ? DateService.getFormattedDate(applicationDeadlineDate, 'MM-D-YY') : 'unassigned'}`;

  return `${openLabel}, ${deadlineLabel}`;
};

// Open

export const getUntilApplicationOpenValue = (applicationOpenDate?: string, applicationIsOpen?: boolean) => {
  let value;

  if (applicationIsOpen) {
    value = null;
  } else if(!applicationOpenDate) {
    value = undefined;
  } else {
    value = -DateService.dayjs().startOf('day').diff(applicationOpenDate, 'days');
  }

  return value;
};

export const getApplicationHasBeenOpenValue = (applicationOpenDate?: string, applicationIsOpen?: boolean) => {
  let value = getUntilApplicationOpenValue(applicationOpenDate, applicationIsOpen);

  if (value) {
    value = -value;
  }

  return value;
};

export const getUntilApplicationOpenColorCodeConfig = (applicationOpenDate?: string, applicationIsOpen?: boolean): ColorCodeCellProps => {
  const value = getUntilApplicationOpenValue(applicationOpenDate, applicationIsOpen);
  let label = '';
  let color: ThemePalette = 'unknown';

  if (value === undefined) {
    label = 'Unknown';
  } else if (value === null || value <= 0) {
    label = 'Already open';
  } else {
    label = `${value} day${value === 1 ? '' : 's'}`;
  }

  if (value === undefined) {
    color = 'unknown';
  } else if (value === null || value <= 7) {
    color = 'success';
  } else if (value <= 14) {
    color = 'warning';
  } else {
    color = 'error';
  }

  return { color, label };
};

export const getApplicationHasBeenOpenColorCodeConfig = (applicationOpenDate?: string, applicationIsOpen?: boolean): ColorCodeCellProps => {
  const value = getApplicationHasBeenOpenValue(applicationOpenDate, applicationIsOpen);

  let label = '';
  let color: ThemePalette = 'unknown';

  if (value === undefined) {
    label = 'Unknown';
  } else if(value === null) {
    label = 'Is Open (open date unknown)';
  } else if(value < 0) {
    label = 'Not open yet';
  } else {
    label = `${value} day${value === 1 ? '' : 's'}`;
  }

  if (value !== undefined) {
    if(value === null) {
      color = 'warning';
    } else if (value <= 7) {
      color = 'success';
    } else if (value > 14) {
      color = 'error';
    } else if (value > 7) {
      color = 'threat';
    }
  }

  return { label, color };
};

// Deadline

export const getUntilApplicationDeadlineValue = (applicationDeadlineDate?: string, applicationOpenUntilFull?: boolean) => {
  let value;

  if(applicationOpenUntilFull) {
    value = null;
  } else if(!applicationDeadlineDate) {
    value = undefined;
  } else {
    value = -DateService.dayjs().startOf('day').diff(applicationDeadlineDate, 'days');
  }

  return value;
};

export const getUntilApplicationDeadlineColorCodeConfig = (applicationDeadlineDate?: string, applicationOpenUntilFull?: boolean): ColorCodeCellProps => {
  const value = getUntilApplicationDeadlineValue(applicationDeadlineDate, applicationOpenUntilFull);
  let solid = false;
  let label = '';
  let color: ThemePalette = 'unknown';

  if(value === null) {
    label = 'Until Full';
  } else if(value === undefined) {
    label = 'Unknown';
  } else if(value < 0) {
    label = 'Already closed';
  } else if (value === 0) {
    label = 'Due today';
  } else {
    label = `${value} day${value === 1 ? '' : 's'}`;
  }

  if(value === null || value === undefined) {
    color = 'error';
  } else if (value <= 14) {
    color = 'error';
    solid = true;
  } else if (value > 14) {
    color = 'warning';
  }

  return { color, solid, label };
};