import { Box, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { getSelectOptionsFromEnumHelper, eventEnumHelpers } from 'helpers';
import React, { useMemo } from 'react';
import { formSx } from 'styles';
import { EventPageSectionRowId, EventSectionRowForm } from '../types';
import { FormDateTimePicker } from 'components/DateTimePicker';
import { TextInput, AddressVerificationInput, MarkdownInput, Select } from 'components/Inputs';
import { RadioGroupYesNoBoolean } from 'components/RadioGroup';
import { YesNoEnum } from 'types';

export const EventLodgingPlaceForm: React.FC = () => {
  const formik = useFormikContext<EventSectionRowForm[EventPageSectionRowId.LODGING_PLACE]>();
  const { values: { needToSendCreditAuthorization } } = formik;
  const bookingStatusOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.lodgingBookingStatus), []);

  return (
    <Box sx={formSx.formGroup}>
      <TextInput
        label="Lodging Name"
        name="placeName"
      />
      <AddressVerificationInput
        name="placeAddress"
        label="Lodging Address"
        placeIdName="placeId"
      />

      <Box display="flex" gap={1} alignItems="center">
        <FormDateTimePicker label="Check-in" name="checkIn" sx={{ flex: 1 }} />
        <Typography variant="h6" color="text.secondary">—</Typography>
        <FormDateTimePicker label="Check-out" name="checkOut" sx={{ flex: 1 }} />
      </Box>

      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              name="cost"
              label="Cost"
              type="number"
              fullWidth
              helperText="Estimated cost"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name="finalCost"
              label="Final Cost"
              type="number"
              fullWidth
              helperText="Actual amount paid after the stay."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              name="bookingStatus"
              label="Booking Status"
              options={bookingStatusOptions}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name="confirmationNumber"
              label="Booking Confirmation Number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name="website"
              label="Website"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name="phone"
              label="Phone"
              fullWidth
            />
          </Grid>
        </Grid>

        <RadioGroupYesNoBoolean
          label="Need to send credit authorization to the hotel"
          name="needToSendCreditAuthorization"
          row
          includeUnknown
        />
        {needToSendCreditAuthorization === YesNoEnum.yes && (
          <RadioGroupYesNoBoolean
            label="Credit authorization sent to the hotel"
            name="creditAuthorizationSent"
            row
            includeUnknown
          />
        )}

        <MarkdownInput name="notes" label="Notes" />
      </>
    </Box>
  );
};
