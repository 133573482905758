import { DateRange } from '@mui/x-date-pickers-pro';
import { EventAcceptanceStatusEnum, EventApplicationPlatformEnum, EventApplicationStatusEnum, EventParticipationStatusEnum } from 'api/resources';
import { UnknownEnum } from 'types/unknown';

export const enum EventStaffStatusEnum {
  pending = 'pending',
  fulfilled = 'fulfilled',
}

export const enum EventDatesStatusEnum {
  complete = 'complete',
  attention = 'attention',
}


export type TEventsTableEditableRow = {
  _id: string;
  name: string;
  eventTimeframe: string;
  applicationOpenTimeframe: DateRange<string>;

  spaceSize: string;
  attendance: number;

  participationStatus: EventParticipationStatusEnum | UnknownEnum;
  applicationStatus: EventApplicationStatusEnum | UnknownEnum;
  applicationPlatform: EventApplicationPlatformEnum | UnknownEnum;
  acceptanceStatus: EventAcceptanceStatusEnum | UnknownEnum;
};