import { SectionCard, SectionCardRow, TextInput } from 'components';
import React from 'react';
import { OrderPageSection, OrderPageSectionRowId } from '../types';
import { OrderSectionCardRowEditable } from '../components/OrderSectionCardRowEditable.component';
import { updateCustomer } from 'api/actions';
import { processFormValueUpdate } from 'helpers';
import { object, string } from 'yup';
import { useOrderOutletContext } from '../Order.base';
import { phoneRegex } from 'validation';

export const CustomerSection = () => {
  const { order } = useOrderOutletContext();
  const customer = order.customer;

  if (!customer) {
    return null;
  }

  return (
    <SectionCard title="Customer" id={OrderPageSection.OVERVIEW}>
      <OrderSectionCardRowEditable
        title="First Name"
        rowId={OrderPageSectionRowId.CUSTOMER_FIRST_NAME}
        formikProps={{
          onSubmit: (values) => updateCustomer(customer._id, { firstName: values.firstName?.trim() }),
          initialValues: { firstName: customer.firstName ?? '' },
          validationSchema: object({ firstName: string().required('First Name Required') })
        }}
        form={<TextInput fullWidth name="firstName" label="First Name" />}
        formFullWidth
      >
        {customer.firstName}
      </OrderSectionCardRowEditable>
      <OrderSectionCardRowEditable
        title="Last Name"
        rowId={OrderPageSectionRowId.CUSTOMER_LAST_NAME}
        formikProps={{
          onSubmit: (values) => updateCustomer(customer._id, { lastName: values.lastName?.trim() }),
          initialValues: { lastName: customer.lastName ?? '' },
          validationSchema: object({ lastName: string().required('Last Name Required') })
        }}
        form={<TextInput fullWidth name="lastName" label="Last Name" />}
        formFullWidth
      >
        {customer.lastName}
      </OrderSectionCardRowEditable>
      <SectionCardRow title="Address">
        {customer.place.address}
      </SectionCardRow>
      <SectionCardRow title="Apt., suite, etc.">
        {customer.place.address2}
      </SectionCardRow>
      <OrderSectionCardRowEditable
        title="Phone"
        rowId={OrderPageSectionRowId.CUSTOMER_PHONE}
        formikProps={{
          onSubmit: (values) => updateCustomer(customer._id, { phone: processFormValueUpdate.string(values.phone) }),
          initialValues: { phone: customer.phone ?? '' },
          validationSchema: object({ phone: string().matches(phoneRegex, { message: 'Phone number is not valid', excludeEmptyString: true }).default('') })
        }}
        form={<TextInput fullWidth name="phone" label="Phone" />}
        formFullWidth
      >
        {customer.phone}
      </OrderSectionCardRowEditable>
      <OrderSectionCardRowEditable
        title="Email"
        rowId={OrderPageSectionRowId.CUSTOMER_EMAIL}
        formikProps={{
          onSubmit: (values) => updateCustomer(customer._id, { email: values.email.trim() }),
          initialValues: { email: customer.email ?? '' },
          validationSchema: object({ email: string().email('Invalid email').required('Email Required') })
        }}
        form={<TextInput fullWidth name="email" label="Email" />}
        formFullWidth
      >
        {customer.email}
      </OrderSectionCardRowEditable>
    </SectionCard>
  );
};
