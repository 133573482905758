import { authAxiosRequest } from 'api/axios';

type Substring = {
  length: number;
  offset: number;
};

export type AddressSuggestion = {
  description: string;
  matched_substrings: Substring[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: Substring[];
    secondary_text: string;
    secondary_text_matched_substrings: Substring[];
  };
  terms: {
    offset: number;
    value: string;
  }[];
  types: string[];
};

export type AddressSuggestionsResponse = {
  predictions: AddressSuggestion[];
};

export const getAddressSuggestions = (text: string) => {
  const urlEncodedText = new URLSearchParams({ text }).toString();

  return authAxiosRequest<AddressSuggestionsResponse>(`/api/google-api/addressSuggestions?${urlEncodedText}`);
};
