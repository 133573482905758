import React from 'react';
import { AddEventDateModal } from './AddEventDateModal.modal';
import { ConfirmResizeModal } from './ConfirmResizeModal.component';
import { CardContentContainer, EventDatesFullCalendar, useEventContext } from 'components';
import { useEvents } from 'queries';
import { EventAcceptanceStatusEnum } from 'api/resources';

export const EventDatesCalendar: React.FC = () => {
  const { data: events = [] } = useEvents();
  const { event } = useEventContext();
  const filteredEvents = events.filter(_event => _event._id === event._id || _event.team || _event.acceptanceStatus === EventAcceptanceStatusEnum.accepted);

  return (
    <CardContentContainer>
      <EventDatesFullCalendar
        events={filteredEvents}
        event={event}
        renderAddDateModal={(date, onClose) => (
          <AddEventDateModal
            onClose={onClose}
            fullDate={date}
            event={event}
          />
        )}
        renderResizeModal={(resizeObj, onClose) => (
          <ConfirmResizeModal onClose={onClose} resizeObj={resizeObj} />
        )}
      />
    </CardContentContainer>
  );
};
