/* eslint-disable react/no-unescaped-entities */
import { AcceptanceStatusChip, ApplicationPlatformChip, ApplicationStatusChip, CardContentMarkdown, EventApplicationDateRangeContent, MarkdownInput, ParticipationStatusChip, SectionCard, Select } from 'components';
import { Box, Chip, ListItem, ListItemText, Typography } from '@mui/material';
import { eventEnumHelpers, getSelectOptionsFromEnumHelper, getSelectOptionsFromEnumHelperWithIcon, processFormValueUpdate, removeUnchanged } from 'helpers';
import { DateService } from 'services';
import { useMemo } from 'react';
import { UnknownEnum } from 'types';
import { EventCommunicationLogTypeEnum, EventFutureInterestStatusEnum, EventAcceptanceStatusEnum, EventApplicationPlatformEnum, EventApplicationStatusEnum, EventParticipationStatusEnum } from 'api/resources';
import { createEventCommunicationLog, deleteEventCommunicationLog, updateEvent, updateEventCommunicationLog } from 'api/actions';
import { boolean, mixed, object, string } from 'yup';
import { useEventContext } from '../event.context';
import { EventSectionCardRowEditable, EventSectionCardRowEditableList } from '../components';
import { EventPageSection, EventPageSectionRowId } from '../types';
import { ApplicationDateRangeForm, EventCommunicationLogForm, eventCommunicationLogValidationSchema } from '../forms';

export const ApplicationSection: React.FC = () => {
  const { event } = useEventContext();
  const futureInterestStatusOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.futureInterestStatus), []);
  const participationStatusOptions = useMemo(() => {
    const options = getSelectOptionsFromEnumHelper(eventEnumHelpers.participationStatus);

    return options.map(option => {
      if (option.value === EventParticipationStatusEnum.participating && event.acceptanceStatus !== EventAcceptanceStatusEnum.accepted) {
        return { ...option, disabled: true };
      }

      return option;
    });
  }, [ event.acceptanceStatus ]);
  const applicationStatusOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.applicationStatus), []);
  const acceptanceStatusOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.acceptanceStatus), []);
  const applicationPlatformOptions = useMemo(() => getSelectOptionsFromEnumHelperWithIcon(eventEnumHelpers.applicationPlatform), []);

  return (
    <SectionCard title="Application" id={EventPageSection.APPLICATION}>
      <EventSectionCardRowEditable
        title="Participation Status"
        rowId={EventPageSectionRowId.PARTICIPATION_STATUS}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { participationStatus: processFormValueUpdate.enumWithUnknown(values.participationStatus) }),
          initialValues: { participationStatus: event.participationStatus ?? UnknownEnum.unknown },
          validationSchema: object({  participationStatus: mixed<EventParticipationStatusEnum | UnknownEnum>().oneOf(eventEnumHelpers.participationStatus.enumValues).required() })
        }}
        form={(
          <Select
            id="participationStatus"
            name="participationStatus"
            label="Participation Status *"
            options={participationStatusOptions}
            autoFocus
            SelectProps={{
              defaultOpen: true
            }}
          />
        )}
      >
        <ParticipationStatusChip value={event.participationStatus} />
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="App. Date Range"
        rowId={EventPageSectionRowId.APPLICATION_DATE_RANGE}
        formikProps={{
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEvent(event._id, {
              applicationOpenDate: updates.applicationOpenDate,
              applicationIsOpen: updates.applicationIsOpen,
              applicationDeadlineDate: updates.applicationDeadlineDate,
              applicationOpenUntilFull: updates.applicationOpenUntilFull,
            });
          },
          initialValues: {
            applicationOpenDate: event.applicationOpenDate ?? null,
            applicationIsOpen: event.applicationIsOpen ?? false,
            applicationDeadlineDate: event.applicationDeadlineDate ?? null,
            applicationOpenUntilFull: event.applicationOpenUntilFull ?? false
          },
          validationSchema: object({
            applicationDeadlineDate: string().nullable().default(null),
            applicationIsOpen: boolean().required(),
            applicationOpenDate: string().nullable().default(null),
            applicationOpenUntilFull: boolean().required(),
          })
        }}
        form={<ApplicationDateRangeForm />}
      >
        <EventApplicationDateRangeContent applicationOpenDate={event.applicationOpenDate} applicationIsOpen={event.applicationIsOpen} applicationDeadlineDate={event.applicationDeadlineDate} applicationOpenUntilFull={event.applicationOpenUntilFull} />
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="App. Status"
        rowId={EventPageSectionRowId.APPLICATION_STATUS}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { applicationStatus: processFormValueUpdate.enumWithUnknown(values.applicationStatus) }),
          initialValues: { applicationStatus: event.applicationStatus ?? UnknownEnum.unknown },
          validationSchema: object({ applicationStatus: mixed<EventApplicationStatusEnum | UnknownEnum>().oneOf(eventEnumHelpers.applicationStatus.enumValues).required() })
        }}
        form={(
          <Select
            id="applicationStatus"
            name="applicationStatus"
            label="Application Status *"
            options={applicationStatusOptions}
            autoFocus
            SelectProps={{
              defaultOpen: true
            }}
          />
        )}
      >
        <ApplicationStatusChip value={event.applicationStatus} />
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="App. Platform"
        rowId={EventPageSectionRowId.APPLICATION_PLATFORM}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { applicationPlatform: processFormValueUpdate.enumWithUnknown(values.applicationPlatform) }),
          initialValues: { applicationPlatform: event.applicationPlatform ?? UnknownEnum.unknown },
          validationSchema: object({ applicationPlatform: mixed<EventApplicationPlatformEnum | UnknownEnum>().oneOf(eventEnumHelpers.applicationPlatform.enumValues).required() })
        }}
        form={(
          <Select
            id="applicationPlatform"
            name="applicationPlatform"
            label="Application Platform *"
            options={applicationPlatformOptions}
            autoFocus
            SelectProps={{
              defaultOpen: true
            }}
          />
        )}
      >
        <ApplicationPlatformChip value={event.applicationPlatform} />
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Acceptance Status"
        rowId={EventPageSectionRowId.ACCEPTANCE_STATUS}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { acceptanceStatus: processFormValueUpdate.enumWithUnknown(values.acceptanceStatus) }),
          initialValues: { acceptanceStatus: event.acceptanceStatus ?? UnknownEnum.unknown },
          validationSchema: object({ acceptanceStatus: mixed<EventAcceptanceStatusEnum | UnknownEnum>().oneOf(eventEnumHelpers.acceptanceStatus.enumValues).required() })
        }}
        form={(
          <Select
            id="acceptanceStatus"
            name="acceptanceStatus"
            label="Acceptance Status *"
            options={acceptanceStatusOptions}
            autoFocus
            SelectProps={{
              defaultOpen: true
            }}
          />
        )}
      >
        <AcceptanceStatusChip value={event.acceptanceStatus} />
      </EventSectionCardRowEditable>
      {(event.acceptanceStatus === EventAcceptanceStatusEnum.denied || event.acceptanceStatus === EventAcceptanceStatusEnum.waitListDenied) && (
        <EventSectionCardRowEditable
          title="App. Denied Reason"
          rowId={EventPageSectionRowId.APPLICATION_DENIED_REASON}
          formikProps={{
            onSubmit: (values) => updateEvent(event._id, { applicationDeniedReason: processFormValueUpdate.string(values.applicationDeniedReason) }),
            initialValues: { applicationDeniedReason: event.applicationDeniedReason ?? '' },
            validationSchema: object({ applicationDeniedReason: string().default('') })
          }}
          form={<MarkdownInput name="applicationDeniedReason" />}
          formFullWidth
        >
          <CardContentMarkdown markdown={event.applicationDeniedReason} />
        </EventSectionCardRowEditable>
      )}
      <EventSectionCardRowEditableList
        title="Communication Log"
        listItems={event.communicationLog ?? []}
        rowId={EventPageSectionRowId.COMMUNICATION_LOG}
        renderItem={(communicationLog) => {
          const Icon = eventEnumHelpers.communicationLog.getIcon(communicationLog.type);

          return (
            <ListItem components={{ Root: 'div' }} disableGutters disablePadding>
              <ListItemText
                sx={{ my: 0 }}
                primary={(
                  <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Icon fontSize="small" color="primary" /> {eventEnumHelpers.communicationLog.getLabel(communicationLog.type)}
                    </Box>
                    <Chip
                      color="primary"
                      variant="outlined"
                      size="small"
                      label={DateService.dayjs(communicationLog.date).fromNow()}
                      sx={{ bgcolor: theme => theme.palette.primary.background }}
                    />
                  </Box>
                )}
                secondaryTypographyProps={{ whiteSpace: 'pre-wrap' }}
                secondary={(
                  <>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {communicationLog.user && `${communicationLog.user.name} on `}
                      {DateService.getFormattedDate(communicationLog.date, 'MMM Do, YYYY h:mma')}
                    </Typography>
                    {communicationLog.note && ` - ${communicationLog.note}`}
                  </>
                )}
              />
            </ListItem>
          );
        }}
        form={<EventCommunicationLogForm />}
        createButtonLabel="Log"
        orderBy={{
          getField: (listItem) => listItem.date,
          direction: 'desc',
        }}
        getFormikProps={(listItem) => ({
          initialValues: {
            date: listItem.date,
            type: listItem.type,
            note: listItem.note ?? '',
          },
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEventCommunicationLog(event._id, listItem._id, updates);
          },
          validationSchema: eventCommunicationLogValidationSchema,
        })}
        createFormikProps={{
          initialValues: {
            date: DateService.dayjs().toISOString(),
            type: EventCommunicationLogTypeEnum.other,
            note: '',
          },
          onSubmit: (values) => createEventCommunicationLog(event._id, values),
          validationSchema: eventCommunicationLogValidationSchema,
        }}
        deleteMutationFn={(log) => deleteEventCommunicationLog(event._id, log._id)}
      />
      <EventSectionCardRowEditable
        title="Future Interest Status"
        rowId={EventPageSectionRowId.FUTURE_INTEREST_STATUS}
        formikProps={{
          initialValues: { futureInterestStatus: event.futureInterestStatus ?? UnknownEnum.unknown },
          onSubmit: values => updateEvent(event._id, { futureInterestStatus: processFormValueUpdate.enumWithUnknown(values.futureInterestStatus) }),
          validationSchema: object({ futureInterestStatus: mixed<EventFutureInterestStatusEnum | UnknownEnum>().oneOf(eventEnumHelpers.futureInterestStatus.enumValues).required() })
        }}
        form={(
          <Select
            name="futureInterestStatus"
            label="Future Interest Status"
            options={futureInterestStatusOptions}
            autoFocus
            helperText={(
              <div>
                <p><strong>'Yes, repeat':</strong> Select this option to automatically duplicate the event for the next two years. Each duplicated instance will also have the status 'Yes, repeat' and will automatically get duplicated in future. This ensures the event is continuously scheduled and evaluated the same way in subsequent years.</p>

                <p><strong>'Yes, ask again next year':</strong> Choose this option to duplicate the event only once. The duplicated instance will have an 'Unknown' status, indicating that its future interest needs to be reassessed next year.</p>

                <p><strong>'No':</strong> Selecting this will ensure that the event does not get duplicated and it will be removed from the duplicate events list. This is suitable for events that we are not interested in doing again.</p>

                <p><strong>'Unknown':</strong> This status does nothing and retains the event's current state until another option is selected. Use this if a decision on the event’s recurrence is pending.</p>
              </div>
            )}
          />
        )}
      >
        {eventEnumHelpers.futureInterestStatus.getLabel(event.futureInterestStatus)}
      </EventSectionCardRowEditable>
    </SectionCard>
  );
};