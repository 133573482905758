import { Storefront, EventDate, Event, Order, Sale } from 'api/resources';
import { ResourceWithPopulated, PickFromResource } from 'api/resources/resources.types';
import { DateService } from 'services';

export type GetSaleDateAndPointContentSale = ResourceWithPopulated<Pick<Sale, 'order'>, {
  order: ResourceWithPopulated<Pick<Order, 'event' | 'eventDate' | 'storefront'>, {
    storefront?: PickFromResource<Storefront, 'name'>;
    event?: PickFromResource<Event, 'name'>;
    eventDate?: PickFromResource<EventDate, 'dateAsUtc'>;
  }>;
  saleDate: string;
}>;

export const getSaleDateAndPointContent = (sale: GetSaleDateAndPointContentSale) => {
  const { event, eventDate, storefront } = sale.order;

  if (event && eventDate) {
    const eventDateDisplay = DateService.getFormattedDate(DateService.dayjsTz(eventDate.dateAsUtc), 'ddd, MMM Do, YYYY');

    return `${eventDateDisplay} • ${event.name}`;
  }

  if(storefront) {
    const saleDate = DateService.getFormattedDate(DateService.dayjsTz(sale.saleDate), 'ddd, MMM Do, YYYY');

    return `${saleDate} • ${storefront.name}`;
  }

  return null;
};