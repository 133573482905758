import { EnumHelpers } from 'helpers/enums/types';
import { SalesPerTableRowGetIdAndNameType, TSalesTablePerTypeRow, TSalesTableRow } from './types';

const getResourceAmountHash = <T extends TSalesTableRow>(sales: T[], getIdAndName: SalesPerTableRowGetIdAndNameType<T>): { [key: string]: TSalesTablePerTypeRow } => {
  return sales.reduce((r, sale) => {
    const { id, name } = getIdAndName(sale);

    const prev = r?.[id] ?? null;
    const salesCount = (prev?.salesCount ?? 0) + 1;
    const salesRevenue = (prev?.salesRevenue ?? 0) + sale.salePrice;

    return {
      ...r,
      [id]: {
        salesCount,
        salesRevenue,
        name,
        _id: id,
      },
    };
  }, {} as { [key: string]: TSalesTablePerTypeRow });
};

export const transformSalesForTablePerType = <T extends TSalesTableRow>(sales: T[], getIdAndName: SalesPerTableRowGetIdAndNameType<T>): TSalesTablePerTypeRow[] => {
  return Object.values(getResourceAmountHash(sales, getIdAndName));
};

export const enum SalesTableTypeEnum {
  all = 'all',
  product = 'product',
  team = 'team',
  event = 'event',
}

export const salesTableTypeEnumHelper: EnumHelpers<SalesTableTypeEnum> = {
  enumValues: [
    SalesTableTypeEnum.all,
    SalesTableTypeEnum.product,
    SalesTableTypeEnum.team,
    SalesTableTypeEnum.event,
  ],
  getLabel: (value) => {
    switch (value) {
      case SalesTableTypeEnum.product:
        return 'Per Product';
      case SalesTableTypeEnum.team:
        return 'Per Team';
      case SalesTableTypeEnum.event:
        return 'Per Event';
      default:
      case SalesTableTypeEnum.all:
        return 'All';
    }
  },
  getColor: () => 'unknown',
};