import { GridRowModesModel, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { UpdateEventsInput, updateEvents } from 'api/actions';
import { useAlertSnackbar, EditModeActionsBar, EditModeToggler, Table, FilterButton } from 'components';
import { getEventsTableEditableColumns } from 'components/Table/columns/get-events-table-editable-columns';
import { transformEventsForTableEditable, processFormValueUpdate, removeUnchanged } from 'helpers';
import { EventsManagementPageContext } from 'pages/EventsManagement/EventsManagement.page';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import { useEventsManagementFilter } from 'pages/EventsManagement/hooks/useEventsManagementFilter.hook';
import { QUERY_KEY } from 'queries/query-keys';
import { useContext, useState, useCallback, useMemo } from 'react';
import { TEventsTableEditableRow } from 'types';

export type EventsListTableEditableProps = {
  setEditMode: (isInEditMode: boolean) => void;
};

const getUpdatedRows = (rows: TEventsTableEditableRow[], apiRef: React.MutableRefObject<GridApiPro>) => {
  return rows.filter(row => apiRef.current.getRowMode(row._id) === GridRowModes.Edit).map(row => apiRef.current.getRowWithUpdatedValues(row._id, '') as TEventsTableEditableRow);
};

const transformRowToUpdate = (updatedRows: TEventsTableEditableRow[], initialRows: TEventsTableEditableRow[]): UpdateEventsInput => {

  return updatedRows.reduce((acc: UpdateEventsInput, row) => {
    const initialRow = initialRows.find(initialRow => initialRow._id === row._id);

    if (initialRow) {
      const updates = removeUnchanged(row, initialRow);

      return [
        ...acc,
        {
          _id: row._id,
          spaceSize: updates.spaceSize ?? '',
          attendance: processFormValueUpdate.number(updates.attendance),
          applicationOpenDate: updates.applicationOpenTimeframe?.[0],
          applicationDeadlineDate: updates.applicationOpenTimeframe?.[1],
          participationStatus: processFormValueUpdate.enumWithUnknown(updates.participationStatus),
          acceptanceStatus: processFormValueUpdate.enumWithUnknown(updates.acceptanceStatus),
          applicationStatus: processFormValueUpdate.enumWithUnknown(updates.applicationStatus),
          applicationPlatform: processFormValueUpdate.enumWithUnknown(updates.applicationPlatform),
        }
      ];
    }

    return acc;
  }, []);
};

export const EventsListTableEditable: React.FC<EventsListTableEditableProps> = props => {
  const { events, eventsLoading: loading } = useContext(EventsManagementPageContext);
  const [ rowModesModel, setRowModesModel ] = useState<GridRowModesModel>({});
  const snackbar = useAlertSnackbar();
  const apiRef = useGridApiRef();
  const queryClient = useQueryClient();

  const updateMutation = useMutation({
    mutationFn: updateEvents,
    onSuccess: async (_, updates) => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENTS);

      snackbar.success(`Event${updates.length > 1 ? 's' : ''} updated`);
    }
  });

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  }, []);

  const { modal, setShowModal, filteredRows } = useEventsManagementFilter({ events, nodeId: EventsManagementTreeViewNodeIdEnum.eventsProSearch });

  const rows = useMemo(() => transformEventsForTableEditable(filteredRows), [ filteredRows ]);
  const columns = useMemo(() => getEventsTableEditableColumns(setRowModesModel), [ setRowModesModel ]);

  const processRowUpdate = useCallback(async (newRow: TEventsTableEditableRow) => {
    const updates = transformRowToUpdate([ newRow ], rows);

    await updateMutation.mutateAsync(updates);

    return newRow;
  }, [ rows, updateMutation ]);

  const hasEdits = Object.values(rowModesModel).some((value) => value.mode === 'edit');
  const saveDisabled = !hasEdits || updateMutation.isLoading;
  const resetDisabled = saveDisabled;

  const resetHandler = useCallback(() => {
    Object.keys(rowModesModel).forEach((key) => {
      setRowModesModel((rowModesModel) => ({ ...rowModesModel, [key]: { mode: GridRowModes.View, ignoreModifications: true } }));
    });
  }, [ rowModesModel ]);

  const saveChangesHandler = useCallback(() => {
    const updatedRows = getUpdatedRows(rows, apiRef);
    const updates = transformRowToUpdate(updatedRows, rows);

    updateMutation.mutate(updates, { onSuccess: () => resetHandler() });
  }, [ apiRef, resetHandler, rows, updateMutation ]);

  return (
    <>
      {modal}
      <EditModeActionsBar
        onSaveChanges={saveChangesHandler}
        onReset={resetHandler}
        resetDisabled={resetDisabled}
        saveDisabled={saveDisabled}
        actions={modal && <FilterButton onClick={() => setShowModal(true)} disabled={hasEdits} />}
        editModeToggler={<EditModeToggler isEditMode setIsEditMode={props.setEditMode} disabled={hasEdits} />}
      />
      <Table
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        pinnedColumns={{ right: [ 'actions' ] }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={setRowModesModel}
        getRowId={(x) => x._id}
        loading={updateMutation.isLoading || loading}
        processRowUpdate={processRowUpdate}
        onRowEditStop={handleRowEditStop}
        pagination
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 30 } },
        }}
      />
    </>
  );
};