import { Box, Button, Card, Container, Divider, ToggleButton, Typography } from '@mui/material';
import { CardContentContainer, Loading, PasswordTextInput, PinInput, ToggleButtonGroup, useAlertSnackbar, validateUserCredentialsSchema } from 'components';
import { Form, Formik, FormikHelpers } from 'formik';
import { useLoggingPortalContext } from 'pages/LoggingPortal/LoggingPortal.base';
import { formSx } from 'styles';
import { ResourceForm } from 'types';
import { useLogSaleContext } from '../utils';
import { useMutation } from '@tanstack/react-query';
import { validateCredentials } from 'api/actions';
import { useNavigate } from 'react-router-dom';

export type ValidateUserProps = {
  userId: string;
};

export const ValidateUser: React.FC<ValidateUserProps> = props => {
  const navigate = useNavigate();
  const { state, onClearAndExit, onValidateUser } = useLogSaleContext();
  const { userOptions } = useLoggingPortalContext();
  const alert = useAlertSnackbar();
  const validateMutation = useMutation({
    mutationFn: (form: ResourceForm['validateCredentials']) => {
      if (form.credentialType === 'password') {
        return validateCredentials({ userId: form.userId, password: form.password });
      } else {
        return validateCredentials({ userId: form.userId, pin: form.pin });
      }
    },
    onSuccess: (_, form) => {
      onValidateUser(form.userId);
    },
    onError: () => {
      alert.error('Invalid Credentials. Please, try again.');
    }
  });

  const initialValues: ResourceForm['validateCredentials'] = {
    userId: props.userId,
    credentialType: 'pin',
    pin: '',
    password: '',
  };

  const userName = userOptions.find(user => user._id === props.userId)?.name;
  const validatedUser = userOptions.find(user => user._id === state.validatedUser?._id);

  const onSubmit = async (values: ResourceForm['validateCredentials'], formikHelpers: FormikHelpers<ResourceForm['validateCredentials']>) => {
    try {
      await validateMutation.mutateAsync(values);
    } catch (error) {
      formikHelpers.setValues(values => ({ ...values, pin: '', password: '' }));
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Container sx={{ flex: 1, pt: { xs: 3, lg: 4 }, pb: 10 }} maxWidth="sm">
      <Box mx={{ xs: -2, sm: 0 }} display="flex" flexDirection="column" gap={2}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validateUserCredentialsSchema}
          enableReinitialize
        >
          {formik => {
            const credentialType = formik.values.credentialType;

            if (formik.isSubmitting) {
              return <Loading />;
            }

            return (
              <CardContentContainer title={`Validate Credentials – ${userName}`}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <Box sx={formSx.formGroup}>
                    <ToggleButtonGroup
                      name="credentialType"
                      color="primary"
                      exclusive
                      fullWidth
                      label="Select which crendential input to use"
                    >
                      <ToggleButton value="pin">Use Pin</ToggleButton>
                      <ToggleButton value="password">Use Password</ToggleButton>
                    </ToggleButtonGroup>
                    {credentialType === 'pin' && (
                      <PinInput
                        label="Pin"
                        name="pin"
                        autoFocus
                        onComplete={(value) => {
                          formik.setSubmitting(true);
                          onSubmit({ ...formik.values, pin: value }, formik);
                        }}
                        clearOnComplete
                      />
                    )}
                    {credentialType === 'password' && <PasswordTextInput label="Password" name="password" autoFocus autoComplete="off" />}
                  </Box>
                  <Box mt={3} mb={-1}>
                    <Divider sx={{ mx: -2 }} />
                    <Box display="flex" alignItems="center" pt={2} gap={2}>
                      <Button sx={{ flex: 1 }} color="inherit" disabled={formik.isSubmitting} onClick={() => onClearAndExit()}>Cancel Logging</Button>
                      <Button sx={{ flex: 1 }} variant="contained" type="submit" disabled={formik.isSubmitting}>Confirm</Button>
                    </Box>
                  </Box>
                </Form>
              </CardContentContainer>
            );
          }}
        </Formik>
        {validatedUser && (
          <Card variant="outlined">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} flexWrap="wrap" gap={2}>
              <Typography>
                    Or, continue as already validated user:
              </Typography>
              <Button variant="outlined" onClick={() => navigate(`?user=${validatedUser._id}`)}>Continue logging as {validatedUser.name}</Button>
            </Box>
          </Card>
        )}
      </Box>
    </Container>
  );
};