import { FilterListOutlined } from '@mui/icons-material';
import { Badge, Button, ButtonProps } from '@mui/material';

export type FilterButtonProps = ButtonProps & {
  nonDefaultBadge?: boolean;
};

export const FilterButton: React.FC<FilterButtonProps> = props => {
  return (
    <Badge color="primary" variant="dot" invisible={!props.nonDefaultBadge}>
      <Button variant="outlined" startIcon={<FilterListOutlined />} {...props}>
        Filters
      </Button>
    </Badge>
  );
};