import { Box } from '@mui/material';
import { RadioGroupYesNoBoolean, Select } from 'components';
import { useFormikContext } from 'formik';
import { getSelectOptionsFromEnumHelper, eventEnumHelpers } from 'helpers';
import { useMemo } from 'react';
import { formSx } from 'styles';
import { EventSectionRowForm } from '../types';
import { YesNoEnum } from 'types';

export const EventInsuranceForm = () => {
  const { values } = useFormikContext<EventSectionRowForm['insurance']>();
  const insuranceStatusOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.insuranceStatus), []);
  const insuranceSharedOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.insuranceShared), []);
  const isRequired = values.isRequired;

  return (
    <Box sx={formSx.formGroup}>
      <RadioGroupYesNoBoolean
        name="isRequired"
        label="Insurance Required *"
        includeUnknown
        row
      />
      {isRequired === YesNoEnum.yes && (
        <>
          <Select
            id="insuranceStatus"
            name="status"
            label="Insurance Status *"
            options={insuranceStatusOptions}
          />
          <Select
            id="insuranceShared"
            name="shared"
            label="Insurance Shared *"
            options={insuranceSharedOptions}
          />
        </>
      )}
    </Box>
  );
};
