import isEqual from 'lodash/isEqual';
import { FilterValidFilterObject, FilterFieldConfiguration, YearInputValue } from './useFilter.hook';
import { Box } from '@mui/material';

export const getNonDefaultFilterDescription = (filter: FilterValidFilterObject, filterConfiguration: FilterFieldConfiguration) => {
  if (filterConfiguration.type === 'checkbox') {
    const defaultValue = filterConfiguration.defaultValue ?? false;
    const currentValue = (filter[filterConfiguration.field] ?? false) as boolean;

    if (defaultValue !== currentValue) {
      return currentValue ? <b>Checked</b> : <b>Unchecked</b>;
    }
  }
  if (filterConfiguration.type === 'enum') {
    const options = filterConfiguration.options;
    const defaultValue = filterConfiguration.defaultValue ?? [];
    const currentValue = filter[filterConfiguration.field] as string[];

    const { checked, unchecked } = options.reduce((r: { checked: string[]; unchecked: string[] }, value) => {
      const checked = currentValue.includes(value);
      const checkedByDefault = defaultValue?.includes(value);

      if (checked !== checkedByDefault) {
        if (checked) {
          r.checked.push(value);
        } else {
          r.unchecked.push(value);
        }
      }

      return r;
    }, { checked: [], unchecked: [] });

    if (checked.length || unchecked.length) {
      return (
        <>
          {!!checked.length && <><br /> - <b>{checked.map(value => filterConfiguration.getOptionLabel(value)).join(', ')}</b> (checked)</>}
          {!!unchecked.length &&  <><br /> - <b>{unchecked.map(value => filterConfiguration.getOptionLabel(value)).join(', ')}</b> (unchecked)</>}
        </>
      );
    }
  }
  if (filterConfiguration.type === 'resource') {
    const defaultValue = filterConfiguration.defaultValue;
    const currentValue = filter[filterConfiguration.field] as string[] | 'any';

    if (defaultValue && !isEqual(defaultValue, currentValue)) {
      const defaultLabel = defaultValue === 'any' ? 'Any' : defaultValue.map(value => {
        if (value === 'notSelected') {
          return 'Not Selected';
        }

        const label = filterConfiguration.options.find(option => option._id === value)?.label;

        return label;
      }).join(', ');
      const currentLabel = currentValue === 'any' ? 'Any' : currentValue.map(value => {
        if (value === 'notSelected') {
          return 'Not Selected';
        }

        const label = filterConfiguration.options.find(option => option._id === value)?.label;

        return label;
      }).join(', ');

      return <><b>{currentLabel}</b>, default is <b>{defaultLabel}</b></>;
    }
  }
  if (filterConfiguration.type === 'year') {
    const defaultValue = filterConfiguration.defaultValue;
    const currentValue = filter[filterConfiguration.field] as YearInputValue<string>;

    if(defaultValue !== currentValue) {
      const defaultLabel = typeof defaultValue === 'number' ? defaultValue :
        filterConfiguration.options.find(option => option.value === defaultValue)?.label;
      const currentLabel = typeof currentValue === 'number' ? currentValue :
        filterConfiguration.options.find(option => option.value === currentValue)?.label;

      return <><b>{currentLabel}</b>, default is <b>{defaultLabel}</b></>;
    }
  }

  return null;
};

export const getNonDefaultFilterDisplay = (filter: FilterValidFilterObject, filterConfigurations: FilterFieldConfiguration[]) => {
  const content = filterConfigurations.map(config => {
    const description = getNonDefaultFilterDescription(filter, config);

    if (description) {
      return <Box key={config.field}><u>{config.label}</u>: {description}<br /></Box>;
    };

    return null;
  }).filter(value => value);

  if (content.length) {
    return content;
  }

  return null;
};