import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getShopifyProduct, GetShopifyProductResponse, getShopifyProducts, GetShopifyProductsResopnse } from 'api/actions';

export const useShopifyProduct = (shopifyProductId: number, opts?: UseQueryOptions<GetShopifyProductResponse['data']>) => {
  return useQuery(QUERY_KEY.SHOPIFY_PRODUCT(shopifyProductId), async () => {
    const response = await getShopifyProduct(shopifyProductId);

    return response.data;
  }, opts);
};

export const useShopifyProducts = (shopifyProductIds: number[], opts?: UseQueryOptions<GetShopifyProductsResopnse['data']>) => {
  return useQuery(QUERY_KEY.SHOPIFY_PRODUCTS(shopifyProductIds), async () => {
    const response = await getShopifyProducts(shopifyProductIds);

    return response.data;
  }, opts);
};