import { SectionCard } from 'components';
import { eventEnumHelpers, processFormValueUpdate, removeUnchanged } from 'helpers';
import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { EventSectionCardRowEditable } from '../components';
import { UnknownEnum, yesNoUnknownSchema } from 'types';
import { updateEvent } from 'api/actions';
import { mixed, object } from 'yup';
import { EventInsuranceSharedEnum, EventInsuranceStatusEnum } from 'api/resources';
import { EventInsuranceForm } from '../forms';
import { useEventContext } from '../event.context';
import { EventPageSection, EventPageSectionRowId } from '../types';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';

export const InsuranceSection: React.FC = () => {
  const { event } = useEventContext();

  const content = useMemo(() => {
    if (event.insurance?.isRequired) {

      return (
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap={2}>
            <Box>
              <Typography fontSize="small" color="text.secondary">Status</Typography>
              <Typography>{eventEnumHelpers.insuranceStatus.getLabel(event.insurance?.status)}</Typography>
            </Box>
            <Box>
              <Typography fontSize="small" color="text.secondary">Shared</Typography>
              <Typography>{eventEnumHelpers.insuranceShared.getLabel(event.insurance?.shared)}</Typography>
            </Box>
          </Box>
        </Box>
      );
    }

    return event.insurance?.isRequired === false ? 'Not Required' : '';
  }, [ event.insurance?.isRequired, event.insurance?.shared, event.insurance?.status ]);

  return (
    <SectionCard title="Insurance" id={EventPageSection.INSURANCE}>
      <EventSectionCardRowEditable
        title="Insurance"
        rowId={EventPageSectionRowId.INSURANCE}
        formikProps={{
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEvent(event._id, {
              insurance: {
                isRequired: processFormValueUpdate.yesNoUnknown(updates.isRequired),
                status: processFormValueUpdate.enumWithUnknown(updates.status),
                shared: processFormValueUpdate.enumWithUnknown(updates.shared),
              }
            });
          },
          initialValues: {
            isRequired: yesNoEnumHelpers.yesNo.getEnumValue(event.insurance?.isRequired),
            shared: event.insurance?.shared ?? UnknownEnum.unknown,
            status: event.insurance?.status ?? UnknownEnum.unknown
          },
          validationSchema: object({
            isRequired: yesNoUnknownSchema,
            status: mixed<EventInsuranceStatusEnum | UnknownEnum>().oneOf(eventEnumHelpers.insuranceStatus.enumValues).required(),
            shared: mixed<EventInsuranceSharedEnum | UnknownEnum>().oneOf(eventEnumHelpers.insuranceShared.enumValues).required(),
          })
        }}
        form={<EventInsuranceForm />}
      >
        {content}
      </EventSectionCardRowEditable>
    </SectionCard>
  );
};