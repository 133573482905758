import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { Table } from 'components';
import { DateService, currencyFormatter } from 'services';
import { GetSalesForEventResponse } from 'api/actions';
import { Box } from '@mui/material';
import { MoneyOffOutlined } from '@mui/icons-material';
import { useTableActionColumn } from 'hooks';
import { ROUTING_CONFIG } from 'constants/routing-config';

export type EventSalesTableType = {
  sales: GetSalesForEventResponse['data'];
  loading?: boolean;
};

const getEventSalesTableColumns = (sales: GetSalesForEventResponse['data']): GridColDef<GetSalesForEventResponse['data'][number]>[] => {
  const orders = sales.reduce((r: NonNullable<GetSalesForEventResponse['data'][number]['order']>[], sale) => {
    if(sale.order && !r.some(order => order._id === sale.order?._id)) {
      return [ ...r, sale.order ];
    }

    return r;
  }, []);

  return [
    {
      field: 'ordinal',
      headerName: '#',
      valueGetter: ({ row }) => `#${sales.length - sales.findIndex(sale => sale._id === row._id)}`,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: '_id',
      headerName: 'Sale Id',
    },
    {
      field: 'order',
      headerName: 'Order',
      valueFormatter: (params: GridValueFormatterParams<GetSalesForEventResponse['data'][number]['order']>) => params.value
        ? `${params.value.name} (#${orders.length - orders.findIndex(order => order._id === params.value?._id)})`
        : '',
      width: 130,
    },
    {
      field: 'product',
      headerName: 'Product',
      valueGetter: ({ row }) => row.product.name,
      width: 200,
    },
    {
      field: 'frame',
      headerName: 'Frame',
      type: 'boolean',
      sortable: false,
      width: 100,
    },
    {
      field: 'salePrice',
      headerName: 'Sale Price',
      valueFormatter: (params: GridValueFormatterParams<GetSalesForEventResponse['data'][number]['salePrice']>) => {
        return currencyFormatter.format(params.value);
      },
      width: 100,
    },
    {
      field: 'payment',
      headerName: 'Payment Type',
      width: 120,
    },
    {
      field: 'eventDate',
      headerName: 'Event Date',
      valueGetter: ({ row }) => row.order.eventDate,
      valueFormatter: (params: GridValueFormatterParams<GetSalesForEventResponse['data'][number]['order']['eventDate']>) => {
        return DateService.getFormattedDate(DateService.dayjsTz(params.value.dateAsUtc), 'ddd, MMM Do, YYYY');
      },
      width: 150,
      type: 'date',
    },
    {
      field: 'createdAt',
      headerName: 'Logged At',
      valueGetter: ({ row }) => new Date(row.saleDate),
      renderCell: (params) => {
        const date = params.row.saleDate;

        return DateService.getFormattedDateAndTime(date, 'ddd, MMM Do, YYYY', params.row.order.event.timezone);
      },
      width: 220,
      type: 'date',
    },
  ];
};

export const EventSalesTable: React.FC<EventSalesTableType> = ({ sales, loading }) => {
  const { withActionColumn } = useTableActionColumn({ routeTo: ROUTING_CONFIG.salesLog });
  const columns = withActionColumn(getEventSalesTableColumns(sales));

  return (
    <Box height="600px">
      <Table
        getRowId={(x) => x._id}
        rows={sales}
        columns={columns}
        loading={loading}
        disableRowSelectionOnClick
        emptyIcon={<MoneyOffOutlined />}
        emptyMessage="No sales yet"
      />
    </Box>
  );
};
