import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card, CardContent, Divider, Fade, Grid, IconButton, Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { EventSalesDatesList } from './EventSalesDatesList.component';
import { EventSalesLogProductList } from './EventSalesLogProductList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'queries/query-keys';
import { SalesTabComponentProps } from '../../EventSales.page';
import { EventSalesTable } from '../EventSalesDisplay';
import { PaymentTypeSelect, SaleProductListItem, ShipmentForm, ShipmentInfo, shipmentInfoFormValidationSchema, useEventContext } from 'components';
import { currencyFormatter, DateService } from 'services';
import { createLiveSaleForEventDate, CreateLiveSaleForEventDateInput, GetProductsResponse } from 'api/actions';
import { missingImg } from 'assets';
import { saleEnumHelpers } from 'helpers';
import { FrameEnum } from 'types';
import { OrderPaymentEnum, OrderTypeEnum } from 'api/resources';
import { DeleteOutlined } from '@mui/icons-material';
import { useCurrentUser } from 'contexts';
import _ from 'lodash';
import { Formik } from 'formik';

export type EventSalesLogSale = Omit<CreateLiveSaleForEventDateInput['sales'][number], 'product'> & { id: number; product: GetProductsResponse['data'][number]; imageUrl?: string };
export type OnAddProductHandler = (values: Omit<EventSalesLogSale, 'id'>) => Promise<void>;

export const EventSalesLog: React.FC<SalesTabComponentProps> = ({ sales, loading }) => {
  const { _id } = useCurrentUser();
  const queryClient = useQueryClient();
  const { event } = useEventContext();
  const orderMutation = useMutation({
    mutationFn: createLiveSaleForEventDate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY.SALES_FOR_EVENT(event._id) });
    }
  });
  const [ selectedDateId, setSelectedDateId ] = useState<string | null>(null);
  const [ logSales, setLogSales ] = useState<EventSalesLogSale[]>([]);
  const [ paymentType, setPaymentType ] = useState<OrderPaymentEnum | null>(null);

  const onDateChange = (id: string) => setSelectedDateId((p) => (p === id ? null : id));

  const onDeleteLogSale = (id: number) => {
    setLogSales(prev => prev.filter(sale => sale.id !== id));
  };

  const onAddProductHandler: OnAddProductHandler = async (values) => {
    if (!selectedDateId) {
      return;
    }

    setLogSales(prev => [ ...prev, { ...values, id: prev.length + 1 } ]);
  };

  const handleLogOrder = (customerValues?: ShipmentInfo) => {
    orderMutation.mutate({
      user: _id,
      type: OrderTypeEnum.manual,
      payment: paymentType!,
      sales: logSales.map(sale => ({ ..._.omit(sale, [ 'id', 'imageUrl' ]), product: sale.product._id })),
      eventDate: selectedDateId!,
      customer: customerValues
    });

    setLogSales([]);
    setPaymentType(null);
  };

  const filteredSales = useMemo(() => {
    if (!selectedDateId) {
      return [];
    }

    return sales.filter((sale) => sale.order.eventDate._id === selectedDateId);
  }, [ selectedDateId, sales ]);

  const formattedDate = useMemo(() => {
    if (!selectedDateId) {
      return null;
    }

    const date = event.dates.find((date) => date._id === selectedDateId);

    if (!date) {
      return null;
    }
    return DateService.getFormattedDate(DateService.dayjsTz(date?.dateAsUtc), 'standardFull');
  }, [ selectedDateId, event.dates ]);

  const paymentTypeDisabled = logSales.length === 0;

  useEffect(() => {
    if (paymentTypeDisabled) {
      setPaymentType(null);
    }
  }, [ paymentTypeDisabled ]);

  if (!event.team) {
    return (
      <Alert
        severity="error"
        variant="outlined"
      >
        <AlertTitle>Select a team for this event before logging sales</AlertTitle>
      </Alert>
    );
  }

  const initialShipmentValues: ShipmentInfo = {
    firstName: '',
    lastName: '',
    place: {
      address: '',
      placeId: '',
      address2: '',
    },
    phone: '',
    email: '',
  };

  const shipmentRequired = logSales.some(sale => !!sale.shipping);

  const getPaymentTypeSelectDisabled = (paymentType: OrderPaymentEnum) => {
    if (paymentType === OrderPaymentEnum.cash) {
      if (logSales.some(sale => !sale.frame)) {
        return 'Only available for framed products';
      }
    }

    if (paymentType === OrderPaymentEnum.split) {
      return 'Split payments will be available soon';
    }

    return false;
  };

  return (
    <>
      <Typography variant="h6">1. Select a Date:</Typography>
      <Typography mb={1}>To log a sale for this event, first select the date of the sale</Typography>
      <Box mb={4}>
        <EventSalesDatesList
          datesFilter={[ 'past', 'today' ]}
          onEventDateClick={onDateChange}
          selectedDateId={selectedDateId}
          emptyMessage="As of now, there are no event dates to log sales for (you can only log sales for event dates that have passed)"
        />
      </Box>

      <Fade in={Boolean(selectedDateId)} unmountOnExit exit={false}>
        <div>
          <Typography variant="h6">2. Add Sales to the Log:</Typography>
          <Typography mb={1}>Find the products that were sold, click the arrow, and make the appropriate selections. Clicking &apos;ADD&apos; will add product to the order. When you are done with the order, click &apos;LOG ORDER&apos;</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} container spacing={1}>
              <Grid item xs={7}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" mb={2}>Products</Typography>
                    <Box ml={-2} mr={-2}>
                      <EventSalesLogProductList onAddProduct={onAddProductHandler} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={5}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" mb={2}>Order</Typography>
                    <Box ml={-2} mr={-2} mb={1}>
                      {logSales.map((sale) => (
                        <SaleProductListItem
                          key={sale.id}
                          name={sale.product.name}
                          isProductImagesDisplay={!sale.imageUrl}
                          imageUrl={sale.imageUrl ?? sale.product.imageUrl ?? missingImg}
                          secondary={`${currencyFormatter.format(sale.salePrice)} • ${saleEnumHelpers.frame.getLabel(sale.product ? FrameEnum.frame : FrameEnum.noFrame)}${sale.shipping ? ' • Shipping' : ''}`}
                          actions={(
                            <IconButton size="small" color="primary" onClick={() => onDeleteLogSale(sale.id)}>
                              <DeleteOutlined fontSize="small" />
                            </IconButton>
                          )}
                        />
                      ))}
                    </Box>
                    <Divider />
                    <Box mt={2}>
                      <PaymentTypeSelect
                        selected={paymentType}
                        onSelect={setPaymentType}
                        getDisabled={getPaymentTypeSelectDisabled}
                        fontSize={50}
                      />
                    </Box>
                    {shipmentRequired &&<Box my={2}><Divider /></Box>}
                    <Formik
                      initialValues={initialShipmentValues}
                      onSubmit={(values, formik) => {
                        handleLogOrder(values);
                        formik.resetForm();
                      }}
                      validationSchema={shipmentInfoFormValidationSchema}
                    >
                      {formik => (
                        <>
                          {shipmentRequired && <ShipmentForm size="small" />}
                          <Box display="flex" justifyContent="flex-end" mt={1}>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={shipmentRequired
                                ? () => formik.handleSubmit()
                                : () => handleLogOrder()}
                              disabled={!paymentType || logSales.length === 0}
                            >
                              Log Order
                            </Button>
                          </Box>
                        </>
                      )}
                    </Formik>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" alignItems="baseline" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'initial' }}>
                    <Typography variant="h6" mb={2}>Logged Sales</Typography>
                    {formattedDate && (
                      <Typography variant="body2">
                        {formattedDate}:&nbsp;{filteredSales.length}&nbsp;Sale{filteredSales.length === 1 ? '' : 's'}
                      </Typography>
                    )}
                  </Box>
                  <Box ml={-2} mr={-2}>
                    <EventSalesTable sales={filteredSales} loading={loading} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </>
  );
};
