import { OrderSaleFulfillmentTypeEnum } from 'api/resources';
import { StatusChipProps } from '../types';
import { StandardChip } from '../StandardChip.component';
import { orderEnumHelpers } from 'helpers';

export type OrderSaleFulfillmentTypeChipProps = StatusChipProps<OrderSaleFulfillmentTypeEnum>;

export const OrderSaleFulfillmentTypeChip: React.FC<OrderSaleFulfillmentTypeChipProps> = props => {
  return <StandardChip {...orderEnumHelpers.saleFulfillmentType} {...props} />;
};
