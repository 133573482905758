import { EnumHelpers } from 'helpers/enums/types';
import { DateRange } from 'components';
import { DateObjectType, DateService } from 'services';
import { AnalyticsChartData } from './useAnalyticsChart.hook';

// Analytics Timeline Chart

export const enum AnalyticsTimelineChartTimePeriodEnum {
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year'
}

export const getInitialAnalyticsTimelineChartTimePeriodForDateRange = (dateRange: DateRange) => {
  const dayDiff = dateRange?.end.diff(dateRange.start, 'day') ?? 0;
  const yearDiff = dateRange?.end.diff(dateRange.start, 'year') ?? 0;

  if(dayDiff <= 30) {
    return AnalyticsTimelineChartTimePeriodEnum.day;
  }
  if(dayDiff <= 90) {
    return AnalyticsTimelineChartTimePeriodEnum.week;
  }
  if(yearDiff < 2) {
    return AnalyticsTimelineChartTimePeriodEnum.month;
  }

  return AnalyticsTimelineChartTimePeriodEnum.year;
};

export const analyticsTimelineChartTimePeriodEnumHelpers: EnumHelpers<AnalyticsTimelineChartTimePeriodEnum> & {
  getDataTransformFormatStr: (timePeriod: AnalyticsTimelineChartTimePeriodEnum) => string;
  getTimePeriodDateRangeLabel: (timePeriod: AnalyticsTimelineChartTimePeriodEnum, timePeriodDateRange: NonNullable<DateRange>) => string;
  getTimePeriodDateRangeTooltipLabel: (timePeriod: AnalyticsTimelineChartTimePeriodEnum, timePeriodDateRange: NonNullable<DateRange>) => string;
  getDisabled: (timePeriod: AnalyticsTimelineChartTimePeriodEnum, dateRange: NonNullable<DateRange>) => boolean;
} = {
  enumValues: [
    AnalyticsTimelineChartTimePeriodEnum.hour,
    AnalyticsTimelineChartTimePeriodEnum.day,
    AnalyticsTimelineChartTimePeriodEnum.week,
    AnalyticsTimelineChartTimePeriodEnum.month,
    AnalyticsTimelineChartTimePeriodEnum.year
  ],
  getLabel: (timePeriod) => {
    switch (timePeriod) {
      case AnalyticsTimelineChartTimePeriodEnum.hour:
        return 'Hour';
      case AnalyticsTimelineChartTimePeriodEnum.day:
        return 'Day';
      case AnalyticsTimelineChartTimePeriodEnum.week:
        return 'Week';
      case AnalyticsTimelineChartTimePeriodEnum.month:
        return 'Month';
      default:
        return 'Year';
    }
  },
  getColor: () => 'unknown',
  getDataTransformFormatStr: (timePeriod) => {
    switch (timePeriod) {
      case AnalyticsTimelineChartTimePeriodEnum.hour:
        return 'HH';
      case AnalyticsTimelineChartTimePeriodEnum.day:
        return 'MM/DD/YYYY';
      case AnalyticsTimelineChartTimePeriodEnum.week:
        return 'MM/DD/YYYY';
      case AnalyticsTimelineChartTimePeriodEnum.month:
        return 'MM/DD/YYYY';
      default:
        return 'YYYY';
    }
  },
  getTimePeriodDateRangeLabel: (timePeriod, timePeriodDateRange) => {
    switch (timePeriod) {
      case AnalyticsTimelineChartTimePeriodEnum.hour:
        return timePeriodDateRange.start.format('h a');
      case AnalyticsTimelineChartTimePeriodEnum.day:
        return timePeriodDateRange.start.format('M/D');
      case AnalyticsTimelineChartTimePeriodEnum.week:
        let label = `${timePeriodDateRange.start.format('MMM D')}-${timePeriodDateRange.end.format('D')}`;

        if(!timePeriodDateRange.start.isSame(timePeriodDateRange.end, 'month')) {
          label = `${timePeriodDateRange.start.format('D')}-${timePeriodDateRange.end.format('MMM D')}`;
        }

        return label;
      case AnalyticsTimelineChartTimePeriodEnum.month:
        return timePeriodDateRange.start.format('M/YY');
      default:
        return timePeriodDateRange.start.format('YYYY');
    }
  },
  getTimePeriodDateRangeTooltipLabel: (timePeriod, timePeriodDateRange) => {
    switch (timePeriod) {
      case AnalyticsTimelineChartTimePeriodEnum.hour:
        return timePeriodDateRange.start.format('h a');
      case AnalyticsTimelineChartTimePeriodEnum.day:
        return timePeriodDateRange.start.format('M/D/YY');
      case AnalyticsTimelineChartTimePeriodEnum.week:
        const startLabel = timePeriodDateRange.start.format('MMM D');
        let endLabel = timePeriodDateRange.end.format('D');

        if(!timePeriodDateRange.start.isSame(timePeriodDateRange.end, 'month')) {
          endLabel = `${timePeriodDateRange.end.format('MMM D')}`;
        }

        if(timePeriodDateRange.start.isSame(timePeriodDateRange.end, 'year')) {
          return `${startLabel} - ${endLabel}, ${timePeriodDateRange.end.format('YYYY')}`;
        } else {
          return `${startLabel}, ${timePeriodDateRange.start.format('YYYY')}  - ${endLabel}, ${timePeriodDateRange.end.format('YYYY')}`;
        }
      case AnalyticsTimelineChartTimePeriodEnum.month:
        return timePeriodDateRange.start.format('M/YY');
      default:
        return timePeriodDateRange.start.format('YYYY');
    }
  },
  getDisabled: (timePeriod, dateRange) => {
    const diff = dateRange.end.diff(dateRange.start, 'day');

    if(diff === undefined) {
      return false;
    }
    switch (timePeriod) {
      case AnalyticsTimelineChartTimePeriodEnum.hour:
        return false;
      case AnalyticsTimelineChartTimePeriodEnum.day:
        return diff > 100;
      case AnalyticsTimelineChartTimePeriodEnum.week:
        return diff < 8 || diff > 500;
      case AnalyticsTimelineChartTimePeriodEnum.month:
        return diff < 31;
      default:
        return diff < 366;
    }
  }
};

export type GetTimelineDataProps<Data> = {
  data: Data[];
  filter?: (data: Data) => boolean;
  getDate: (data: Data) => DateObjectType;
  getValue: (data: Data) => number;
  dateRange: DateRange;
  timePeriod: AnalyticsTimelineChartTimePeriodEnum;
};

const getTimelineData = <Data extends unknown>({ data, filter, getDate, getValue, timePeriod, dateRange }: GetTimelineDataProps<Data>) => {
  if(!dateRange) {
    return { data: [] as number[], labels: [] as string[] };
  }

  const formatStr = analyticsTimelineChartTimePeriodEnumHelpers.getDataTransformFormatStr(timePeriod);

  const keyHash: Record<string, boolean> = {};

  const transformedData = Object.values(data
    .filter((element) => !filter || filter(element))
    .reduce((r, element) => {
      const key = getDate(element).startOf(timePeriod).format(formatStr);

      keyHash[key] = true;

      const prev = r?.[key] ?? null;
      const total = (prev?.value ?? 0) + getValue(element);

      return { ...r, [key]: {  date: getDate(element),  value: total },
      };
    }, {} as { [key: string]: { date: DateObjectType; value: number } }));

  if (dateRange.start && dateRange.end && transformedData.length) {
    if (timePeriod === AnalyticsTimelineChartTimePeriodEnum.hour) {
      for (let hour = 0; hour <= 23; hour = hour + 1) {
        if (!transformedData.some(element => element.date.hour() === hour)) {
          transformedData.push({ date: DateService.dayjs().set('hour', hour), value: 0 });
        }
      };
    } else {
      for (let date = dateRange.start.startOf(timePeriod); !date.isAfter(dateRange.end, timePeriod); date = date.add(1, timePeriod)) {
        const key = date.startOf(timePeriod).format(formatStr);

        if (!keyHash[key]) {
          transformedData.push({ date, value: 0 });
        }
      };
    }
  }

  transformedData.sort((a, b) => {
    if (timePeriod === AnalyticsTimelineChartTimePeriodEnum.hour) {
      return a.date.hour() - b.date.hour();
    }
    return a.date.isBefore(b.date, timePeriod) ? -1 : 1;
  });

  return {
    labels: transformedData.map(data => {
      const dateRange = { start: data.date.startOf(timePeriod), end: data.date.endOf(timePeriod) };

      return analyticsTimelineChartTimePeriodEnumHelpers.getTimePeriodDateRangeLabel(timePeriod, dateRange);
    }),
    data: transformedData.map(data => data.value),
  };
};

type HandleAddDatasetProps<Config> = {
  config: Config;
  r: {
    labels: string[];
    datasets: any[];
  };
  getData: (config: Config) => {labels: string[]; data: number[]};
  dateRange: DateRange;
  label?: string;
  referenceIndex?: number;
  stack?: string;
};

const handleAddDataset = <Config extends unknown>({ config, r, getData, dateRange, label, referenceIndex, stack }: HandleAddDatasetProps<Config>): AnalyticsChartData => {
  if(!dateRange) {
    return r;
  }

  const data = getData(config);
  const newR = r;

  if(!newR.labels?.length) {
    newR.labels = data.labels;
  }
  newR.datasets.push({ ...data, dateRange, label, referenceIndex, stack });
  return newR;
};

export type GetAnalyticsTimelineChartDataProps<Data> = {
  baseConfig: Pick<GetTimelineDataProps<Data>, 'data' | 'getValue' | 'getDate' | 'dateRange' | 'timePeriod'>;
  compareConfigs?: (Pick<GetTimelineDataProps<Data>, 'data' | 'dateRange'> & {label?: string})[];
  datasetsConfigs?: (Partial<Pick<GetTimelineDataProps<Data>, 'filter' | 'dateRange'>> & {label?: string})[];
};

export const getAnalyticsTimelineChartData = <Data extends unknown>({ baseConfig, compareConfigs, datasetsConfigs = [ { } ] }: GetAnalyticsTimelineChartDataProps<Data>): AnalyticsChartData => {
  if (baseConfig.dateRange && analyticsTimelineChartTimePeriodEnumHelpers.getDisabled(baseConfig.timePeriod, baseConfig.dateRange)) {
    return { datasets: [], labels: [] };
  }

  return datasetsConfigs.reduce((r, config) => {
    let newR = handleAddDataset({
      config: { ...baseConfig, ...config },
      r,
      getData: getTimelineData,
      dateRange: config.dateRange ?? baseConfig.dateRange,
      label: config.label
    });

    if(compareConfigs) {
      const referenceIndex = newR.datasets.length - 1;

      newR = compareConfigs.reduce((_r, compareConfig) => {
        return handleAddDataset({
          config: { ...baseConfig, ...compareConfig, ...config },
          r: _r,
          getData: getTimelineData,
          dateRange: compareConfig.dateRange,
          label: config.label && `Compare: ${config.label}`,
          referenceIndex,
        });
      }, newR);
    }

    return newR;
  }, { datasets: [], labels: [] } as AnalyticsChartData);
};

// Analytics Chart

export type GetStandardChartDataProps<Data> = {
  data: Data[];
  filter?: (item: Data) => boolean;
  getValue: (data: Data) => number;
  getLabel: (data: Data) => string;
  getId: (data: Data) => string;
};

export const getStandardChartData = <Data extends unknown>({ data, filter, getValue, getLabel, getId }: GetStandardChartDataProps<Data>) => {
  return Object.values(data.filter((item) => !filter || filter(item))
    .reduce((r, item) => {
      const key = getId(item);
      const prev = r?.[key] ?? null;
      const value = (prev?.value ?? 0) + getValue(item);

      return {
        ...r,
        [key]: { id: key, label: getLabel(item), value }
      };
    }, {} as { [id: string]: { id: string; label: string; value: number }}))
    .sort((a, b) => a.label < b.label ? -1 : 1)
    .reduce((r, element) => ({
      data: [ ...r.data, element.value ],
      labels: [ ...r.labels, element.label ],
      ids: [ ...r.ids, element.id ],
    }), { data: [], labels: [], ids: [] } as { data: number[]; labels: string[]; ids: string []});
};

export type GetAnalyticsChartDataProps<Data> = {
  baseConfig: Omit<GetStandardChartDataProps<Data>, 'filter'> & {dateRange: NonNullable<DateRange>};
  compareConfig?: Pick<GetStandardChartDataProps<Data>, 'data'> & {dateRange: NonNullable<DateRange>};
  datasetsConfigs?: (Pick<GetStandardChartDataProps<Data>, 'filter'> & {label?: string})[];
  stacked?: boolean;
};

export const getAnalyticsChartData = <Data>({ baseConfig, datasetsConfigs = [ { } ], compareConfig, stacked }: GetAnalyticsChartDataProps<Data>): AnalyticsChartData => {
  const finalResult = datasetsConfigs.reduce((r: AnalyticsChartData, config) => {
    const datasetArg: HandleAddDatasetProps<GetStandardChartDataProps<Data>> = {
      config: { ...baseConfig, ...config },
      r,
      getData: getStandardChartData,
      dateRange: baseConfig.dateRange,
      label: config.label,
    };

    if (stacked) {
      datasetArg.stack = 'Stack 1';
    }

    let newR = handleAddDataset(datasetArg);

    if(compareConfig) {
      const compareDatasetArg: HandleAddDatasetProps<GetStandardChartDataProps<Data>> = {
        config: { ...baseConfig, ...compareConfig, ...config },
        r: newR,
        getData: getStandardChartData,
        dateRange: compareConfig.dateRange,
        label: config.label && `Compare: ${config.label}`,
        referenceIndex: newR.datasets.length - 1,
      };

      if (stacked) {
        compareDatasetArg.stack = 'Stack 2';
      }

      newR = handleAddDataset(compareDatasetArg);
    }

    return newR;
  }, { datasets: [], labels: [] });

  // sort data in descending order
  if (finalResult.datasets.length > 0 && finalResult.datasets[0].data) {
    const firstDataset = finalResult.datasets[0];
    const indices = firstDataset.data.map((_, index) => index);

    indices.sort((a, b) => firstDataset.data[b] - firstDataset.data[a]);

    finalResult.labels = indices.map(index => finalResult.labels[index]);
    finalResult.datasets = finalResult.datasets.map(dataset => {
      const ids = dataset.ids;

      return {
        ...dataset,
        data: indices.map(index => dataset.data[index]),
        ids: ids && indices.map(index => ids[index]),
      };
    });
  }

  return finalResult;
};
