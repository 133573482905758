import React, { useMemo, useState } from 'react';
import { useEventOutletContext } from '../Event.base';
import { ReportCard, useEventContext } from 'components';
import { orderEnumHelpers } from 'helpers';
import { currencyFormatter, DateService } from 'services';
import { Box, Button, Collapse, Divider, Typography } from '@mui/material';
import { GetSalesForEventResponse } from 'api/actions';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { OrderPaymentEnum } from 'api/resources';

const getSalesPaymentsRows = (sales: GetSalesForEventResponse['data'], paymentTypes: OrderPaymentEnum[]) => {
  return {
    ...paymentTypes.reduce((r, payment) => {
      const paymentSales = sales.filter(sale => sale.order.payment === payment);

      return {
        ...r,
        [payment]: {
          salesCount: paymentSales.length,
          salesRevenue: paymentSales.reduce((acc, sale) =>  acc + sale.salePrice, 0),
        }
      };
    }, {} as Record<OrderPaymentEnum, { salesCount: number; salesRevenue: number }>),
    total: {
      salesCount: sales.length,
      salesRevenue: sales.reduce((acc, sale) => acc + sale.salePrice, 0),
    }
  };
};

export const EventDatesReconciliation = () => {
  const { eventSales } = useEventOutletContext();
  const { event } = useEventContext();
  const [ showAll, setShowAll ] = useState(false);

  const data = useMemo(() => {
    const paymentTypes = Array.from(new Set(eventSales.map(sale => sale.order.payment))).sort((a, b) => a < b ? -1 : 1);

    const dateRows = event.dates.map((date, index) => ({
      index,
      label: (
        <Box>
          <Typography>{DateService.dayjsTz(date.dateAsUtc).format('ddd, MMM Do')}</Typography>
          <Typography variant="body2" color="text.secondary">Day {index + 1} of {event.dates.length}</Typography>
        </Box>
      ),
      ...getSalesPaymentsRows(eventSales.filter(sale => sale.order.eventDate._id === date._id), paymentTypes),
    }));

    return {
      rows: dateRows,
      paymentTypes,
    };
  }, [ event.dates, eventSales ]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography mb={2} width="fit-content" sx={{ pb: 0, borderBottomColor: (theme) => theme.palette.grey[500], borderBottomStyle: 'dotted', borderBottomWidth: 3 }} >Reconciliation</Typography>
      <EventDateReconciliationRows rows={data.rows.slice(0, 3)} paymentTypes={data.paymentTypes}/>

      {data.rows.length > 3 && (
        <>
          <Collapse orientation="vertical" in={showAll}>
            <EventDateReconciliationRows rows={data.rows.slice(3)} paymentTypes={data.paymentTypes}/>
          </Collapse>
          <Box display="flex" justifyContent="center" my={1}>
            <Button onClick={() => setShowAll(p => !p)}>
              <Box display="flex" alignItems="center" gap={1}>
                {showAll ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />}
                {showAll ? 'Show less' : `Show more (${data.rows.length - 3})`}
              </Box>
            </Button>
          </Box>
        </>
      )}

      <EventDateReconciliationRows
        rows={[
          {
            index: 1,
            label:  (
              <Box>
                <Typography>Total</Typography>
                <Typography variant="body2" color="text.secondary">Sum of {event.dates.length} days</Typography>
              </Box>
            ),
            ...getSalesPaymentsRows(eventSales, data.paymentTypes),
          }
        ]}
        paymentTypes={data.paymentTypes}
      />
    </Box>
  );
};

type EventDateReconciliationRowsProps = {
  paymentTypes: OrderPaymentEnum[];
  rows: ({ label: React.ReactNode; index: number } & Record<OrderPaymentEnum | 'total', { salesCount: number; salesRevenue: number }>)[];
};

const EventDateReconciliationRows =  ({ paymentTypes, rows }: EventDateReconciliationRowsProps) => {
  return (
    <>
      {rows.map((row) => {
        return (
          <Box key={row.index} display="flex" alignItems="center" bgcolor={row.index % 2 ? 'primary.background' : undefined} px={1}>
            <Box width={130} height="100%">{row.label}</Box>
            <Divider flexItem orientation="vertical" />
            <Box display="flex" flexWrap="wrap" alignItems="center" gap={2} p={2}>
              <Box width={220}>
                <ReportCard
                  label="Total"
                  primary={row.total.salesCount.toString()}
                  secondary={currencyFormatter.format(row.total.salesRevenue)}
                  height={65}
                />
              </Box>
              {paymentTypes.map(payment => (
                <Box width={220} key={payment}>
                  <ReportCard
                    label={orderEnumHelpers.payment.getLabel(payment)}
                    primary={row[payment].salesCount.toString()}
                    secondary={currencyFormatter.format(row[payment].salesRevenue)}
                    height={65}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        );
      })}
    </>
  );
};
