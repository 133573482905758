import { Box, BoxProps, Button, ClickAwayListener, Grow, IconButton, LinearProgress, Paper, Popper, Typography, TypographyProps, alpha, useTheme } from '@mui/material';
import { Form, Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useSectionCardsEditContext } from './section-card-edit.context';
import { Add, WarningAmberOutlined } from '@mui/icons-material';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertSnackbar } from 'components/AlertSnackbar';
import { AttentionWarningContentStackReasonItemDescription } from 'components/AttentionWarningContentStack';
import { FULL_SCREEN_Z_INDEX } from 'constants/full-screen';

export type SectionCardRowProps = {
  action?: React.ReactNode;
  children: React.ReactNode;
  title: string;
  bgcolor?: BoxProps['bgcolor'];
  disableTypography?: boolean;
  titleTypographyProps?: TypographyProps;
  bottomContent?: React.ReactNode;
  bottomContentFullWidth?: boolean;
  warningDescription?: string[];
};

export const SectionCardRow: React.FC<SectionCardRowProps> = props => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const bgcolor = props.bgcolor ?? (props.warningDescription ? alpha(theme.palette.warning.main, 0.05) : undefined);
  const children = useMemo(() => {
    if (React.isValidElement(props.children)) {

      return props.children;
    }

    if (props.disableTypography) {

      return props.children;
    }

    return <Typography>{props.children}</Typography>;
  }, [ props.children, props.disableTypography ]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popperId = open ? 'section_card_row_warning_popper' : undefined;

  return (
    <Box
      component="div"
      role="row"
      display="flex"
      flexDirection="column"
      gap={2}
      pr={2}
      pl={{ xs: 2, sm: 2.5, md: 0 }}
      bgcolor={bgcolor}
      position="relative"
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        minHeight={36}
      >
        <Box width={{ md: '160px' }} display="flex" alignItems="center">
          <Popper
            id={popperId}
            open={open}
            anchorEl={anchorEl}
            transition
            placement="bottom-start"
            sx={{ zIndex: FULL_SCREEN_Z_INDEX + 100 }}
          >
            {({ TransitionProps }) => {
              if (!props.warningDescription) {
                return null;
              }

              return (
                <Grow {...TransitionProps}>
                  <Paper variant="outlined" sx={{ borderRadius: 0 }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box m={1}>
                        <AttentionWarningContentStackReasonItemDescription description={props.warningDescription} variant="caption" />
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              );
            }}
          </Popper>
          <Box display={props.warningDescription ? 'initial' : 'none'} order={{ xs: 2, md: 1 }}>
            <IconButton onClick={handleClick}>
              <WarningAmberOutlined color="warning" fontSize="small" />
            </IconButton>
          </Box>
          <Typography
            {...props.titleTypographyProps}
            color={props.titleTypographyProps?.color || 'text.secondary'}
            fontSize={props.titleTypographyProps?.fontSize || '14px'}
            display="flex"
            alignItems="center"
            pl={{ md: props.warningDescription ? 0 : 2 }}
            height="100%"
            order={{ xs: 1, md: 2 }}
          >
            {props.title}
          </Typography>
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          gap={2}
          py={0.5}
          overflow="hidden"
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Box flex={1} whiteSpace="nowrap" overflow="hidden">
              {children}
            </Box>
            <Box flexShrink={0}>
              {props.action}
            </Box>
          </Box>
          {!props.bottomContentFullWidth && props.bottomContent}
        </Box>
      </Box>
      {props.bottomContentFullWidth && props.bottomContent}
    </Box>
  );
};

export type SectionCardRowEditableProps<Values extends FormikValues> = {
  formikProps: SectionCardRowFormikProps<Values>;
  form: React.ReactNode;
  rowId: string;
  formFullWidth?: boolean;
  invalidateQueriesHandler?: (queryClient: QueryClient) => Promise<void> | void;
  disableEditable?: boolean;
} & Omit<SectionCardRowProps, 'action' | 'bottomContentFullWidth'>;

export const SectionCardRowEditable = <Values extends FormikValues>({ children, formikProps, form, rowId, invalidateQueriesHandler, disableEditable, ...props }: SectionCardRowEditableProps<Values>) => {
  const context = useSectionCardsEditContext();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();

  const isEdit = context.editRowId === rowId;
  const onToggleIsEdit = () => context.setEditRowId(isEdit ? null : rowId);

  const onSuccess = useCallback(() => {
    snackbar.success(`${props.title} updated`);

    if (invalidateQueriesHandler) {
      invalidateQueriesHandler(queryClient);
    }
  }, [ invalidateQueriesHandler, props.title, queryClient, snackbar ]);

  const onError = useCallback(() => {
    snackbar.error(`${props.title} could not be updated`);
  }, [ props.title, snackbar ]);

  if (disableEditable) {
    return <SectionCardRow {...props}>{children}</SectionCardRow>;
  }

  return (
    <SectionCardRow
      {...props}
      bgcolor={isEdit ? 'primary.background' : undefined}
      action={(
        <Button
          size="small"
          onClick={onToggleIsEdit}
          sx={{ visibility: isEdit ? 'hidden' : 'initial' }}
        >
          Edit
        </Button>
      )}
      bottomContentFullWidth={props.formFullWidth && isEdit}
      bottomContent={isEdit ? (
        <SectionCardRowForm
          form={form}
          formikProps={formikProps}
          onCancel={onToggleIsEdit}
          onSuccess={onSuccess}
          onError={onError}
        />
      ) : props.bottomContent}
      titleTypographyProps={{ fontWeight: isEdit ? 500 : undefined, color: isEdit ? 'text.primary' : undefined }}
    >
      {children}
    </SectionCardRow>
  );
};

export type SectionCardRowEditableListProps<Values extends FormikValues, ListItem extends { _id: string }> = {
  getFormikProps: (listItem: ListItem & { _order: number }) => SectionCardRowFormikProps<Values>;
  form: React.ReactNode;
  rowId: string;
  listItems: ListItem[];
  renderItem: (listItem: ListItem) => React.ReactNode;
  orderBy?: {
    getField: (listItem: ListItem) => string;
    direction: 'asc' | 'desc';
  };
  children?: React.ReactNode;
  previewLength?: number;
  addRowLabel?: string;
  createButtonLabel?: string;
  createButtonDisabled?: boolean;
  createButtonHidden?: boolean;
  editDisabled?: (listItem: ListItem) => boolean;
  createFormikProps: SectionCardRowFormikProps<Values>;
  createForm?: React.ReactNode;
  deleteMutationFn?: (listItem: ListItem) => Promise<any>;
  invalidateQueriesHandler?: (queryClient: QueryClient) => Promise<void> | void;
  disableEditable?: boolean;
  actions?: React.ReactNode;
} & Omit<SectionCardRowProps, 'action' | 'children'>;

export const SectionCardRowEditableList = <Values extends FormikValues, ListItem extends { _id: string }>({
  getFormikProps,
  listItems,
  renderItem,
  form = null,
  rowId,
  orderBy,
  children,
  previewLength = 3,
  createButtonLabel = 'Item',
  addRowLabel,
  createForm,
  deleteMutationFn,
  createButtonDisabled,
  createButtonHidden,
  editDisabled,
  createFormikProps,
  invalidateQueriesHandler,
  disableEditable,
  actions,
  ...props
}: SectionCardRowEditableListProps<Values, ListItem>) => {
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const [ isShowAll, setIsShowAll ] = useState(listItems.length <= previewLength);
  const [ editListItem, setEditListItem ] = useState<string | null>(null);
  const context = useSectionCardsEditContext();
  const isRowEdit = context.editRowId === rowId;
  const isAdding = isRowEdit && editListItem === '_new';

  const onSetIsEdit = useCallback((id: string) => {
    context.setEditRowId(rowId);
    setEditListItem(id);
  }, [ context, rowId ]);
  const onCancelEdit = useCallback(() => {
    context.setEditRowId(null);
    setEditListItem(null);
  }, [ context ]);

  const deleteMutation = useMutation({
    mutationFn: (listItem: ListItem & { _order: number }): Promise<any> | any => {
      if (deleteMutationFn) {
        return deleteMutationFn(listItem);
      };
    },
    onSuccess: async () => {
      snackbar.success(`${createButtonLabel} deleted`);

      if (invalidateQueriesHandler) {
        await invalidateQueriesHandler(queryClient);
      }
    },
    onError: () => {
      snackbar.error(`${createButtonLabel} could not be deleted`);
    }
  });

  const onSuccess = useCallback(async (order: number) => {
    snackbar.success(`${createButtonLabel} #${order} updated`);

    if (invalidateQueriesHandler) {
      await invalidateQueriesHandler(queryClient);
    }
  }, [ createButtonLabel, invalidateQueriesHandler, queryClient, snackbar ]);

  const onError = useCallback((order: number) => {
    snackbar.error(`${createButtonLabel} #${order} could not be updated`);
  }, [ createButtonLabel, snackbar ]);

  const onCreateSuccess = useCallback(async () => {
    snackbar.success(`${createButtonLabel} #${listItems.length + 1} created`);

    if (invalidateQueriesHandler) {
      await invalidateQueriesHandler(queryClient);
    }
  }, [ createButtonLabel, invalidateQueriesHandler, listItems.length, queryClient, snackbar ]);

  const onCreateError = useCallback(() => {
    snackbar.error(`${createButtonLabel} could not be created`);
  }, [ createButtonLabel, snackbar ]);

  const sortedListItems = listItems.sort((a, b) => {
    if (!orderBy) {

      return 0;
    }

    const aField = orderBy.getField(a);
    const bField = orderBy.getField(b);

    if (aField < bField) {

      return orderBy.direction === 'asc' ? -1 : 1;
    }
    if (aField > bField) {

      return orderBy.direction === 'asc' ? 1 : -1;
    }

    return 0;
  }).map((x, idx, { length }) => ({ ...x, _order: orderBy?.direction === 'desc' ? length - idx : idx + 1 })).slice(0, isShowAll ? listItems.length : previewLength);

  const enableShowMoreButton = !isShowAll;

  const addRow = useMemo(() => {
    if (isAdding) {
      return (
        <SectionCardRowEditableListItem
          formikProps={createFormikProps}
          form={createForm ?? form}
          onCancel={onCancelEdit}
          isEdit
          order={listItems.length + 1}
          onSuccess={onCreateSuccess}
          onError={onCreateError}
        >
          {addRowLabel ?? `Create a new ${createButtonLabel}:`}
        </SectionCardRowEditableListItem>
      );
    }

    return (
      <Box display="flex" justifyContent={actions ? 'space-between' : 'flex-end'}>
        <Box flex={1} visibility={actions ? 'visible' : 'hidden'}>{actions}</Box>
        <Box visibility={!disableEditable ? 'visible' : 'hidden'}>
          {!createButtonHidden && (
            <Button size="small" variant="outlined" startIcon={<Add />} onClick={() => onSetIsEdit('_new')} disabled={createButtonDisabled}>
            Add {createButtonLabel}
            </Button>
          )}
        </Box>
      </Box>
    );
  }, [ isAdding, actions, disableEditable, createButtonHidden, createButtonDisabled, createButtonLabel, createFormikProps, createForm, form, onCancelEdit, listItems.length, onCreateSuccess, onCreateError, addRowLabel, onSetIsEdit ]);

  return (
    <SectionCardRow {...props}>
      {children && <Box mb={3}>{children}</Box>}
      <Box display="flex" flexDirection="column" gap={2} width="100%">
        {addRow}
        {sortedListItems.map((listItem) => {
          const isEdit = isRowEdit && editListItem === listItem._id;

          return (
            <SectionCardRowEditableListItem
              key={listItem._id}
              formikProps={getFormikProps(listItem)}
              form={form}
              onDelete={deleteMutationFn ? () => deleteMutation.mutate(listItem) : undefined}
              onEdit={() => onSetIsEdit(listItem._id)}
              onCancel={onCancelEdit}
              isEdit={isEdit}
              order={listItem._order}
              deleteLoading={deleteMutation.isLoading && deleteMutation.variables?._id === listItem._id}
              onSuccess={() => onSuccess(listItem._order)}
              onError={() => onError(listItem._order)}
              disableEdit={editDisabled?.(listItem)}
              disableEditable={disableEditable}
            >
              {renderItem(listItem)}
            </SectionCardRowEditableListItem>
          );
        })}
      </Box>
      {enableShowMoreButton && (
        <Box mt={3}>
          <Button fullWidth color="inherit" size="small" onClick={() => setIsShowAll(true)}>Show All ({listItems.length})</Button>
        </Box>
      )}
    </SectionCardRow>
  );
};

type SectionCardRowEditableListItemProps<Values extends FormikValues> = {
  children: React.ReactNode;
  onDelete?: () => void;
  onEdit?: () => void;
  isEdit: boolean;
  order?: number;
  deleteLoading?: boolean;
  disableEdit?: boolean;
  disableEditable?: boolean;
} & Pick<SectionCardRowFormProps<Values>, 'formikProps' | 'form' | 'onCancel' | 'onSuccess' | 'onError'>;

export const SectionCardRowEditableListItem = <Values extends FormikValues>({
  formikProps,
  form,
  children,
  onDelete,
  onEdit,
  onCancel,
  isEdit,
  order,
  deleteLoading,
  onSuccess,
  onError,
  disableEdit,
  disableEditable,
}: SectionCardRowEditableListItemProps<Values>) => {

  return (
    <Box m={isEdit ? -1 : undefined}>
      <Box p={isEdit ? 1 : 0} bgcolor={isEdit ? 'primary.background' : undefined}>
        <SectionCardRowListItem
          action={!disableEditable && (
            <Box visibility={!onEdit || isEdit ? 'hidden' : undefined}>
              <Button size="small" onClick={onEdit} disabled={disableEdit}>Edit</Button>
            </Box>
          )}
          order={order}
          bottomContent={isEdit && (
            <SectionCardRowForm
              loading={deleteLoading}
              form={form}
              formikProps={formikProps}
              onCancel={onCancel}
              actions={onDelete && <Button color="error" onClick={onDelete} disabled={deleteLoading}>Delete</Button>}
              onSuccess={onSuccess}
              onError={onError}
            />
          )}
        >
          {children}
        </SectionCardRowListItem>
      </Box>
    </Box>
  );
};

type SectionCardRowListItemProps = {
  children?: React.ReactNode;
  action?: React.ReactNode;
  order?: number;
  bottomContent?: React.ReactNode;
};

const SectionCardRowListItem: React.FC<SectionCardRowListItemProps> = props => {

  return (
    <Box>
      <Box flex={1} display="flex" alignItems="center" gap={1}>
        {props.order !== undefined && (
          <Box width={27}>
            <Typography color="text.secondary" fontSize="15px" component="span">#{props.order}</Typography>
          </Box>
        )}
        <Box borderLeft={t => `2px solid ${t.palette.primary.main}`} pl={1} width="80%">
          {props.children}
        </Box>
        <Box>
          {props.action}
        </Box>
      </Box>
      {props.bottomContent && (
        <Box mt={2.5}>
          {props.bottomContent}
        </Box>
      )}
    </Box>
  );
};

type SectionCardRowFormikProps<Values extends FormikValues> = {
  initialValues: Values;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values> & { initialValues: Values }) => void | Promise<any>;
  validationSchema?: FormikConfig<Values>['validationSchema'];
};

type SectionCardRowFormProps<Values extends FormikValues> = {
  formikProps: SectionCardRowFormikProps<Values>;
  form: React.ReactNode;
  onCancel: () => void;
  actions?: React.ReactNode;
  loading?: boolean;
  onSuccess: () => Promise<void> | void;
  onError: () => void;
};

const SectionCardRowForm = <Values extends FormikValues>(props: SectionCardRowFormProps<Values>) => {

  const onSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    try {
      await props.formikProps.onSubmit(values, { ...formikHelpers, initialValues: props.formikProps.initialValues });

      await props.onSuccess?.();
      props.onCancel();
    } catch (error) {
      props.onError?.();
    }
  };

  return (
    <Formik
      {...props.formikProps}
      onSubmit={onSubmit}
    >
      {formik => {
        const isLoading = props.loading || formik.isSubmitting;

        return (
          <>
            <Form>
              <Box display="flex" flexDirection="column" bgcolor="background.paper" gap={3} p={2} border={t => `1px solid ${t.palette.divider}`}>
                <Box>
                  {props.form}
                </Box>
                <Box display="flex" gap={2} alignItems="center">
                  {props.actions}
                  <Box display="flex" gap={2} alignItems="center" flex={1} justifyContent="flex-end">
                    <Button onClick={props.onCancel} disabled={isLoading}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit" disabled={isLoading || !formik.dirty}>Save</Button>
                  </Box>
                </Box>
              </Box>
            </Form>
            {isLoading && (
              <Box position="absolute" top={0} left={0} width="100%">
                <LinearProgress />
              </Box>
            )}
          </>
        );
      }}
    </Formik>
  );
};