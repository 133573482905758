import { EventParticipationStatusEnum, EventAcceptanceStatusEnum, EventApplicationStatusEnum, EventDateStaffStatusEnum, User } from 'api/resources';
import { eventEnumHelpers, getEventStaffStatus, getEventDateStaffStatus, userEnumHelpers } from 'helpers';
import { UnknownEnum, EventStaffStatusEnum } from 'types';
import { ColorCodeConfig, paletteHelperHash } from '../colorCode';
import { GetEventsResponse } from 'api/actions';

export type EventColorCodeConfigArg = {
  event: GetEventsResponse['data'][number];
  eventDate: GetEventsResponse['data'][number]['dates'][number];
};

// Participation status

const getParticipationStatusLabel = eventEnumHelpers.participationStatus.getLabel;
const participationKeys = {
  interested: {
    keyLabel: getParticipationStatusLabel(EventParticipationStatusEnum.interested),
    ...paletteHelperHash.success,
  },
  participating: {
    keyLabel: getParticipationStatusLabel(EventParticipationStatusEnum.participating),
    ...paletteHelperHash.successDark,
  },
  notInterested: {
    keyLabel: getParticipationStatusLabel(EventParticipationStatusEnum.notInterested),
    ...paletteHelperHash.error,
  },
  canceled: {
    keyLabel: getParticipationStatusLabel(EventParticipationStatusEnum.canceled),
    ...paletteHelperHash.error,
  },
  unable: {
    keyLabel: getParticipationStatusLabel(EventParticipationStatusEnum.unable),
    ...paletteHelperHash.threat,
  },
  unknown: {
    keyLabel: getParticipationStatusLabel(UnknownEnum.unknown),
    ...paletteHelperHash.unknown,
  },
};

export const eventParticipationStatusColorCodeConfig = new ColorCodeConfig('Participation Status', participationKeys, ({ event }: EventColorCodeConfigArg) => {
  return event.participationStatus ?? UnknownEnum.unknown;
});

// Acceptance status

const getAcceptanceStatusLabel = eventEnumHelpers.acceptanceStatus.getLabel;
const acceptanceKeys = {
  accepted: {
    keyLabel: getAcceptanceStatusLabel(EventAcceptanceStatusEnum.accepted),
    ...paletteHelperHash.success,
  },
  waitListPending: {
    keyLabel: getAcceptanceStatusLabel(EventAcceptanceStatusEnum.waitListPending),
    ...paletteHelperHash.info,
  },
  waitListDenied: {
    keyLabel: getAcceptanceStatusLabel(EventAcceptanceStatusEnum.waitListDenied),
    ...paletteHelperHash.error,
  },
  followUp: {
    keyLabel: getAcceptanceStatusLabel(EventAcceptanceStatusEnum.followUp),
    ...paletteHelperHash.warning,
  },
  denied: {
    keyLabel: getAcceptanceStatusLabel(EventAcceptanceStatusEnum.denied),
    ...paletteHelperHash.error,
  },
  unknown: {
    keyLabel: getAcceptanceStatusLabel(UnknownEnum.unknown),
    ...paletteHelperHash.unknown,
  },
};

export const eventAcceptanceStatusColorCodeConfig = new ColorCodeConfig('Acceptance Status', acceptanceKeys, ({ event }: EventColorCodeConfigArg) => {
  return event.acceptanceStatus ?? UnknownEnum.unknown;
});

// Application status

const getApplicationStatusLabel = eventEnumHelpers.applicationStatus.getLabel;
const applicationKeys = {
  needApply: {
    keyLabel: getApplicationStatusLabel(EventApplicationStatusEnum.needApply),
    ...paletteHelperHash.error,
  },
  inProccess: {
    keyLabel: getApplicationStatusLabel(EventApplicationStatusEnum.inProccess),
    ...paletteHelperHash.warning,
  },
  applied: {
    keyLabel: getApplicationStatusLabel(EventApplicationStatusEnum.applied),
    ...paletteHelperHash.success,
  },
  research: {
    keyLabel: getApplicationStatusLabel(EventApplicationStatusEnum.research),
    ...paletteHelperHash.threat,
  },
  unknown: {
    keyLabel: getApplicationStatusLabel(UnknownEnum.unknown),
    ...paletteHelperHash.unknown,
  },
};

export const eventApplicationStatusColorCodeConfig = new ColorCodeConfig('Application Status', applicationKeys, ({ event }: EventColorCodeConfigArg) => {
  return event.applicationStatus ?? UnknownEnum.unknown;
});

// Staff Preperation Status

const staffPreperationStatusKeys = {
  fulfilled: {
    keyLabel: eventEnumHelpers.staffStatus.getLabel(EventStaffStatusEnum.fulfilled),
    ...paletteHelperHash.success,
  },
  pending: {
    keyLabel: eventEnumHelpers.staffStatus.getLabel(EventStaffStatusEnum.pending),
    ...paletteHelperHash.warning,
  },
};

export const eventStaffPreperationStatusColorCodeConfig = new ColorCodeConfig('Event Staff Status', staffPreperationStatusKeys, ({ event }: EventColorCodeConfigArg) => {
  return getEventStaffStatus(event.dates);
});

export const eventDatesStaffPreperationStatusColorCodeConfig = new ColorCodeConfig('Dates Staff Status', staffPreperationStatusKeys, ({ eventDate }: EventColorCodeConfigArg) => {
  return getEventDateStaffStatus(eventDate);
});

const userStaffStatusKeys = {
  confirmed: {
    keyLabel: userEnumHelpers.staffStatus.getLabel(EventDateStaffStatusEnum.confirmed),
    ...paletteHelperHash.success,
  },
  requested: {
    keyLabel: userEnumHelpers.staffStatus.getLabel(EventDateStaffStatusEnum.requested),
    ...paletteHelperHash.warning,
  },
  selfRequested: {
    keyLabel: userEnumHelpers.staffStatus.getLabel(EventDateStaffStatusEnum.selfRequested),
    ...paletteHelperHash.warning,
  },
  selfRequestDenied: {
    keyLabel: userEnumHelpers.staffStatus.getLabel(EventDateStaffStatusEnum.selfRequestDenied),
    ...paletteHelperHash.error,
  },
  deniedRequest: {
    keyLabel: userEnumHelpers.staffStatus.getLabel(EventDateStaffStatusEnum.deniedRequest),
    ...paletteHelperHash.error,
  },
  initial: {
    keyLabel: userEnumHelpers.staffStatus.getLabel(EventDateStaffStatusEnum.initial),
    ...paletteHelperHash.unknownDarker,
  },
  unknown: {
    keyLabel: 'Unknown',
    ...paletteHelperHash.unknown,
  }
};

export type EventWithUserColorCodeConfigArg = EventColorCodeConfigArg & {
  user: Pick<User, '_id'> | null;
};

export const userStaffStatusColorCodeConfig = new ColorCodeConfig('User Staff Status', userStaffStatusKeys, ({ user, eventDate }: EventWithUserColorCodeConfigArg) => {
  return (user && eventDate.staff.find(staff => staff.user._id === user._id)?.status) ?? UnknownEnum.unknown;
});

