import { EnumHelpers } from './types';
import { FrameEnum } from 'types';

const frame: EnumHelpers<FrameEnum> = {
  enumValues: [
    FrameEnum.frame,
    FrameEnum.noFrame,
  ],
  getLabel: value => {
    switch (value) {
      case FrameEnum.frame:
        return 'Frame';
      case FrameEnum.noFrame:
        return 'No Frame';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown'
};

export const saleEnumHelpers = {
  frame,
};