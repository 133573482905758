import { Box, Button, Grid, Typography } from '@mui/material';
import { CardContentContainer, FormFooter, PageContentContainer, PageContentResourceHeader, useAlertSnackbar } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { Formik } from 'formik';
import { Navigate, useNavigate } from 'react-router-dom';
import { formSx } from 'styles';
import { userNewValidationSchema } from './validation';
import { UserPasswordPinForm } from './forms/UserPassword.form';
import { UnknownEnum, UserResourceForm } from 'types';
import { useMutation } from '@tanstack/react-query';
import { UserRoleEnum } from 'api/resources';
import { createUser } from 'api/actions';
import { useCurrentUser } from 'contexts';
import { processFormValueUpdate } from 'helpers';
import { UserBasicInfoForm, UserDrivingInfoForm } from 'pages';

const initialValues: UserResourceForm['new'] = {
  name: '',
  email: '',
  role: UserRoleEnum.user,
  employeeRoles: [],
  phoneNumber: '',
  birthday: null,
  password: '',
  passwordConfirmation: '',
  pin: '',
  pinConfirmation: '',
  driverLicence: UnknownEnum.unknown,
  personalVehicle: UnknownEnum.unknown,
  coveredByBussinessInsuranceAsDriver: UnknownEnum.unknown,
  canDriveBoxTruck: UnknownEnum.unknown,
  canPullTrailer: UnknownEnum.unknown,
  drivingNotes: '',
};

export const UserNew: React.FC = () => {
  const me = useCurrentUser();
  const navigate = useNavigate();
  const snackbar = useAlertSnackbar();
  const createMutation = useMutation({
    mutationFn: (form: UserResourceForm['new']) => createUser({
      name: form.name,
      email: form.email,
      phoneNumber: processFormValueUpdate.string(form.phoneNumber),
      birthday: form.birthday,
      role: form.role,
      employeeRoles: form.employeeRoles,
      password: form.password,
      pin: form.pin,
      driverLicence: processFormValueUpdate.yesNoUnknown(form.driverLicence),
      personalVehicle: processFormValueUpdate.yesNoUnknown(form.personalVehicle),
      coveredByBussinessInsuranceAsDriver: processFormValueUpdate.yesNoUnknown(form.coveredByBussinessInsuranceAsDriver),
      canDriveBoxTruck: processFormValueUpdate.yesNoUnknown(form.canDriveBoxTruck),
      canPullTrailer: processFormValueUpdate.yesNoUnknown(form.canPullTrailer),
      drivingNotes: processFormValueUpdate.string(form.drivingNotes),
    }),
    onSuccess: async ({ data }) => {
      snackbar.success('User created');
      navigate(`/${ROUTING_CONFIG.users}/${data._id}`);
    },
    onError: async () => {
      snackbar.error('Unable to create user');
    }
  });

  if (!me.isAdmin) {
    snackbar.error('You do not have permission to create a user');

    return <Navigate to={`/${ROUTING_CONFIG.users}`} replace />;
  }

  const handleCancel = () => {
    navigate(`/${ROUTING_CONFIG.users}`);
  };

  return (
    <PageContentContainer
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.users, text: 'Users' } ],
        current: 'New User',
      }}
      header={(
        <PageContentResourceHeader
          pageTitle="New User Page"
          title="Create a new user"
        />
      )}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => createMutation.mutateAsync(values)}
        validationSchema={userNewValidationSchema}
      >
        {(formik) => {
          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <CardContentContainer>
                    <Typography variant="h6" mb={2}>User Information and Credentials</Typography>
                    <Box sx={formSx.formGroup}>
                      <UserBasicInfoForm showRole showEmployeeRoles />
                      <UserPasswordPinForm />
                    </Box>
                  </CardContentContainer>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <CardContentContainer>
                    <UserDrivingInfoForm />
                  </CardContentContainer>
                </Grid>
              </Grid>
              <FormFooter>
                <Button
                  onClick={handleCancel}
                  size="large"
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => formik.handleSubmit()}
                  disabled={formik.isSubmitting}
                >
                  Create User
                </Button>
              </FormFooter>
            </>
          );
        }}
      </Formik>
    </PageContentContainer>
  );
};