import { CardContentLink, OrderFulfillmentStatusChip, OrderSaleFulfillmentStatusChip, OrderSaleFulfillmentTypeChip, SectionCard, SectionCardRow } from 'components';
import React from 'react';
import { useOrderOutletContext } from '../Order.base';
import { OrderPageSection, OrderPageSectionRowId, OrderSectionRowForm } from '../types';
import { OrderSectionCardRowEditableList } from '../components/OrderSectionCardRowEditable.component';
import { getSaleDateAndPointContent, removeUnchanged } from 'helpers';
import { updateSale } from 'api/actions';
import { mixed, number, object, SchemaOf, string } from 'yup';
import { FrameEnum } from 'types';
import { OrderSaleForm } from '../forms/OrderSale.form';
import { Box, Typography } from '@mui/material';
import { currencyFormatter } from 'services';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { missingImg } from 'assets';

const orderSaleValidationSchema = (): SchemaOf<OrderSectionRowForm[OrderPageSectionRowId.SALES]> => {
  return object({
    product: string().required(),
    salePrice: number().required(),
    frame: mixed<FrameEnum>().oneOf([ FrameEnum.frame, FrameEnum.noFrame ]).required(),
  });
};

export const OverviewSection = () => {
  const { order } = useOrderOutletContext();

  return (
    <SectionCard title="Overview" id={OrderPageSection.OVERVIEW}>
      {order.shopifyOrderId && (
        <SectionCardRow title="Shopify Order">
          <CardContentLink openInNew title="View order in Shopify" href={`https://admin.shopify.com/store/astro-vinyl-art/orders/${order.shopifyOrderId}`} />
        </SectionCardRow>
      )}
      <SectionCardRow title="Fulfillment Status">
        <OrderFulfillmentStatusChip value={order.fulfillmentStatus} />
      </SectionCardRow>
      <SectionCardRow title="Payment">
        {order.payment}
      </SectionCardRow>
      <OrderSectionCardRowEditableList
        title="Sales"
        listItems={order.sales}
        rowId={OrderPageSectionRowId.SALES}
        renderItem={(sale) => {
          return (
            <Box display="flex" gap={1} position="relative" overflow="hidden">
              <Box minWidth="112px" width="100px" height="100px">
                <img style={{ maxWidth: '100%', height: 'auto', maxHeight: '100%' }} src={sale.product.imageUrl || missingImg} alt="header" />
              </Box>
              <Box flex={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                <Typography fontWeight={500} noWrap maxWidth="calc(100% - 200px)">{sale.product.name}</Typography>
                <CardContentLink variant="body2" title={`Sale ID: ${sale._id}`} href={`/${ROUTING_CONFIG.salesLog}/${sale._id}`} />
                <Typography mt={1} noWrap>{getSaleDateAndPointContent(sale)}</Typography>
                <Typography color="text.secondary" noWrap>{currencyFormatter.format(sale.salePrice)}</Typography>
              </Box>
              <Box position="absolute" top={0} right={10} display="flex" gap={1}>
                <OrderSaleFulfillmentTypeChip value={sale.fulfillmentType} sx={{ width: 80 }} />
                <OrderSaleFulfillmentStatusChip value={sale.fulfillmentStatus} sx={{ width: 80 }} />
              </Box>
            </Box>
          );
        }}
        createButtonHidden
        getFormikProps={(sale) => ({
          initialValues: {
            salePrice: sale.salePrice,
            frame: sale.frame ? FrameEnum.frame : FrameEnum.noFrame,
          },
          onSubmit: async (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            await updateSale(sale._id, {
              salePrice: updates.salePrice,
              frame: updates.frame && updates.frame === FrameEnum.noFrame ? false : true,
            });
          },
          validationSchema: orderSaleValidationSchema,
        })}
        // TODO: make create props optional
        createFormikProps={{
          initialValues: {
            salePrice: 0,
            frame: FrameEnum.noFrame,
          },
          onSubmit: () => {},
        }}
        form={<OrderSaleForm />}
      />
    </SectionCard>
  );
};
