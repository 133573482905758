import { TextField, TextFieldProps } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React from 'react';

export type TextFieldCellProps = Pick<GridRenderEditCellParams, 'id' | 'value' | 'field'> & Omit<TextFieldProps, 'id'>;

export const TextFieldCell: React.FC<TextFieldCellProps> = (props) => {
  const { id, value, field, ...params } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (newValue: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <TextField
      {...params}
      value={value}
      onChange={(e) => handleValueChange(e.target.value)}
      onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
        const inputElement = e.currentTarget.querySelector('input');

        if (inputElement) {
          inputElement.blur();
        }
      }
      }
    />
  );
};
