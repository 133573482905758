import { UserEmployeeRoleEnum, UserRoleEnum } from 'api/resources';
import { userEnumHelpers } from 'helpers';
import { UserResourceForm, yesNoUnknownSchema } from 'types';
import { phoneRegex } from 'validation';
import { SchemaOf, array, mixed, object, string } from 'yup';

export const userInfoValidationSchema = (): SchemaOf<UserResourceForm['edit']>  => {
  return object({
    name: string().required('Name required'),
    email: string().email('Invalid email').required('Email required'),
    phoneNumber: string().default('').test({
      test: (value: string) => {
        if (!value) return true;

        return phoneRegex.test(value);
      },
      message: 'Invalid phone number',
    }),
    birthday: string().nullable().default(null),
    role: mixed().oneOf<UserRoleEnum>([ UserRoleEnum.admin, UserRoleEnum.user ], 'Invalid role').required('Role required'),
    employeeRoles: array(mixed().oneOf<UserEmployeeRoleEnum>(userEnumHelpers.employeeRole.enumValues, 'Invalid employee role')).required('Employee roles required'),
    driverLicence: yesNoUnknownSchema,
    personalVehicle: yesNoUnknownSchema,
    coveredByBussinessInsuranceAsDriver: yesNoUnknownSchema,
    canDriveBoxTruck: yesNoUnknownSchema,
    canPullTrailer: yesNoUnknownSchema,
    drivingNotes: string().default(''),
  });
};

export const passwordValidationSchema = (): SchemaOf<UserResourceForm['password']> => {
  return object({
    currentPassword: string().min(6, 'Too short').required('Current password is required'),
    newPassword: string().min(6, 'Too short').required('New password is required'),
  });
};

export const pinValidationSchema = (): SchemaOf<UserResourceForm['pin']> => {
  return object({
    password: string().min(6, 'Too short').required('Current password is required'),
    newPin: string().length(4, 'Must be 4 digits').required('New PIN is required').test('isValidPin', 'Dont use this pin', (value) => value !== '0000'),
  });
};