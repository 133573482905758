import { CheckCircleOutline, HelpOutline, UnpublishedOutlined } from '@mui/icons-material';
import { EnumHelpers } from './types';
import { UnknownEnum, YesNoEnum } from 'types';
import { SxProps, Theme } from '@mui/material';

const getYesNoEnumValue = (value?: boolean | null) => {
  switch (value) {
    case true:
      return YesNoEnum.yes;
    case false:
      return YesNoEnum.no;
    default:
      return UnknownEnum.unknown;
  }
};

const getYesNoEnumLabel = (value: YesNoEnum | UnknownEnum) => {
  switch (value) {
    case YesNoEnum.yes:
      return 'Yes';
    case YesNoEnum.no:
      return 'No';
    default:
      return 'Unknown';
  }
};

const yesNo: EnumHelpers<YesNoEnum | UnknownEnum> & {
  getEnumValue: (value?: boolean | null) => YesNoEnum | UnknownEnum;
  getEnumValueOrNull: (value?: boolean) => YesNoEnum | null; // for forms without 'Unknown' option and no default value
  getLabelForValue: (value?: boolean | null) => string;
  getValue: (enumValue: YesNoEnum | UnknownEnum) => boolean | null;
  getIconForValue: (value?: boolean | null) => React.FunctionComponent<React.SVGProps<SVGSVGElement> & { sx?: SxProps<Theme>}>;
  getIconSxForValue: (value?: boolean | null) => SxProps<Theme>;
} = {
  enumValues: [
    YesNoEnum.yes,
    YesNoEnum.no,
    UnknownEnum.unknown
  ],
  getLabel: getYesNoEnumLabel,
  getEnumValue: getYesNoEnumValue,
  getEnumValueOrNull: (value) => {
    switch (value) {
      case true:
        return YesNoEnum.yes;
      case false:
        return YesNoEnum.no;
      default:
        return null;
    }
  },
  getLabelForValue: (value) => {
    const enumValue = getYesNoEnumValue(value);

    return getYesNoEnumLabel(enumValue);
  },
  getValue: (value) => {
    switch (value) {
      case YesNoEnum.yes:
        return true;
      case YesNoEnum.no:
        return false;
      default:
        return null;
    }
  },
  getIconForValue: (value) => {
    if (value === null || value === undefined) {
      return HelpOutline;
    }

    return value ? CheckCircleOutline : UnpublishedOutlined;
  },
  getIconSxForValue: (value) => {
    if (value === null || value === undefined) {
      return (theme) => ({ color: theme.palette.text.secondary });
    }

    return value ? (theme) => ({ color: theme.palette.success.main }) : (theme) => ({ color: theme.palette.error.main });
  },
  getColor: () => 'unknown',
};

export const yesNoEnumHelpers = {
  yesNo
};