import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import React from 'react';
import { AnalyticsChart, AnalyticsChartTypeEnum } from 'components';
import { useAnalyticsChartDataWithCompare } from 'pages/Analytics/hooks';

export const SalesPaymentMethodChart = () => {
  const { salesLoading } = useAnalyticsPageContext();
  const { config } = useAnalyticsChartDataWithCompare({
    type: 'sales',
    baseConfig:  { getValue: () => 1, getLabel: (sale) => sale.order.payment, getId: (sale) => sale.order.payment },
  });

  return (
    <AnalyticsChart
      header="Payment Method"
      config={config}
      colorMode="element"
      allowedViewTypes={[ AnalyticsChartTypeEnum.bar, AnalyticsChartTypeEnum.pie ]}
      initialChartType={AnalyticsChartTypeEnum.bar}
      loading={salesLoading}
    />
  );
};
